import React, { FC } from 'react';

import { BlockWrapper, Button, ProductCard } from 'components';
import { Product, Block, Button as TButton } from 'types';

export type TProductCards = Block<
  'productCards',
  { title: string; products: Product[]; button: TButton }
>;
export const ProductCards: FC<TProductCards> = ({ title, products, button }) => {
  const activeProducts = products.filter(product => product.store.status === 'active');

  return (
    <BlockWrapper className="ProductCards">
      <div className="ProductCards__header mb-12 flex justify-center">
        <h2 className="ProductCards__title block w-full text-center font-grotesk-headline-news text-2xl md:text-3xl">
          {title}
        </h2>
      </div>
      <div className="ProductCards__grid grid grid-cols-1 md:grid-cols-2 gap-12 lg:grid-cols-4">
        {activeProducts.map(product => (
          <ProductCard key={`ProductCards__card-${product._id}`} product={product} />
        ))}
      </div>
      <div className="ProductCards__button flex justify-center mt-12">
        <Button href={button.url} variant="fill">
          {button.label}
        </Button>
      </div>
    </BlockWrapper>
  );
};

export default ProductCards;
