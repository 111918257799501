import React, { FC, useCallback, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import * as Sentry from '@sentry/nextjs';

import EscapodAPI from 'clients/Escapod';
import * as Gtag from 'clients/Gtag';
import Fbq from 'clients/Fbq';
import emailIsValid from 'utils/emailIsValid';

import Variants from 'components/experiments/newsletterPopupAB1';

const NEWSLETTER_COOKIE = '__ESCAPOD_NEWSLETTER_POPUP_V2';

export const NewsletterPopup: FC = () => {
  const [email, setEmail] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [error, setError] = useState<{ [key: string]: string }>({});

  const close = useCallback(
    (days = 5) => {
      const expires = typeof days === 'number' ? days : 5;
      Cookies.set(NEWSLETTER_COOKIE, 'true', { expires });
      setIsActive(false);
    },
    [setIsActive]
  );

  const submit = useCallback(() => {
    setError({});

    if (!email || !emailIsValid(email)) {
      return setError({ email: 'Please provide a valid email address.' });
    }

    setIsLoading(true);
    Sentry.setUser({ email });

    EscapodAPI.klaviyo
      .subscribe({ email, form: 'newsletter_popup' })
      .then(res => {
        if (res.status === 201) {
          Fbq('Subscribe', { value: '0.00', currency: 'USD', predicted_ltv: '0.00' });
          Gtag.event('conversion', {
            send_to: 'AW-814232277/VfPlCMato6YDENXloIQD',
            transaction_id: window.btoa(email),
            event_callback: () => {
              return;
            }
          });
          setError({ form: 'Thank you for your submission!' });
          setTimeout(() => {
            close(30);
            setError({});
            setEmail('');
            setIsLoading(false);
          }, 3000);
        }
      })
      .catch(err => {
        setIsLoading(false);
        if (err.response.status === 400) {
          if (err.response.data.message === 'email invalid') {
            return setError({ email: 'Please enter a valid email address.' });
          }
          if (err.response.data.message === 'listId invalid') {
            return setError({ form: 'Something went wrong. Please try again later!' });
            // TO-DO: Add Sentry to notify developers of a listId malfunction
          }
          if (err.response.data.message === 'member exists') {
            return setError({
              form: "You've already subscribed with that email address! Try a different one."
            });
          }
        }
        return setError({ form: 'Something went wrong. Please try again later!' });
        // TO-DO: Add Sentry to notify developers of an unknown malfunction
      });
  }, [email, close]);

  useEffect(() => {
    setTimeout(() => {
      if (
        Cookies.get(NEWSLETTER_COOKIE) !== 'true' &&
        !(
          typeof window !== 'undefined' &&
          !!(window as any)?.location?.href?.includes('events/link')
        )
      )
        setIsActive(true);
    }, 7000);
  }, []);

  return (
    <Variants.PopupA
      error={error}
      isLoading={isLoading}
      isActive={isActive}
      email={email}
      onChange={setEmail}
      onSubmit={submit}
      onClose={close}
    />
  );
};

export default NewsletterPopup;
