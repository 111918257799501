import React, { FC, useMemo } from 'react';
import Link from 'next/link';
import { format, parse, compareDesc } from 'date-fns';

import { Block, SanityPage } from 'types';
import { BlockWrapper, Image } from 'components';
import Button from 'components/base/Button';

export type TPostGrid = Block<'postGrid', { title: string; posts: SanityPage[] }>;

const PostGridItem: FC<{ post: SanityPage }> = ({ post }) => {
  const hasImage = !!post.seo?.image?.src;
  const imageWidth = post.seo?.image?.metadata?.dimensions.width || 1;
  const imageHeight = post.seo?.image?.metadata?.dimensions.height || 1;

  const [author, date] = useMemo(() => {
    const heroBlock = post.content.find(block => block._type === 'postHero');
    const author = heroBlock?._type === 'postHero' && heroBlock.author;
    const date = (heroBlock?._type === 'postHero' && heroBlock.date) || '';

    return [author, date];
  }, [post]);

  const parsedDate = !!date && parse(date, 'yyyy-MM-dd', new Date());
  const formattedDate = !!parsedDate && format(parsedDate, 'MMMM dd, yyyy');

  if (!!post.options?.noIndex) return null;

  return (
    <div className="PostGrid__posts__post mb-12 lg:mb-20">
      {hasImage && (
        <div
          className="relative mb-6"
          style={{
            paddingBottom: `${(imageHeight / imageWidth) * 100}%`
          }}
        >
          <Link href={post.slug.current}>
            <a>
              <Image src={post.seo.image.src || ''} layout="fill" alt={post.seo.image.alt} />
            </a>
          </Link>
        </div>
      )}
      <div className="flex flex-col items-start md:flex-row md:justify-between">
        <div className="flex flex-col pr-6">
          <Link href={post.slug.current}>
            <a className="mb-2">
              <span className="text-lg font-grotesk-headline">{post.title}</span>
            </a>
          </Link>
          <span className="text-stone-400 font-grotesk-sub-headline text-xs">
            {!!formattedDate && <span className="mr-6">{formattedDate}</span>}
            {!!author && <span className="mr-6">By {author.name}</span>}
          </span>
        </div>
        <div className="shrink-0 mt-6 md:mt-0">
          <Link href={post.slug.current} passHref>
            {/* TO-DO: This should be an anchor tag so the browser shows link preview */}
            <Button className="w-auto">Read More</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export const PostGrid: FC<TPostGrid> = ({ title, posts }) => {
  const sorted = useMemo(() => {
    return posts.sort((a, b) => {
      const aDate =
        !!a.content &&
        !!a.content.length &&
        a.content[0]?._type === 'postHero' &&
        !!a.content[0]?.date
          ? new Date(a.content[0]?.date)
          : new Date(a._createdAt);

      const bDate =
        !!b.content &&
        !!b.content.length &&
        b.content[0]?._type === 'postHero' &&
        !!b.content[0]?.date
          ? new Date(b.content[0]?.date)
          : new Date(b._createdAt);

      return compareDesc(aDate, bDate);
    });
  }, [posts]);

  return (
    <BlockWrapper className="PostGrid">
      <div className="PostGrid__title">
        <h1 className="mb-12 lg:mb-20 w-full text-center font-grotesk-headline-news text-2xl md:text-3xl">
          {title}
        </h1>
      </div>
      <div className="PostGrid__posts max-w-prose mx-auto">
        {sorted &&
          sorted.map(post => <PostGridItem key={`PostGrid__post--${post._id}`} post={post} />)}
      </div>
    </BlockWrapper>
  );
};

export default PostGrid;
