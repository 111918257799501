import React, { FC, useEffect, useState } from 'react';
import uniq from 'lodash/uniq';

import { BlockWrapper, ProductCard, Button } from 'components';
import { Product, Block } from 'types';

const DEFAULT_FILTER = 'all';
const DEFAULT_LABEL = 'All Products';

export type TProductGrid = Block<'productGrid', { title: string; products: Product[] }>;
export const ProductGrid: FC<TProductGrid> = ({ title, products, meta }) => {
  const [filter, setFilter] = useState<string>(DEFAULT_FILTER);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>(products);

  useEffect(() => {
    setFilteredProducts(
      filter === DEFAULT_FILTER
        ? products.filter(product => product.store.status === 'active')
        : products.filter(
            product => product.store.status === 'active' && product.store.productType === filter
          )
    );
  }, [filter, products]);

  return (
    <BlockWrapper className="ProductGrid">
      <div className="ProductGrid__header mb-8 flex flex-col lg:flex-row lg:flex-wrap">
        {meta.isFirstBlockWithTitle ? (
          <h1 className="ProductGrid__title w-full text-center font-grotesk-headline-news text-2xl md:text-3xl lg:w-1/2 lg:text-left">
            {title}
          </h1>
        ) : (
          <h2 className="ProductGrid__title w-full text-center font-grotesk-headline-news text-2xl md:text-3xl lg:w-1/2 lg:text-left">
            {title}
          </h2>
        )}
        <div className="ProductGrid__filters mt-6 flex w-full flex-wrap items-start justify-center lg:mt-0 lg:w-1/2 lg:justify-end">
          <Button
            className="mb-3 ml-3"
            variant="fill"
            size="sm"
            color={filter === 'all' ? 'black' : 'gray'}
            onClick={() => setFilter(DEFAULT_FILTER)}
          >
            {DEFAULT_LABEL}
          </Button>
          {!!products &&
            !!products.length &&
            uniq(products.map(product => product.store.productType)).map((type: string) => {
              if (!type) return null;
              return (
                <div
                  className="ProductGrid__filters__dynamic-filters"
                  key={`ProductGrid__filter__${type}`}
                >
                  <Button
                    className="mb-3 ml-3"
                    variant="fill"
                    size="sm"
                    color={filter === type ? 'black' : 'gray'}
                    onClick={() => setFilter(type)}
                    key={`ProductGrid__filters__${type}`}
                  >
                    {type}
                  </Button>
                </div>
              );
            })}
        </div>
      </div>
      <div className="ProductGrid__grid grid grid-cols-1 md:grid-cols-2 gap-12 lg:grid-cols-4">
        {!!filteredProducts &&
          !!filteredProducts.length &&
          filteredProducts.map(product => (
            <ProductCard key={`ProductGrid-product-${product._id}`} product={product} />
          ))}
      </div>
    </BlockWrapper>
  );
};

export default ProductGrid;
