import cx from 'classnames';
import { FC, useEffect } from 'react';

import { Button, Image, FormWrapper, TextField } from 'components';
import iconClose from 'public/images/v2-icon-close.svg';

type Props = {
  email: string;
  isActive: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: () => void;
  onChange: (email: string) => void;
  error?: { form?: string; email?: string };
};

const NewsletterPopup: FC<Props> = ({
  isActive,
  isLoading,
  onClose,
  onSubmit,
  onChange,
  email,
  error
}) => {
  useEffect(() => {
    console.warn(`Experiment Variant: Control`);
  }, []);

  return (
    <div
      className={cx(
        'NewsletterPopup fixed bottom-0 left-0 right-0 w-full z-50 transition-transform rounded-sm bg-stone-200 shadow-above',
        {
          'translate-y-full': !isActive
        }
      )}
    >
      <div className="NewsletterPopup__inner-wrapper container relative flex flex-wrap justify-center text-center pt-12 pb-16">
        <div className="NewsletterPopup__title-close-button-wrapper mb-5 flex items-center justify-between w-full">
          <div className="NewsletterPopup__title">
            <span className="md:whitespace-nowrap font-grotesk-headline-news text-2xl md:text-3xl tracking-wide text-charcoal">
              Stay in Touch
            </span>
          </div>
          <div className="NewsletterPopup__close-button mt-1">
            <Button variant="link" onClick={onClose}>
              <div className="relative h-[18px] w-[18px]">
                <Image src={iconClose} layout="fill" alt="Newsletter popup close icon" />
              </div>
            </Button>
          </div>
        </div>
        <div className="NewsletterPopup__message-form-wrapper flex w-full items-center flex-wrap md:flex-nowrap">
          <div className="NewsletterPopup__message text-left mb-4 md:w-1/2 md:pr-10 md:mb-0">
            <span className="text-sm">
              We’re always dreaming up new ways to get you outdoors. Sign up and we’ll share with
              you what we’re working on before anyone else. No spam here, just rugged, outdoorsy
              goodness.
            </span>
          </div>
          <FormWrapper
            className="NewsletterPopup__form-wrapper w-full md:w-1/2 flex flex-nowrap justify-between"
            id="NewsletterPopup-Subscribe"
            name="NewsletterPopup-Subscribe"
          >
            <div className="NewsletterPopup__email-input w-2/3 mr-4 mb-4 md:mb-0 flex-grow">
              <TextField
                className="whitespace-nowrap"
                id="Newsletter-popup-form-email"
                name="email"
                placeholder="Email Address"
                ariaLabel="Email Address"
                value={email}
                error={error?.email}
                showError={!!error?.email}
                onChange={value => onChange(value as string)}
              />
            </div>
            <div className="NewsletterPopup__signup-button">
              <Button type="button" disabled={isLoading} onClick={onSubmit}>
                {isLoading ? 'Sending' : 'Submit'}
              </Button>
            </div>
          </FormWrapper>
        </div>
        {error?.form && (
          <div className="NewsletterPopup__form-error absolute bottom-20 md:top-0 md:right-14">
            <span className="text-center text-sm text-fire">{error.form}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default NewsletterPopup;
