import React, { FC } from 'react';
import Link from 'next/link';

import urlFromProduct from 'utils/urlFromProduct';

import { Image } from 'components';
import { Product } from 'types';

const ProductCard: FC<{ product: Product }> = ({ product }) => {
  return (
    <Link href={urlFromProduct(product)}>
      <a className="ProductCard__product-link transition-transform hover:scale-[102%]">
        <div className="ProductCard__product">
          <div className="ProductCard__product-image relative mb-3 w-full bg-stone-50 pb-[100%] brightness-95">
            {!!product.store.previewImageUrl && (
              <Image
                src={product.store.previewImageUrl}
                alt={product.store.title}
                layout="fill"
                objectFit="contain"
              />
            )}
          </div>
          <div className="ProductCard__product-info flex justify-between">
            <span className="pr-12 font-grotesk-headline text-sm">{product.store.title}</span>
            <span className="text-stone-500 text-sm">
              ${product.store.priceRange.minVariantPrice.toFixed(2)}
            </span>
          </div>
        </div>
      </a>
    </Link>
  );
};

export default ProductCard;
