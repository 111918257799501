import React, { FC, useCallback, useEffect, useState } from 'react';
import { animateScroll } from 'react-scroll';
import Link from 'next/link';
import cx from 'classnames';

import { Block, Image as TImage } from 'types';
import { BlockWrapper, Button, Image } from 'components';
import { useNavContext } from 'contexts/NavContext';

import iconDownArrowWhite from '../../public/images/v2-icon-down-white.svg';
import iconDownArrowBlack from '../../public/images/v2-icon-down-charcoal.svg';
import ifLogo from '../../public/images/escapod-if-logo.png';
import redDotLogo from '../../public/images/escapod-red-dot-logo.png';

export type THero = Block<
  'hero',
  {
    title?: string;
    theme: 'white' | 'black';
    showBumper?: boolean;
    mobileImage: TImage;
    desktopImage: TImage;
    nudgeButton?: string;
  }
>;

// TO-DO: Add the ability to "crop and hotspot" from Sanity
export const Hero: FC<THero> = ({
  nudgeButton,
  theme,
  mobileImage,
  desktopImage,
  showBumper = false
}) => {
  const nudgeIcon = theme === 'black' ? iconDownArrowBlack : iconDownArrowWhite;
  const navContext = useNavContext();
  const [bumperIsActive, setBumperIsActive] = useState(false);

  const onNudgeButtonClick = useCallback(() => {
    animateScroll.scrollMore(300);
  }, []);

  useEffect(() => {
    if (!!theme && theme !== navContext.theme) navContext.setContext({ ...navContext, theme });
  }, [navContext, theme]);

  useEffect(() => {
    if (showBumper && !bumperIsActive) setTimeout(() => setBumperIsActive(true), 2000);
  }, [showBumper, bumperIsActive, setBumperIsActive]);

  return (
    <BlockWrapper layout="full" className="Hero relative overflow-hidden">
      <div className="Hero__mobile-image-wrapper relative h-[600px] lg:hidden">
        <Image
          src={mobileImage.src}
          layout="fill"
          objectFit="cover"
          alt={mobileImage.alt}
          priority={true}
        />
      </div>
      <div className="Hero__desktop-image-wrapper relative hidden h-[600px] lg:block">
        <Image
          src={desktopImage.src}
          layout="fill"
          objectFit="cover"
          alt={desktopImage.alt}
          quality={90}
          priority={true}
        />
      </div>
      {!!nudgeButton && (
        <div className="Hero__nudge-button absolute bottom-8 flex w-full justify-center">
          <Button
            variant="no-style"
            onClick={onNudgeButtonClick}
            className="flex h-[42px] flex-col items-center justify-between transition-all hover:h-[52px] hover:translate-y-2"
          >
            <span
              className={cx('font-grotesk-sub-headline-news text-sm tracking-widest', {
                'text-white': theme === 'white',
                'text-charcoal': theme === 'black'
              })}
            >
              {nudgeButton}
            </span>
            <div className="w-5">
              <Image src={nudgeIcon} alt="Scroll page down icon" />
            </div>
          </Button>
        </div>
      )}
      {showBumper && (
        <div className="absolute bottom-8 w-full">
          <div
            className={cx('container transition-all duration-[.5s]', {
              'translate-y-full opacity-0': !bumperIsActive
            })}
          >
            <Link href="/blog/topo2-design-awards">
              <a className="flex justify-end items-center">
                <span
                  className={cx(
                    'font-grotesk-headline-news text-xs md:text-sm tracking-widest uppercase mr-4 animate-bounce',
                    {
                      'text-white': theme === 'white',
                      'text-charcoal': theme === 'black'
                    }
                  )}
                >
                  Award Winning →
                </span>
                <div className="mr-4">
                  <Image
                    hideLoadingPlaceholder={true}
                    width="110"
                    height="57"
                    src={ifLogo}
                    alt="iF Design Awards 2023 Logo for Escapod Trailers"
                  />
                </div>
                <div className="mr-0">
                  <Image
                    hideLoadingPlaceholder={true}
                    width="223"
                    height="57"
                    src={redDotLogo}
                    alt="Red Dot Design Awards 2023 Logo for Escapod Trailers"
                  />
                </div>
              </a>
            </Link>
          </div>
        </div>
      )}
    </BlockWrapper>
  );
};

export default Hero;
