import { FC, useEffect } from 'react';
import { Button } from 'components';
import fireworks from 'utils/fireworks';

import { useFinancingContext } from 'contexts/FinancingContext';

const Confirmation: FC = () => {
  const { result } = useFinancingContext();

  useEffect(() => {
    if (result?.result === 'APPROVED') fireworks();
  }, [result]);

  const title =
    result?.result === 'APPROVED'
      ? `Congratulations, You're Pre-Approved!`
      : `Thank you for your interest!`;

  const body =
    result?.result === 'APPROVED'
      ? `Based on the information you provided, we are happy to pre-approve you for in-house financing. Check your email for more information. An Escapod team member will reach out soon with more information.`
      : `Unfortunately, based on the information you provided we cannot offer you in-house financing at this time. Check your email for more information. An Escapod team member will reach out soon with more information.`;

  return (
    <div className="FinancingApplication__Confirmation flex flex-col items-center justify-center w-full animate-fade-in">
      <div className="flex flex-col max-w-prose mx-auto text-center justify-center items-center">
        <h2 className="ImageText__title my-8 w-full text-center font-grotesk-headline-news text-2xl md:text-3xl mx-auto">
          {title}
        </h2>
        <span className="text-lg">{body}</span>
        {result?.result === 'APPROVED' && (
          <div className="mt-12">
            <Button variant="fill" href="/order-now/topo-2">
              Build Your TOPO2
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Confirmation;
