const Styles = {
  style: {
    fonts: {
      family: 'Escapod Grotesk News',
      src: `url(/fonts/EscapodGroteskNews.ttf)`
    },
    base: {
      border: '1px solid #aaa',
      background: 'green',
      color: '#222',
      fontFamily: 'Escapod Grotesk News',
      fontSmoothing: 'antialiased',
      fontSize: '14px',
      letterSpacing: '.5px',
      '::placeholder': {
        color: 'rgb(168 162 158)'
      }
    },
    invalid: {
      color: '#DF002A',
      iconColor: '#DF002A'
    }
  }
};

export default Styles;
