import React, { FC, useCallback, useState, useMemo, useEffect } from 'react';
import cx from 'classnames';

import { useCartMutate } from 'contexts/CartContext';

import { Product, Block, ProductVariant } from 'types';
import { BlockWrapper, Button, Image, TextField, PortableText, Select } from 'components';

import base64 from 'utils/base64';

export type TProductHero = Block<'productHero', { title: string; product: Product }>;
export const ProductHero: FC<TProductHero> = ({ title, product }) => {
  const cartMutate = useCartMutate();
  const [quantity, setQuantity] = useState<number>(1);

  const [selectedOptions, setSelectedOptions] = useState<{ [key: string]: string }>(
    product.store.options.reduce((options: { [key: string]: string }, option) => {
      options[option.name] = option.values[0];
      return options;
    }, {})
  );

  const selectedVariant = useMemo(
    () =>
      product.store.variants.find(variant => {
        const optionKeys = Object.keys(variant.store).filter(key => key.includes('option'));
        const optionValues = optionKeys.map(
          key => variant.store[key as keyof ProductVariant['store']]
        );
        return optionValues?.every(
          value =>
            Object.values(selectedOptions).includes(value as keyof ProductVariant['store']) ||
            !value
        );
      }),
    [product, selectedOptions]
  );

  const images = useMemo(
    () =>
      product.store.variants.reduce((images: { [key: string]: string }, variant) => {
        images[variant.store.id] = variant.store.previewImageUrl;
        return images;
      }, {}),
    [product]
  );
  const [selectedImage, setSelectedImage] = useState<string>(
    images[selectedVariant?.store.id || ''] || product.store.previewImageUrl
  );

  useEffect(() => {
    const selectedVariantImage = images[selectedVariant?.store.id || ''];
    if (selectedVariantImage) setSelectedImage(selectedVariantImage);
  }, [images, selectedVariant]);

  const _addToCart = useCallback(() => {
    if (selectedVariant && cartMutate) {
      cartMutate.addLineItems([
        {
          // TO-DO: create a utility to for this btoa
          variantId: base64(`gid://shopify/ProductVariant/${selectedVariant.store.id}`),
          quantity
        }
      ]);
    }
  }, [quantity, selectedVariant, cartMutate]);

  const _selectVariant = useCallback(
    (newOptionValue: object) => {
      setSelectedOptions({ ...selectedOptions, ...newOptionValue });
    },
    [selectedOptions]
  );

  const _setQuantity = useCallback(
    (value: number) => {
      setQuantity(value < 1 ? 1 : value);
    },
    [setQuantity]
  );

  if (!product || !selectedVariant) return <div>No Product 404</div>;

  const variantOptionsArr = product.store.options.reduce((previousValue: string[], optionGroup) => {
    return previousValue.concat(optionGroup.values);
  }, []);

  const hasMorethanOneVariant: boolean = variantOptionsArr.length > 1;

  return (
    <BlockWrapper className="ProductHero -mt-12 flex flex-wrap items-center">
      <div className="ProductHero__image-container w-full lg:w-1/2">
        <div className="ProductHero__product-image relative mb-3 w-full bg-stone-50 pb-[100%] brightness-95">
          {!!selectedImage && (
            <Image
              src={selectedImage}
              alt={product.store.title}
              layout="fill"
              objectFit="contain"
            />
          )}
        </div>
        {Object.values(images).length > 1 && (
          <div className="ProductHero__image-thumbnails flex my-6">
            {Object.keys(images).map(variantId => {
              if (!images[variantId]) return null;

              return (
                <Button
                  key={`ProductHero__thumbnail-button--${variantId}`}
                  className={cx('relative border-[1px] mr-6 last-of-type:mr-0', {
                    'border-stone-300': images[variantId] === selectedImage,
                    'border-transparent': images[variantId] !== selectedImage
                  })}
                  variant="no-style"
                  onClick={() => setSelectedImage(images[variantId])}
                >
                  <Image src={images[variantId]} alt="Select image" width="80" height="80" />
                </Button>
              );
            })}
          </div>
        )}
      </div>
      <div className="ProductHero__info-container w-full lg:w-1/2 lg:p-12">
        <h1 className="ProductHero__title mt-6 mb-8 w-full font-grotesk-headline-news text-2xl lg:mt-0 lg:text-3xl">
          {title}
        </h1>
        {hasMorethanOneVariant && (
          <div className="ProductHero__options mb-8 flex">
            {product.store.options.map(option => (
              <div
                key={`${option._key}-product-hero-selector`}
                className="ProductHero__option mr-6"
              >
                <Select
                  variant="secondary"
                  label={option.name}
                  ariaLabel={option.name}
                  name={option.name}
                  onChange={value => _selectVariant({ [option.name]: value })}
                >
                  {option.values.map(value => (
                    <option key={`${option._key}-${value}-product-hero-option`} value={value}>
                      {value}
                    </option>
                  ))}
                </Select>
              </div>
            ))}
          </div>
        )}
        {!!product.description && (
          <div className="ProductHero__description text-md mb-8 font-grotesk-news leading-relaxed tracking-wide">
            <PortableText content={product.description} />
          </div>
        )}
        <div className="ProductHero__add-ui flex flex-wrap justify-start">
          <Button variant="fill" onClick={_addToCart}>
            Add to Cart - ${(selectedVariant.store.price * quantity).toFixed(2)}
          </Button>
          <TextField
            className="ml-3 w-[80px]"
            ariaLabel="Quantity"
            name="quantity"
            type="number"
            value={quantity}
            onChange={value => _setQuantity(parseInt(value.toString()))}
          />
        </div>
      </div>
    </BlockWrapper>
  );
};

export default ProductHero;
