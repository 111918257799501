import React, { FC } from 'react';
import cx from 'classnames';

import { Button, Image } from 'components';
import { LinkCard as TLinkCard } from 'types';

type Props = {
  link: TLinkCard;
  className?: string;
};

export const LinkCard: FC<Props> = ({ link, className = '' }) => {
  const { title, subtitle, button, image } = link;
  if (!title || !button) return null;

  const imageWidth = image?.metadata?.dimensions.width || 1;
  const imageHeight = image?.metadata?.dimensions.height || 1;

  return (
    <div className={cx('LinkCard', className)}>
      {!!image && (
        <div
          className="LinkCard__image relative"
          style={{
            paddingBottom: `${(imageHeight / imageWidth) * 100}%`
          }}
        >
          <Image layout="fill" src={image.src} objectFit="contain" alt={image.alt} />
        </div>
      )}
      <div className="bg-stone-200 w-full flex flex-col lg:flex-row p-4 md:p-5 justify-center items-center lg:items-start lg:h-36">
        <div className="flex flex-col lg:flex-row justify-center items-end h-full w-full">
          <div className="LinkCard__info flex flex-col justify-between w-full h-full text-center lg:text-left mb-4 lg:mb-0">
            <div className="LinkCard__title mb-4 w-full">
              <span className="text-sm font-grotesk-headline-news tracking-wide">{title}</span>
            </div>
            <div className="w-full flex flex-col lg:flex-row lg:items-end">
              {!!subtitle && (
                <div className="LinkCard__subtitle lg:pr-4 2xl:pr-6">
                  <span className="text-xs font-grotesk tracking-wide">{subtitle}</span>
                </div>
              )}
              <div className="LinkCard__Button flex justify-center lg:flex-none mt-6">
                <Button
                  className="flex md:hidden 2xl:flex"
                  variant="fill"
                  size="sm"
                  href={link.button.url}
                >
                  {link.button.label}
                </Button>
                <Button
                  className="hidden md:flex 2xl:hidden"
                  variant="fill"
                  size="sm"
                  href={link.button.url}
                >
                  {link.button.label}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkCard;
