import React, { FC } from 'react';
import Image from 'next/image';

import iconCheck from '../../public/images/icon-check.png';

import { Block } from 'types';
import { BlockWrapper } from 'components';

export type TFeatureList = Block<
  'featureList',
  {
    title: string;
    features: {
      _key: string;
      name: string;
    }[];
  }
>;

export const FeatureList: FC<TFeatureList> = ({ title, features }) => {
  return (
    <BlockWrapper>
      <div className="FeatureList____title mb-8">
        <span className="font-grotesk-sub-headline text-fire text-xl lg:text-2xl xl:text-3xl">
          {title}
        </span>
      </div>
      <div className="FeatureList__feature__wrapper md:flex md:flex-wrap md:justify-between">
        {features.map(feature => (
          <div
            className="FeatureList__feature md:w-[48%] md:flex"
            key={`FeatureList__feature-${feature._key}`}
          >
            <div className="FeatureList__feature__name-icon-wrapper md:w-full flex items-center justify-between border-b-[1px] border-b-stone-200">
              <div className="Builder__feature__name w-3/4 pr-1 my-4">
                <span className="font-grotesk-news text-sm lg:text-base">{feature.name}</span>
              </div>
              <div className="Builder__feature__icon flex w-[80px] justify-center rounded-sm bg-fire py-[0.85rem]">
                <div className="flex w-4 h-3 relative">
                  <Image src={iconCheck} alt="Included check mark icon" layout="fill" />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </BlockWrapper>
  );
};
export default FeatureList;
