import React, { FC, useCallback, useState } from 'react';
import ReactPlayer from 'react-player';
import cx from 'classnames';

import { Block, Image as TImage, VideoEmbed } from 'types';
import { BlockWrapper, Image, Button } from 'components';
import iconPlayButton from '../../public/images/icon-play-button.svg';

export type TVideo = Block<
  'video',
  {
    title?: string;
    thumbnail: TImage;
    videoEmbed: VideoEmbed;
    videoButton?: { url: string; label: string };
  }
>;
export const Video: FC<TVideo> = ({ title, thumbnail, videoEmbed, videoButton, meta }) => {
  const [thumbnailIsHidden, setThumbnailIsHidden] = useState<boolean>(false);
  const [videoIsPlaying, setVideoIsPlaying] = useState<boolean>(false);

  const onPlayButtonClick = useCallback(() => {
    setThumbnailIsHidden(true);
    setVideoIsPlaying(true);
  }, []);

  const handleVideoButtonClick = () => window.open(videoButton?.url);

  return (
    <BlockWrapper
      className={cx('Video relative text-left', {
        'mb-28 lg:mb-24': !!videoButton
      })}
    >
      <div className="Video__inner-wrapper mb-6 relative">
        <div className="Video__title-button-wrapper lg:flex lg:flex-nowrap lg:justify-between">
          {!!title && meta.isFirstBlockWithTitle && (
            <div className="Video__title mb-6 md:mb-8">
              <h1 className="font-grotesk-headline-news text-2xl md:text-3xl">{title}</h1>
            </div>
          )}
          {!!title && !meta.isFirstBlockWithTitle && (
            <div className="Video__title mb-6 md:mb-8">
              <h2 className="font-grotesk-headline-news text-2xl md:text-3xl">{title}</h2>
            </div>
          )}
          {!!videoButton && (
            <div className="Video__button absolute -bottom-16 left-0 lg:relative lg:bottom-0">
              <Button variant="fill" onClick={() => handleVideoButtonClick()}>
                {videoButton?.label}
              </Button>
            </div>
          )}
        </div>
        <div
          className="Video__player-wrapper relative"
          style={{ paddingBottom: `${(videoEmbed.height / videoEmbed.width) * 100}%` }}
        >
          <ReactPlayer
            url={videoEmbed.url}
            className="Video__player absolute top-0 left-0"
            width="100%"
            height="100%"
            playing={videoIsPlaying}
          />
          <div
            className={cx(
              'Video__thumbnail-wrapper absolute flex h-full w-full items-center justify-center bg-black transition-opacity',
              {
                'pointer-events-none opacity-0': thumbnailIsHidden
              }
            )}
          >
            <Image
              className="Video__thumbnail opacity-80"
              src={thumbnail.src}
              alt={thumbnail.alt || ''}
              layout="fill"
              objectFit="cover"
            />
            <Button
              variant="no-style"
              className="Video__play-button-wrapper absolute flex items-center justify-center md:bottom-9 md:left-9 md:hover:brightness-200 lg:bottom-10 lg:left-10"
              onClick={onPlayButtonClick}
            >
              <div className="Video__play-button-icon mr-2 w-11 md:w-12 md:brightness-90 lg:w-16">
                <Image
                  src={iconPlayButton}
                  alt="Video play button icon"
                  hideLoadingPlaceholder={true}
                />
              </div>
              <span className="Video__play-button-text font-grotesk-headline text-base text-white">
                Watch Video
              </span>
            </Button>
          </div>
        </div>
      </div>
    </BlockWrapper>
  );
};
export default Video;
