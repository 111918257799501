import React, { FC, useState, useCallback, useEffect, useRef } from 'react';
import cx from 'classnames';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { IoLocationSharp } from 'react-icons/io5';
import { BsBagFill as BagIcon } from 'react-icons/bs';
import * as Sentry from '@sentry/nextjs';

import emailIsValid from 'utils/emailIsValid';
import linkIsRelative from 'utils/linkIsRelative';
import * as Gtag from 'clients/Gtag';
import EscapodAPI from 'clients/Escapod';
import Fbq from 'clients/Fbq';
import { useNavContext } from 'contexts/NavContext';
import { useCartState, useCartMutate } from 'contexts/CartContext';
import useScrollDistance from 'hooks/useScrollDistance';

import { Button, TextField, FormWrapper } from 'components';
import { Menu } from 'types';

import iconClose from '../public/images/v2-icon-close.svg';
import iconMenuWhite from '../public/images/v2-icon-menu-white.svg';
import iconMenuBlack from '../public/images/v2-icon-menu.svg';
import logoWhite from '../public/images/v2-logo-white.svg';
import logoBlack from '../public/images/v2-logo.svg';
import t2logo from '../public/images/escapod-topo2-logo-legacy-red.svg';
import emblem from '../public/images/escapod-emblem.svg';
import immersiveCloseIcon from '../public/images/escapod-immersive-close-icon.svg';

const CONVERSION_ROUTES = ['/order-now', '/rentals'];

const Nav: FC<{ menu: Menu[]; immersive?: boolean }> = ({ menu, immersive = false }) => {
  const navContext = useNavContext();
  const cartState = useCartState();
  const cartMutate = useCartMutate();
  const scrollDistance = useScrollDistance();

  const prevPath = useRef('');
  const currentPath = useRef('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [isSending, setIsSending] = useState<boolean>(false);
  const [error, setError] = useState<{ [key: string]: string }>({});
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const openMenu = useCallback(() => setMenuIsOpen(true), [setMenuIsOpen]);
  const closeMenu = useCallback(() => setMenuIsOpen(false), [setMenuIsOpen]);
  const [cartIsOpen, setCartIsOpen] = useState<boolean>(false);
  const openCart = useCallback(() => setCartIsOpen(true), [setCartIsOpen]);
  const closeCart = useCallback(() => setCartIsOpen(false), [setCartIsOpen]);

  const hasScrolledBeyondHero = scrollDistance > 400;
  const computedTheme = menuIsOpen || hasScrolledBeyondHero ? 'black' : navContext.theme;
  const computedVariant = hasScrolledBeyondHero ? 'fill' : 'stroke';
  const lineItemCount = cartState?.lineItems.reduce(
    (total: number, item) => total + item.quantity,
    0
  );

  const page = useRouter();

  const submit = useCallback(() => {
    setError({});
    setIsSending(true);

    if (!firstName) return setError({ firstName: 'Enter your first name.' });
    if (!firstName) return setError({ lastName: 'Enter your last name.' });
    if (!email || !emailIsValid(email)) return setError({ email: 'Enter a valid email address.' });

    Sentry.setUser({ email });

    EscapodAPI.klaviyo
      .subscribe({ firstName, lastName, email, form: 'navigation_overlay' })
      .then(res => {
        if (res.status === 201) {
          Fbq('Subscribe', { value: '0.00', currency: 'USD', predicted_ltv: '0.00' });
          Gtag.event('conversion', {
            send_to: 'AW-814232277/VfPlCMato6YDENXloIQD',
            transaction_id: window.btoa(email),
            event_callback: () => {
              return;
            }
          });
          setError({ form: 'Thank you for your submission!' });
          setTimeout(() => {
            setError({});
            setFirstName('');
            setLastName('');
            setEmail('');
            setIsSending(false);
          }, 3000);
        }
      })
      .catch(err => {
        setIsSending(false);
        if (err.response.status === 400) {
          if (err.response.data.message === 'email invalid') {
            return setError({ email: 'Please enter a valid email address.' });
          }
          if (err.response.data.message === 'listId invalid') {
            return setError({ form: 'Something went wrong. Please try again later!' });
            // TO-DO: Add Sentry to notify developers of a listId malfunction
          }
          if (err.response.data.message === 'member exists') {
            return setError({
              form: "You've already subscribed with that email address! Try a different one."
            });
          }
        }
        return setError({ form: 'Something went wrong. Please try again later!' });
        // TO-DO: Add Sentry to notify developers of an unknown malfunction
      });
  }, [firstName, lastName, email]);

  const removeItem = useCallback(
    (item: string) => {
      if (cartMutate) {
        const lineItems = [item];

        cartMutate?.removeLineItems(lineItems);
      }
    },
    [cartMutate]
  );

  const _closeImmersive = useCallback(() => {
    if (!prevPath.current || page.asPath.includes('/info')) return page.push('/');

    return page.back();
  }, [page, prevPath]);

  useEffect(() => {
    prevPath.current = currentPath.current;
    currentPath.current = page.asPath;
  }, [page, prevPath]);
  useEffect(closeMenu, [page.asPath, closeMenu]);
  useEffect(() => {
    if ((lineItemCount || 0) < 1 && cartIsOpen) closeCart();
  }, [lineItemCount, closeCart, cartIsOpen]);

  const isConversionRoute = CONVERSION_ROUTES.some(route => page.asPath.includes(route));

  return (
    <nav className="Nav">
      <div
        className={cx('Nav__top-bar fixed top-6 z-50 w-full lg:top-16 pointer-events-none', {
          'opacity-0': immersive
        })}
      >
        <div
          className={cx('Nav__top-bar__inner relative mx-auto flex justify-between', {
            container: !immersive,
            'pl-6': immersive
          })}
        >
          <div className="Nav__menu-toggle fixed top-6 right-6 mt-[5px] flex w-1/2 items-center justify-end lg:absolute lg:right-auto lg:top-0 lg:-left-6 lg:mt-2 lg:w-[20px]">
            <div className="relative mr-8 mt-[-6px] items-center !cursor-pointer justify-center flex lg:hidden pointer-events-auto">
              <Link aria-label="Trailer Finder" href="/trailer-finder">
                <a className="w-full !cursor-pointer relative group flex items-center">
                  <IoLocationSharp
                    className={cx(
                      'absolute w-5 h-5 group-hover:fill-fire transition-all !cursor-pointer fill-white',
                      {
                        'opacity-0 pointer-events-none': computedTheme === 'black'
                      }
                    )}
                  />
                  <IoLocationSharp
                    className={cx(
                      'absolute w-5 h-5 group-hover:fill-fire transition-all !cursor-pointer fill-charcoal',
                      {
                        'opacity-0 pointer-events-none': computedTheme === 'white'
                      }
                    )}
                  />
                </a>
              </Link>
            </div>
            {!!lineItemCount && (
              <div className="relative mr-4 mb-[9px] h-6 w-6 items-center justify-center lg:hidden">
                <Button
                  variant="no-style"
                  onClick={openCart}
                  className={cx('w-full pointer-events-auto', {
                    '!pointer-events-none': immersive
                  })}
                >
                  <BagIcon
                    className={cx('absolute h-full w-full fill-white transition-opacity', {
                      'opacity-0': computedTheme === 'black'
                    })}
                  />
                  <BagIcon
                    className={cx('absolute h-full w-full fill-charcoal transition-opacity', {
                      'opacity-0': computedTheme === 'white'
                    })}
                  />
                  <span
                    className={cx(
                      'Nav__line-item-count relative z-10 mt-2 block font-grotesk-headline text-xs',
                      {
                        'text-white': computedTheme === 'black',
                        'text-charcoal': computedTheme === 'white'
                      }
                    )}
                  >
                    {lineItemCount}
                  </span>
                </Button>
              </div>
            )}
            <div className="relative w-[20px]">
              {menuIsOpen ? (
                <button
                  className={cx('pointer-events-auto', { '!pointer-events-none ': immersive })}
                  onClick={closeMenu}
                >
                  <Image src={iconClose} alt="Close Menu Icon" />
                </button>
              ) : (
                <button
                  className={cx('pointer-events-auto', { '!pointer-events-none ': immersive })}
                  onClick={openMenu}
                >
                  <span className="absolute top-0 left-0">
                    <Image
                      className={cx('transition-opacity', {
                        'opacity-0': computedTheme !== 'black'
                      })}
                      src={iconMenuBlack}
                      alt="Menu Icon in Black"
                    />
                  </span>
                  <span className="absolute top-0 left-0">
                    <Image
                      className={cx('transition-opacity', {
                        'opacity-0': computedTheme !== 'white'
                      })}
                      src={iconMenuWhite}
                      alt="Menu Icon in White"
                    />
                  </span>
                </button>
              )}
            </div>
          </div>
          <div className="Nav__logo relative w-1/2">
            <Link href="/">
              <a
                className={cx({
                  'pointer-events-none': hasScrolledBeyondHero && !menuIsOpen,
                  'pointer-events-auto': !hasScrolledBeyondHero || menuIsOpen,
                  '!pointer-events-none': immersive
                })}
              >
                <span className="absolute top-0 left-0">
                  <Image
                    className={cx('transition-opacity', {
                      'opacity-0':
                        computedTheme !== 'black' || (hasScrolledBeyondHero && !menuIsOpen)
                    })}
                    width="200"
                    height="35"
                    src={logoBlack}
                    alt="Escapod Logo Black"
                  />
                </span>
                <span className="absolute top-0 left-0">
                  <Image
                    className={cx('transition-opacity', {
                      'opacity-0':
                        computedTheme !== 'white' || (hasScrolledBeyondHero && !menuIsOpen)
                    })}
                    width="200"
                    height="35"
                    src={logoWhite}
                    alt="Escapod Logo White"
                  />
                </span>
              </a>
            </Link>
          </div>
          <div
            className={cx(
              'Nav__buttons fixed bottom-6 left-0 flex w-full px-6 lg:relative lg:bottom-auto lg:w-1/2 lg:items-center lg:justify-end lg:px-0',
              { 'pointer-events-none opacity-0 lg:pointer-events-auto lg:opacity-100': menuIsOpen },
              { '!pointer-events-none': immersive }
            )}
          >
            <div className="relative mr-12 hidden items-center !cursor-pointer justify-center lg:flex pointer-events-auto">
              <Link aria-label="Trailer Finder" href="/trailer-finder">
                <a className="w-full !cursor-pointer relative group flex items-center">
                  <span
                    className={cx(
                      'absolute opacity-0 group-hover:opacity-[1] left-0 -translate-x-full whitespace-nowrap font-grotesk-headline text-xs uppercase tracking-widest transition-all py-4 pr-4',
                      {
                        'text-white': computedTheme === 'white',
                        'text-charcoal': computedTheme === 'black'
                      }
                    )}
                  >
                    Trailer Finder
                  </span>
                  <IoLocationSharp
                    className={cx(
                      'absolute w-8 h-8 group-hover:fill-fire transition-all !cursor-pointer fill-white',
                      {
                        'opacity-0 pointer-events-none': computedTheme === 'black'
                      }
                    )}
                  />
                  <IoLocationSharp
                    className={cx(
                      'absolute w-8 h-8 group-hover:fill-fire transition-all !cursor-pointer fill-charcoal',
                      {
                        'opacity-0 pointer-events-none': computedTheme === 'white'
                      }
                    )}
                  />
                </a>
              </Link>
            </div>
            {!!lineItemCount && (
              <div className="relative mr-6 mb-1 hidden h-7 w-7 items-center justify-center lg:flex">
                <Button
                  variant="no-style"
                  className={cx('flex items-center justify-center pointer-events-auto', {
                    '!pointer-events-none': immersive
                  })}
                  onClick={openCart}
                >
                  <BagIcon
                    className={cx('absolute h-full w-full fill-white transition-opacity', {
                      'opacity-0': computedTheme === 'black'
                    })}
                  />
                  <BagIcon
                    className={cx('absolute h-full w-full fill-charcoal transition-opacity', {
                      'opacity-0': computedTheme === 'white'
                    })}
                  />
                  <span
                    className={cx('relative z-10 mt-2 block font-grotesk-headline text-xs', {
                      'text-white': computedTheme === 'black',
                      'text-charcoal': computedTheme === 'white'
                    })}
                  >
                    {lineItemCount}
                  </span>
                </Button>
              </div>
            )}
            <div className="Nav__order-contact-buttons flex nowrap w-full lg:w-auto justify-center lg:justify-end">
              <div className="Nav__order-now-button pr-2 w-1/2 lg:w-auto hidden lg:block">
                <Button
                  variant={computedVariant}
                  href="/order-now/topo-2"
                  color={computedTheme}
                  className={cx('w-full lg:w-auto pointer-events-auto', {
                    '!pointer-events-none': immersive
                  })}
                >
                  Build & Price
                </Button>
              </div>
              <div
                className={cx('Nav__order-now-button-mobile pr-2 w-1/2 lg:w-auto lg:hidden', {
                  hidden: isConversionRoute,
                  'opacity-0': !hasScrolledBeyondHero
                })}
              >
                <Button
                  variant={computedVariant}
                  href="/order-now/topo-2"
                  className={cx('w-full lg:w-auto pointer-events-auto', {
                    '!pointer-events-none': immersive
                  })}
                >
                  Build & Price
                </Button>
              </div>
              <div className="Nav__contact-button pl-2 w-1/2 lg:w-auto hidden lg:block">
                <Button
                  href="/contact-us"
                  variant={computedVariant}
                  color={computedTheme}
                  className={cx('w-full lg:w-auto pointer-events-auto', {
                    '!pointer-events-none': immersive
                  })}
                >
                  Contact Us
                </Button>
              </div>
              <div
                className={cx('Nav__contact-button-mobile pl-2 w-1/2 lg:w-auto lg:hidden', {
                  hidden: isConversionRoute
                })}
              >
                <Button
                  href="/contact-us"
                  variant={computedVariant}
                  className={cx('w-full lg:w-auto pointer-events-auto', {
                    '!pointer-events-none': immersive,
                    'opacity-0': !hasScrolledBeyondHero
                  })}
                >
                  Contact Us
                </Button>
              </div>
            </div>
          </div>
          <div
            className={cx(
              'Nav__cart container absolute top-8 right-0 z-50 w-full lg:w-1/2 max-w-lg py-6 lg:top-[100%] transition-all pointer-events-none',
              {
                'pointer-events-auto': cartIsOpen,
                'opacity-0 translate-y-4': !cartIsOpen,
                '!pointer-events-none': immersive
              }
            )}
          >
            <div className="Nav__cart-inner h-full w-full rounded-sm bg-stone-200 p-6">
              <div className="Nav__cart-header flex justify-between border-b border-white pb-4">
                <span className="font-grotesk-headline text-2xl tracking-wider">Cart</span>
                <button onClick={closeCart}>
                  <Image src={iconClose} alt="Close" />
                </button>
              </div>
              <ul className="Nav__cart__items relative max-h-72 overflow-auto py-6">
                {cartState?.lineItems.map(item => (
                  <li
                    key={`Nav__cart__item--${item.id}`}
                    className="Nav__cart__item mb-4 flex border-b border-white pb-4 last-of-type:mb-0 last-of-type:border-0 last-of-type:pb-0"
                  >
                    <div className="Nav__cart__item__image">
                      <div className="relative mr-4 h-16 w-16">
                        <Image
                          alt={item.title}
                          src={item.variant.image.originalSrc}
                          layout="fill"
                          objectFit="scale-down"
                        />
                      </div>
                    </div>
                    <div className="flex w-full flex-col justify-between">
                      <div className="mb-2 flex flex-col">
                        <div className="flex justify-between">
                          <span className="pr-8 font-grotesk-headline text-xs lg:text-sm">
                            {item.title}
                          </span>
                          <span className="text-xs text-stone-500 lg:text-sm">
                            ${(parseFloat(item.variant.price.amount) * item.quantity).toFixed(2)}
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center justify-between">
                        <span
                          className={cx('text-xs text-stone-500', {
                            'hidden ': item.variant.title === 'Default Title'
                          })}
                        >
                          {item.variant.title}
                        </span>
                        <Button
                          variant="no-style"
                          className={cx('p-0 leading-[1rem]', {
                            'text-right w-full': item.variant.title === 'Default Title'
                          })}
                        >
                          <span
                            className="font-grotesk-news text-xs font-normal text-fire"
                            onClick={() => removeItem(item.id)}
                          >
                            Remove
                          </span>
                        </Button>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
              <div className="Nav__cart-footer relative flex items-center justify-between border-t border-white pt-6 before:absolute before:-top-[25px] before:left-0 before:h-6 before:w-full before:bg-gradient-to-t before:from-neutral-200">
                <span className="font-grotesk-headline">
                  <span className="text-fire">Subtotal: </span>
                  <span>${parseInt(cartState?.subtotalPrice?.amount || '0').toFixed(2)}</span>
                </span>
                <a href={cartState?.webUrl} target="_blank" rel="noreferrer">
                  <Button
                    variant="fill"
                    onClick={() => {
                      Fbq('InitiateCheckout'); // TO-DO: Make this useCallback on a LinkButton
                    }}
                  >
                    Checkout
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {immersive && (
        <div className="Nav__immersive px-6 lg:px-12 py-6 lg:py-10 xl:px-20 xl:py-16 w-full relative z-50 animate-fade-in bg-white">
          <div className="w-full flex">
            <div className="flex w-full lg:w-1/4 2xl:w-1/3 items-center">
              <Link href="/">
                <a>
                  <div className="hidden xl:block">
                    <Image
                      width="67"
                      height="48"
                      src={emblem}
                      alt="Escapod Emblem Logo in Legacy Fire color"
                    />
                  </div>
                  <div className="xl:hidden">
                    <Image
                      width="42"
                      height="30"
                      src={emblem}
                      alt="Escapod Emblem Logo in Legacy Fire color"
                    />
                  </div>
                </a>
              </Link>
              <div className="w-[2px] h-12 mx-4 bg-stone-200" />
              <Link href="/">
                <a>
                  <div className="hidden xl:block">
                    <Image
                      width="100"
                      height="26"
                      src={t2logo}
                      alt="Escapod TOPO2 Logo in Legacy Fire color"
                    />
                  </div>
                  <div className="xl:hidden">
                    <Image
                      width="70"
                      height="18.2"
                      src={t2logo}
                      alt="Escapod TOPO2 Logo in Legacy Fire color"
                    />
                  </div>
                </a>
              </Link>
            </div>
            <div className="hidden lg:flex flex-col w-1/2 2xl:w-1/3 text-center items-center justify-center">
              <span className="grotesk-headline font-grotesk-headline text-2xl xl:text-3xl tracking-wide">
                {navContext.immersive.title}
              </span>
              <div className="Builder__title__estimated-delivery">
                <span className="font-grotesk-news text-xs text-stone-400">
                  {navContext.immersive.subtitle}
                </span>
              </div>
            </div>
            <div className="flex w-1/4 2xl:w-1/3 text-right justify-end">
              <Button
                variant="no-style"
                onClick={_closeImmersive}
                className="items-center hidden lg:flex"
              >
                <span className="text-xs uppercase font-grotesk-headline mr-2">Close</span>
                <Image width="42" height="42" src={immersiveCloseIcon} alt="Escapod Close Icon" />
              </Button>
              <Button
                variant="no-style"
                onClick={_closeImmersive}
                className="items-center flex lg:hidden"
              >
                <Image width="32" height="32" src={immersiveCloseIcon} alt="Escapod Close Icon" />
              </Button>
            </div>
          </div>
        </div>
      )}
      <div
        className={cx(
          'Nav__menu w100 fixed top-0 bottom-0 left-0 right-0 z-40 bg-white transition-opacity flex items-center lg:-mt-24',
          {
            'opacity-0 pointer-events-none': !menuIsOpen
          }
        )}
      >
        <div className="Nav__menu__lists container h-full overflow-auto py-32 pr-2 lg:h-auto lg:pr-4 lg:pt-40 lg:pb-0">
          <ul className="flex flex-wrap">
            {menu.map(group => (
              <li
                key={group._key}
                className="mb-16 w-full pr-2 lg:w-[21%] lg:pr-4 lg:fifths:w-[16%] lg:fifths:pr-0"
              >
                <span className="mb-6 block font-grotesk-sub-headline-news text-sm uppercase tracking-[1px] text-fire">
                  {group.title}
                </span>
                {!!group.links.length && (
                  <ul>
                    {group.links.map(link => {
                      const classes =
                        'font-grotesk-news text-[11px] lg:text-[13px] tracking-wider text-charcoal';

                      return (
                        <li key={link._key} className="mb-3 leading-tight">
                          {linkIsRelative(link.url) ? (
                            <Link href={link.url}>
                              <a className={classes}>{link.title}</a>
                            </Link>
                          ) : (
                            // TO-DO: New tab optional
                            <a
                              href={link.url}
                              target="_blank"
                              rel="noopner noreferrer"
                              aria-label={link.title}
                              className={classes}
                            >
                              {link.title}
                            </a>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
        <div className="Nav__newsletter absolute bottom-0 left-0 hidden w-full bg-stone-200 lg:block">
          <div className="Nav__newsletter__inner flex items-center p-8">
            <span className="mr-12 whitespace-nowrap font-grotesk-headline text-xs tracking-wide text-charcoal">
              Newsletter Signup
            </span>
            {error.form && (
              <div className="NewsletterSignup__form-error absolute -top-8 right-8">
                <span className="text-center text-xs text-fire">{error.form}</span>
              </div>
            )}
            <FormWrapper
              id="Escapod-Nav-Menu-Newsletter-Subscribe"
              name="Escapod-Nav-Menu-Newsletter-Subscribe"
              className="flex w-full"
            >
              <TextField
                className="mr-8"
                id="Nav-newsletter-form-first-name"
                name="first_name"
                placeholder="First Name"
                ariaLabel="First Name"
                value={firstName}
                error={error.firstName}
                showError={!!error.firstName}
                onChange={value => setFirstName(value as string)}
              />
              <TextField
                className="mr-8"
                id="Nav-newsletter-form-last-name"
                name="last_name"
                placeholder="Last Name"
                ariaLabel="Last Name"
                value={lastName}
                error={error.lastName}
                showError={!!error.lastName}
                onChange={value => setLastName(value as string)}
              />
              <TextField
                className="mr-8"
                id="Nav-newsletter-form-email"
                name="email"
                placeholder="Email Address"
                ariaLabel="Email Address"
                value={email}
                error={error.email}
                showError={!!error.email}
                onChange={value => setEmail(value as string)}
              />
              <Button
                type="button"
                variant="fill"
                onClick={submit}
                className={cx('', {
                  'cursor-not-allowed opacity-50': isSending
                })}
              >
                Signup
              </Button>
            </FormWrapper>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
