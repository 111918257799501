import React, { FC } from 'react';

import { NewsletterPopup, ChatPopup } from 'components';

const Popups: FC = () => {
  return (
    <div className="Popups">
      <ChatPopup />
      <NewsletterPopup />
    </div>
  );
};

export default Popups;
