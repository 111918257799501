import React, { FC, useCallback, useState } from 'react';
import cx from 'classnames';
import AnimateHeight from 'react-animate-height';

import EscapodApi from 'clients/Escapod';
import Fbq from 'clients/Fbq';
import * as Gtag from 'clients/Gtag';
import Klaviyo from 'clients/Klaviyo/browser';

import { Block, PortableText as TPortableText } from 'types';
import { BlockWrapper, Button, Checkbox, Image, PortableText, Select, TextField } from 'components';
import loader from '../../public/images/loader.gif';
import emailIsValid from 'utils/emailIsValid';

export type TQuickLinkForm = Block<
  'quickLinkFormBlock',
  {
    title?: string;
    description: TPortableText;
    links: {
      _key: string;
      url: string;
      label: string;
    }[];
    staff?: {
      _key: string;
      name: string;
      type: string;
    }[];
  }
>;

type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  message: string;
  staff: string;
};
const INITIAL_FORM_DATA: FormData = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  message: '',
  staff: ''
};

export const QuickLinkForm: FC<TQuickLinkForm> = ({ title, links, staff, description }) => {
  const [formHasSubmitted, setFormHasSubmitted] = useState(false);
  const [formIsSending, setFormIsSending] = useState(false);
  const [optIn, setOptIn] = useState(false);
  const [messageIsOpen, setMessageIsOpen] = useState(false);

  const [formData, setFormData] = useState<FormData>(INITIAL_FORM_DATA);
  const [formErrors, setFormErrors] = useState<FormData & { form?: string }>(INITIAL_FORM_DATA);

  const submitForm = useCallback(() => {
    setFormErrors(INITIAL_FORM_DATA);

    if (!formData.firstName) {
      return setFormErrors({ ...INITIAL_FORM_DATA, firstName: 'Please enter a first name.' });
    }
    if (!formData.lastName) {
      return setFormErrors({ ...INITIAL_FORM_DATA, lastName: 'Please enter a last name.' });
    }
    if (!formData.email || !emailIsValid(formData.email)) {
      return setFormErrors({ ...INITIAL_FORM_DATA, email: 'Please enter a valid email.' });
    }

    setFormIsSending(true);

    const createContact = async () => {
      const response = await EscapodApi.contact.create({
        templateParams: {
          FIRST_NAME: formData.firstName,
          LAST_NAME: formData.lastName,
          EMAIL: formData.email,
          PHONE: formData.phone || '',
          SUBJECT: 'sales',
          MESSAGE: !!formData.staff
            ? `Team: ${formData.staff} <br />Message: ${formData.message}`
            : formData.message
        }
      });

      if (response.message === 'ok') {
        setFormErrors(state => ({ ...state, form: 'Your message has been received.' }));
        setTimeout(() => {
          setFormIsSending(false);
          setFormHasSubmitted(true);
          setFormData(INITIAL_FORM_DATA);
          setFormErrors(INITIAL_FORM_DATA);
        }, 3000);
      } else {
        setFormErrors(state => ({
          ...state,
          form: 'There was an unexpected error while submitting your message. Contact us directly by emailing hello@escapod.us or try again later.'
        }));

        setFormIsSending(false);
      }
    };

    Fbq('Contact');

    Gtag.event('contact_form_submission', {
      path: window?.location.pathname
    });
    Gtag.event('conversion', {
      send_to: 'AW-814232277/hlCNCP2unakYENXloIQD',
      event_callback: () => {
        return;
      }
    });

    EscapodApi.klaviyo.subscribe({ email: formData.email, form: 'contact_form' });
    Fbq('Subscribe', { value: '0.00', currency: 'USD', predicted_ltv: '0.00' });
    Gtag.event('conversion', {
      send_to: 'AW-814232277/VfPlCMato6YDENXloIQD',
      transaction_id: window.btoa(formData.email),
      event_callback: () => {
        return;
      }
    });

    Klaviyo.events.contact({
      name: `${formData.firstName} ${formData.lastName}`,
      phone: formData.phone,
      subject: 'sales',
      message: formData.message
    });

    createContact();
  }, [formData, setFormErrors]);

  return (
    <BlockWrapper layout="full" className="md:container lg:max-w-prose">
      {formHasSubmitted ? (
        <div className="animate-slide-up">
          {!!title && (
            <div className="QuickLinkForm__title mb-8 px-6 md:px-0">
              <h1 className="text-2xl md:text-3xl font-grotesk-headline-news">{title}</h1>
            </div>
          )}
          <div className="QuickLinkForm__subtitle mb-8 px-6 md:px-0">
            <span className="text-xs lg:text-lg font-grotesk-news">
              <PortableText content={description} />
            </span>
          </div>
          <div className="QuickLinkForm__item flex flex-wrap w-full border-t-stone-200 border-t-[1px] px-6 md:px-0 pt-8 pb-24">
            {links.map(item => {
              return (
                <div className="QuickLinkForm__item mb-4" key={`QuickLinkForm__item__${item._key}`}>
                  <Button className="w-full" variant="stroke" color="charcoal" href={item.url}>
                    {item.label}
                  </Button>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="animate-slide-up">
          <div className="QuickLinkForm__title mb-8 px-6 md:px-0">
            <h1 className="text-2xl md:text-3xl font-grotesk-headline-news">{`Let's Get Started`}</h1>
          </div>
          <div className="QuickLinkForm__subtitle mb-8 px-6 md:px-0">
            <span className="text-xs lg:text-lg font-grotesk-news">
              Enter your information below to be logged as an attendee of the event. This will
              automatically add you to the raffle ticket pool. Keep an eye on your inbox, we will
              notify the winner by email.
            </span>
          </div>
          <div className="QuickLinkForm__form w-full border-t-stone-200 border-t-[1px] pt-8 px-6 md:px-0 pb-24">
            <div className="grid grid-cols-2 gap-4 mb-6">
              <TextField
                ariaLabel="First Name"
                label="First Name"
                name="first_name"
                id="QuickLinks-first-name"
                placeholder="First Name"
                required={true}
                value={formData.firstName}
                showError={!!formErrors.firstName}
                onBlur={() => setFormErrors(state => ({ ...state, firstName: '' }))}
                onChange={value => setFormData({ ...formData, firstName: value as string })}
              />
              <TextField
                ariaLabel="Last Name"
                label="Last Name"
                name="last_name"
                id="QuickLinks-last-name"
                placeholder="Last Name"
                required={true}
                value={formData.lastName}
                showError={!!formErrors.lastName}
                onBlur={() => setFormErrors(state => ({ ...state, lastName: '' }))}
                onChange={value => setFormData({ ...formData, lastName: value as string })}
              />
            </div>
            <div className="grid grid-cols-2 gap-4 mb-6">
              <TextField
                ariaLabel="Email"
                label="Email"
                name="email"
                required={true}
                id="QuickLinks-email"
                placeholder="hello@escapod.us"
                value={formData.email}
                showError={!!formErrors.email}
                onBlur={() => setFormErrors(state => ({ ...state, email: '' }))}
                onChange={value => setFormData({ ...formData, email: value as string })}
              />
              <TextField
                ariaLabel="Phone Number"
                label="Phone"
                name="name"
                id="QuickLinks-phone"
                placeholder="435-555-0123"
                value={formData.phone}
                showError={!!formErrors.phone}
                onBlur={() => setFormErrors(state => ({ ...state, phone: '' }))}
                onChange={value => setFormData({ ...formData, phone: value as string })}
              />
            </div>
            <div className="w-full mt-4 flex flex-col">
              <label className="TextField__label mb-2 block font-grotesk-news text-xs tracking-wide text-stone-700">
                Who helped you today?
              </label>
              <Select
                className="mb-6"
                inputClassName={cx('transition-color', {
                  'border-fire': formErrors.staff,
                  'text-stone-400': !formData.staff
                })}
                placeholder="Pick a team member"
                ariaLabel="Staff"
                name="staff"
                value={formData.staff}
                showError={!!formErrors.staff}
                onBlur={() => setFormErrors(state => ({ ...state, staff: '' }))}
                onChange={value => setFormData({ ...formData, staff: value as string })}
              >
                <option value="" disabled selected>
                  Select a team member
                </option>
                <option value="Nobody Helped Me Today">Nobody helped me today</option>
                <option value="Someone but I do not know their name">
                  Someone, but I don&apos;t know their name
                </option>
                {staff?.map(member => (
                  <option key={member._key} value={member.name}>
                    {member.name}
                  </option>
                ))}
              </Select>

              <Button
                className="!justify-start mb-6"
                variant="no-style"
                onClick={() => setMessageIsOpen(!messageIsOpen)}
              >
                <span className="text-xs underline !font-grotesk">Add an optional message?</span>
              </Button>
              <AnimateHeight height={messageIsOpen ? 'auto' : 0}>
                <TextField
                  ariaLabel="How can we help? Write your message"
                  name="name"
                  type="textarea"
                  id="QuickLinks-message"
                  inputClassName="h-48"
                  placeholder="Write your message here"
                  value={formData.message}
                  showError={!!formErrors.message}
                  onBlur={() => setFormErrors(state => ({ ...state, message: '' }))}
                  onChange={value => setFormData({ ...formData, message: value as string })}
                />
              </AnimateHeight>
            </div>
            <div className="ContactForm__buttons flex flex-col justify-start items-start lg:flex-row lg:justify-between lg:items-center mt-6 mb-2 w-full">
              <div className="pr-1">
                <Checkbox
                  reverse={true}
                  variant="primary"
                  name="subscribe"
                  value={optIn}
                  label="I give Escapod Sales & Rentals permission to reach out to me regarding my visit today."
                  onChange={value => setOptIn(value)}
                />
              </div>
              <div className="ContactForm__primary-button relative flex lg:flex-row-reverse items-center mt-4 lg:mt-0">
                <Button
                  size="lg"
                  color="sand"
                  className="inline px-12"
                  disabled={!optIn || formIsSending}
                  onClick={submitForm}
                >
                  Submit Info
                </Button>
                <div
                  className={cx('ContactForm__loader-gif ml-2 lg:ml-0 lg:mr-2 transition-opacity', {
                    'opacity-0': !formIsSending
                  })}
                >
                  <div className="h-5 w-5 relative">
                    <Image src={loader} layout="fill" alt="Submit button loading GIF" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </BlockWrapper>
  );
};

export default QuickLinkForm;
