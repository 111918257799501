import React, { FC } from 'react';
import { format, parse } from 'date-fns';

import { Block, Image as TImage, Author } from 'types';
import { BlockWrapper, Image } from 'components';

export type TPostHero = Block<
  'postHero',
  {
    title: string;
    image: TImage;
    author: Author;
    date: string;
  }
>;

export const PostHero: FC<TPostHero> = ({ title, image, author, date }) => {
  const imageWidth = image.metadata?.dimensions.width || 1;
  const imageHeight = image.metadata?.dimensions.height || 1;

  const parsedDate = parse(date, 'yyyy-MM-dd', new Date());
  const formattedDate = format(parsedDate, 'MMMM dd, yyyy');

  return (
    <BlockWrapper layout="no-style" className="PostHero relative lg:container pb-24 md:pb-0">
      <div className="PostHero__image w-5/6">
        <div
          className="relative w-full"
          style={{
            paddingBottom: `${(imageHeight / imageWidth) * 100}%`
          }}
        >
          <Image src={image.src} layout="fill" alt={image.alt} />
        </div>
      </div>
      <div className="PostHero__header w-4/5 md:w-1/2 bg-stone-200 absolute bottom-0 md:bottom-auto md:top-0 right-0 p-6 md:p-8 md:mt-24">
        <h1 className="font-grotesk-headline-news text-xl md:text-3xl">{title}</h1>
        <div className="flex justify-start flex-wrap mt-6 md:mt-12">
          <span className="text-xs md:text-sm mt-4 md:mt-0 mr-6 md:mr-10">By {author.name}</span>
          <span className="text-xs md:text-sm mt-4 md:mt-0">{formattedDate}</span>
        </div>
      </div>
    </BlockWrapper>
  );
};

export default PostHero;
