import axios from 'axios';
import { CreditApplicant, Result } from 'contexts/FinancingContext';

const BASE_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://secure.escapod.us/api'
    : 'http://localhost:3001/api';
const API_VER = 'v1';

type Payload = {
  primary: CreditApplicant;
  secondary?: CreditApplicant;
  downPayment: number;
  monthlyTarget: number;
};

const Secure = {
  preApproveApplicants: async (payload: Payload): Promise<Result> =>
    (await axios.post(`${BASE_URL}/${API_VER}/credit/check`, payload)).data
};

export default Secure;
