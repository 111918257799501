import { format } from 'date-fns';

export const DaysInOrder = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'
];
export type StartAndEndTime = { start: string; end: string };
export type StartAndEndTimes = {
  monday: StartAndEndTime;
  tuesday: StartAndEndTime;
  wednesday: StartAndEndTime;
  thursday: StartAndEndTime;
  friday: StartAndEndTime;
  saturday: StartAndEndTime;
  sunday: StartAndEndTime;
};

const formatOpenHours = (hours: StartAndEndTimes): { [key: string]: string } => {
  const formattedStrings: { [key: string]: string } = Object.keys(hours).reduce<{
    [key: string]: string;
  }>((strings, day) => {
    const { start, end } = hours[day as keyof StartAndEndTimes];

    const startStr = format(new Date(`January 1, 2023, ${start}`), 'h:mm a');
    const endStr = format(new Date(`January 1, 2023, ${end}`), 'h:mm a');
    const str = `${startStr} – ${endStr}`;

    return !start || !end ? strings : { ...strings, [day]: str };
  }, {});

  const stringsByDayRanges = DaysInOrder.reduce<{ days: string[]; label: string }[]>(
    (ranges, day, i) => {
      const str = formattedStrings[day];
      const dayBefore = i === 0 ? '' : formattedStrings[DaysInOrder[i - 1]];

      if (str === dayBefore && !!dayBefore) {
        const last = ranges[ranges.length - 1];
        last.days = [...last.days, day];
      }

      return str === dayBefore || !str ? ranges : [...ranges, { days: [day], label: str }];
    },
    []
  );

  return stringsByDayRanges.reduce((ranges, range) => {
    const dayRange =
      range.days.length > 1
        ? `${range.days[0]} – ${range.days[range.days.length - 1]}`
        : range.days[0];
    return { ...ranges, [dayRange]: range.label };
  }, {});
};

export default formatOpenHours;
