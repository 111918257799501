import React, { FC, useState, useCallback } from 'react';
import cx from 'classnames';

import { Block, Image as TImage } from 'types';
import { BlockWrapper, Button, Image } from 'components';

import iconHotspot from '../../public/images/icon-pin.png';

export type TTwoImageGallery = Block<
  'twoImageGallery',
  {
    layout: 'largeImageOnLeft' | 'largeImageOnRight';
    largeImage: TImage;
    largeHotspot?: {
      text: string;
      coordinates: {
        x: number;
        y: number;
      };
    };
    smallImage: TImage;
    smallHotspot?: {
      text: string;
      coordinates: {
        x: number;
        y: number;
      };
    };
  }
>;

export const TwoImageGallery: FC<TTwoImageGallery> = ({
  meta,
  layout,
  largeImage,
  smallImage,
  largeHotspot,
  smallHotspot
}) => {
  const [largeHotspotActive, setLargeHotspotActive] = useState<boolean>(false);
  const [smallHotspotActive, setSmallHotspotActive] = useState<boolean>(false);

  const smallImageWidth = smallImage.metadata?.dimensions.width || 1;
  const smallImageHeight = smallImage.metadata?.dimensions.height || 1;

  const largeImageWidth = largeImage.metadata?.dimensions.width || 1;
  const largeImageHeight = largeImage.metadata?.dimensions.height || 1;

  const toggleLargeActive = useCallback(() => {
    largeHotspotActive === false ? setLargeHotspotActive(true) : setLargeHotspotActive(false);
  }, [largeHotspotActive]);

  const toggleSmallActive = useCallback(() => {
    smallHotspotActive === false ? setSmallHotspotActive(true) : setSmallHotspotActive(false);
  }, [smallHotspotActive]);

  const largeHotspotDivisor = !!largeHotspot && largeHotspot.text.length < 35 ? 2 : 1;
  const smallHotspotDivisor = !!smallHotspot && smallHotspot.text.length < 35 ? 1.5 : 1;

  return (
    <BlockWrapper
      className={cx('TwoImageGallery !mb-8 md:container', {
        '!mb-16 sm:!mb-24': meta.isLastOfConsecutiveType
      })}
      layout="full"
    >
      <div
        className={cx('TwoImageGallery__inner-wrapper flex flex-wrap items-start', {
          'md:flex-row-reverse': layout === 'largeImageOnRight'
        })}
      >
        <div
          className={cx('TwoImageGallery__large-image flex w-full md:w-3/5 overflow-hidden', {
            'pr-6 md:pr-0': layout === 'largeImageOnLeft',
            'pl-6 md:pl-0': layout === 'largeImageOnRight'
          })}
        >
          <div
            className="relative w-full"
            style={{
              paddingBottom: `${(largeImageHeight / largeImageWidth) * 100}%`
            }}
          >
            <Image src={largeImage.src} layout="fill" objectFit="contain" alt={largeImage.alt} />
            {!!largeHotspot?.text && (
              <div
                className="TwoImageGallery__hot-spot absolute drop-shadow-lg"
                style={{
                  top: `${largeHotspot.coordinates.y}%`,
                  left: `${largeHotspot.coordinates.x}%`,
                  width:
                    largeHotspot.coordinates.x < 50
                      ? `calc((${
                          100 - largeHotspot.coordinates.x
                        }% / ${largeHotspotDivisor}) - 1rem)`
                      : `calc((${largeHotspot.coordinates.x}% / ${largeHotspotDivisor}) - 1rem)`
                }}
              >
                <Button variant="no-style" onClick={toggleLargeActive}>
                  <div
                    className={cx(
                      'TwoImageGallery__hot-spot-icon absolute -left-6 z-10 h-12 w-12 transition-all',
                      {
                        'rotate-45': largeHotspotActive,
                        '-top-full': largeHotspot.coordinates.y < 50,
                        '-bottom-full': largeHotspot.coordinates.y >= 50
                      }
                    )}
                  >
                    <Image
                      hideLoadingPlaceholder={true}
                      src={iconHotspot}
                      alt="Collapse and expand icon"
                    />
                  </div>
                </Button>
                <div
                  className={cx(
                    'TwoImageGallery__hot-spot-text absolute w-full bg-white px-[14px] py-2 opacity-0 transition-opacity',
                    {
                      'top-0': largeHotspot.coordinates.y < 50,
                      'bottom-0': largeHotspot.coordinates.y >= 50,
                      'left-0': largeHotspot.coordinates.x < 50,
                      'right-[100%]': largeHotspot.coordinates.x >= 50,
                      'opacity-100': largeHotspotActive
                    }
                  )}
                >
                  <span className="text-left font-grotesk text-xs">{largeHotspot?.text}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          className={cx(
            'TwoImageGallery__small-image mt-6 flex w-full md:mt-0 md:w-2/5 overflow-hidden',
            {
              'pl-6 md:pl-8': layout === 'largeImageOnLeft',
              'justify-end pr-6 md:pr-8': layout === 'largeImageOnRight'
            }
          )}
        >
          <div
            className={cx('relative w-full', {
              'md:w-1/2': smallImageWidth < smallImageHeight
            })}
            style={{
              paddingBottom: `${
                ((smallImageHeight / smallImageWidth) * 100) /
                (smallImageHeight > smallImageWidth ? 2 : 1)
              }%`
            }}
          >
            <Image
              src={smallImage.src}
              layout="fill"
              objectFit="contain"
              objectPosition="top"
              alt={smallImage.alt}
            />
            {!!smallHotspot?.text && (
              <div
                className="TwoImageGallery__hot-spot absolute drop-shadow-lg"
                style={{
                  top: `${smallHotspot.coordinates.y}%`,
                  left: `${smallHotspot.coordinates.x}%`,
                  width:
                    smallHotspot.coordinates.x < 50
                      ? `calc((${
                          100 - smallHotspot.coordinates.x
                        }% / ${smallHotspotDivisor}) - 1rem)`
                      : `calc((${smallHotspot.coordinates.x}% / ${smallHotspotDivisor}) - 1rem)`
                }}
              >
                <Button variant="no-style" onClick={toggleSmallActive}>
                  <div
                    className={cx(
                      'TwoImageGallery__hot-spot-icon absolute -left-6 z-10 h-12 w-12 transition-all',
                      {
                        'rotate-45': smallHotspotActive,
                        '-top-full': smallHotspot.coordinates.y < 50,
                        '-bottom-full': smallHotspot.coordinates.y >= 50
                      }
                    )}
                  >
                    <Image
                      hideLoadingPlaceholder={true}
                      src={iconHotspot}
                      alt="Collapse and expand icon"
                    />
                  </div>
                </Button>
                <div
                  className={cx(
                    'TwoImageGallery__hot-spot-text absolute w-full bg-white px-[14px] py-2 opacity-0 transition-opacity',
                    {
                      'top-0': smallHotspot.coordinates.y < 50,
                      'bottom-0': smallHotspot.coordinates.y >= 50,
                      'left-0': smallHotspot.coordinates.x < 50,
                      'right-[100%]': smallHotspot.coordinates.x >= 50,
                      'opacity-100': smallHotspotActive
                    }
                  )}
                >
                  <span className="font-grotesk text-xs">{smallHotspot?.text}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </BlockWrapper>
  );
};

export default TwoImageGallery;
