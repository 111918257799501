import React, { FC } from 'react';
import { useCartState } from 'contexts/CartContext';
import { Block } from 'types';
import { BlockWrapper } from 'components';

export type TCartBlock = Block<'cart'>;
export const CartBlock: FC = () => {
  const cart = useCartState();

  if (!cart) return null;

  return (
    <BlockWrapper className="Cart">
      <h1>Cart</h1>
      {cart.lineItems.length && (
        <div className="Cart__line-items">
          {cart.lineItems.map(item => (
            <div key={item.title}>
              <div>
                {item.title} - {item.quantity}
              </div>
              <div>
                <button onClick={() => console.log(item.id)}>Remove</button>
              </div>
            </div>
          ))}
        </div>
      )}
      {cart.webUrl && (
        <div>
          <a href={cart.webUrl} target="_blank" rel="noreferrer">
            Checkout
          </a>
        </div>
      )}
    </BlockWrapper>
  );
};

export default CartBlock;
