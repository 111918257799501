import React, { FC } from 'react';
import cx from 'classnames';

type Props = {
  className?: string;
  layout?: 'full' | 'container' | 'prose' | 'no-style';
  children: React.ReactNode;
};

export const BlockWrapper: FC<Props> = ({ layout = 'container', className = '', children }) => {
  return (
    <div
      className={cx('Block mb-16 sm:mb-24', className, {
        'container mx-auto': layout === 'container',
        'w-full': layout === 'full',
        'max-w-prose mx-auto': layout === 'prose'
      })}
    >
      {children}
    </div>
  );
};

export default BlockWrapper;
