import React, { FC, useEffect } from 'react';

import { Block, PortableText as TPortableText } from 'types';
import { BlockWrapper } from 'components';
import { useFinancingContext } from 'contexts/FinancingContext';

import Consent from './Consent';
import BasicInfo from './BasicInfo';
import IdPrimary from './IdPrimary';
import IdSecondary from './IdSecondary';
import EmploymentPrimary from './EmploymentPrimary';
import EmploymentSecondary from './EmploymentSecondary';
import Review from './Review';
import Confirmation from './Confirmation';
import { animateScroll } from 'react-scroll';

export type TFinancingApplication = Block<
  'financingApplication',
  {
    title?: string;
    agreement: {
      title: string;
      agreement: TPortableText;
    };
  }
>;

// TO-DO: Add the ability to "crop and hotspot" from Sanity
export const FinancingApplication: FC<TFinancingApplication> = props => {
  const context = useFinancingContext();
  useEffect(() => {
    animateScroll.scrollToTop();
  }, [context.stage]);

  console.log('FINANCING APP', context);

  return (
    <BlockWrapper className="FinancingApplication relative">
      {context.stage === 'CONSENT' && <Consent {...props} />}
      {context.stage === 'BASIC' && <BasicInfo />}
      {context.stage === 'ID_PRIMARY' && <IdPrimary />}
      {context.stage === 'ID_SECONDARY' && <IdSecondary />}
      {context.stage === 'EMPLOYMENT_PRIMARY' && <EmploymentPrimary />}
      {context.stage === 'EMPLOYMENT_SECONDARY' && <EmploymentSecondary />}
      {context.stage === 'REVIEW' && <Review />}
      {context.stage === 'CONFIRMATION' && <Confirmation />}
    </BlockWrapper>
  );
};

export default FinancingApplication;
