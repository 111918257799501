import React, { FC } from 'react';

import { Block, Image as TImage } from 'types';
import { BlockWrapper } from 'components';

export type TImageCarousel = Block<
  'imageCarousel',
  {
    title?: string;
    images: {
      image: TImage;
    }[];
  }
>;

export const ImageCarousel: FC<TImageCarousel> = props => {
  return (
    <BlockWrapper className="ImageCarousel">
      <div className="ImageCarousel-title-wrapper">
        <span className="ImageCarousel-title">{props.title}</span>
      </div>
      {props.images.map(item => (
        <div
          key={`ImageCarousel__image--${item.image.src}`}
          className="ImageCarousel-image-wrapper"
        >
          {/* <Image src={item.image.src} layout="fill" /> */}
        </div>
      ))}
    </BlockWrapper>
  );
};

export default ImageCarousel;
