import React, { FC, MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import Link from 'next/link';
import Slider from 'react-slick';

import { Block, Image as TImage } from 'types';
import { BlockWrapper, Image, Button } from 'components';
import { useNavContext } from 'contexts/NavContext';
import ifLogo from '../../public/images/escapod-if-logo.png';
import redDotLogo from '../../public/images/escapod-red-dot-logo.png';

export type TImpactHero = Block<
  'impactHero',
  {
    title: string;
    slides: {
      title1: string;
      title2: string;
      text: string;
      theme: 'white' | 'black';
      mobileImage: TImage;
      desktopImage: TImage;
      buttonPrimary: { url: string; label: string };
      buttonSecondary: { url: string; label: string };
    }[];
  }
>;
const SLIDER_INTERVAL = 8000;
const SliderDot: FC<{
  className?: string;
  isActive: boolean;
  theme: 'white' | 'black';
  onClick?: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
}> = ({ isActive, className = '', theme, onClick }) => {
  const [time, setTime] = useState(0);
  const percentage = useMemo(() => (time / SLIDER_INTERVAL) * 100, [time]);
  const color = `bg-${theme}`;

  useEffect(() => setTime(0), [isActive]);
  useEffect(() => {
    const timer = setInterval(() => {
      if (!isActive && time === 0) return;
      if (!isActive && time !== 0) return setTime(0);
      return setTime(time => time + SLIDER_INTERVAL / 800);
    }, SLIDER_INTERVAL / 800);

    return () => clearInterval(timer);
  }, [time, isActive]);

  return (
    <div
      className={cx(
        'SliderDot relative overflow-hidden transition-all h-[6px] rounded-full',
        className,
        {
          'w-[6px]': !isActive,
          'w-8': isActive
        }
      )}
    >
      <Button
        onClick={onClick}
        variant="no-style"
        className={cx('absolute top-0 left-0 h-[6px] opacity-60 transition-all', color, {
          'w-[6px] hover:opacity-100': !isActive,
          'w-8': isActive
        })}
      >
        {' '}
      </Button>
      <div
        style={{ width: `${percentage}%` }}
        className={cx('transition-opacity z-10 absolute top-0 left-0 h-[6px]', color, {
          'opacity-0 pointer-events-none': !isActive
        })}
      />
    </div>
  );
};

export const ImpactHero: FC<TImpactHero> = ({ title, slides, meta }) => {
  const navContext = useNavContext();
  const sliderRef = useRef<null | Slider>(null);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [bumperIsActive, setBumperIsActive] = useState(false);

  const _setSlide = useCallback(
    (index: number) => {
      sliderRef.current?.slickGoTo(index);
    },
    [sliderRef]
  );

  useEffect(() => {
    if (!bumperIsActive) setTimeout(() => setBumperIsActive(true), 2000);
  }, [bumperIsActive]);

  useEffect(() => {
    const theme = slides[carouselIndex].theme;
    if (!!theme && theme !== navContext.theme) navContext.setContext({ ...navContext, theme });
  }, [navContext, carouselIndex, slides]);

  return (
    <BlockWrapper layout="full" className={cx('ImpactHero')}>
      {!!title && meta.isFirstBlockWithTitle && (
        <h1 className="ImpactHero__title hidden">{title}</h1>
      )}
      {!!title && !meta.isFirstBlockWithTitle && (
        <h2 className="ImpactHero__title hidden">{title}</h2>
      )}
      <Slider
        ref={sliderRef}
        arrows={false}
        dots={false}
        infinite={true}
        speed={1000}
        autoplay={true}
        autoplaySpeed={SLIDER_INTERVAL + 1000}
        pauseOnHover={false}
        fade={true}
        beforeChange={(_, next) => setCarouselIndex(next)}
      >
        {slides.map((slide, index) => {
          const textColor = slide.theme === 'black' ? 'text-charcoal' : 'text-white';
          const isActive = index === carouselIndex;

          return (
            <div
              className={cx('ImpactHero__slide relative z-10', {
                'pointer-events-none': !isActive
              })}
              key={slide.text}
            >
              <div className="ImpactHero__slide__inner relative h-[620px] md:h-[800px]">
                <div className="absolute top-0 left-0 right-0 bottom-0 -z-10 lg:hidden">
                  <Image
                    src={slide.mobileImage.src}
                    layout="fill"
                    objectFit="cover"
                    alt={slide.mobileImage.alt}
                    priority={true}
                    sizes="99vw"
                  />
                </div>
                <div className="absolute top-0 left-0 right-0 bottom-0 -z-10 hidden lg:block">
                  <Image
                    src={slide.desktopImage.src}
                    layout="fill"
                    objectFit="cover"
                    className=""
                    alt={slide.desktopImage.alt}
                    priority={true}
                    sizes="99vw"
                  />
                </div>
                <div className="ImpactHero__slide__content container mx-auto flex flex-col lg:flex-row items-end justify-between h-full relative pb-8 lg:pb-16">
                  <div className="ImpactHero__slide__info text-center w-full lg:w-fit lg:text-left pt-32 lg:pt-0">
                    <div className="ImpactHero__slide__dots flex justify-center lg:justify-start mb-4">
                      {slides.map((_, i) => (
                        <SliderDot
                          key={`Dot--${slides[i].text}`}
                          onClick={() => _setSlide(i)}
                          theme={slide.theme}
                          className="mr-2"
                          isActive={i === carouselIndex}
                        />
                      ))}
                    </div>
                    <h2
                      className={cx(
                        'font-grotesk-headline text-xl md:text-3xl lg:text-4xl tracking-wide flex flex-col uppercase mb-5',
                        textColor
                      )}
                    >
                      <span className="opacity-60">{slide.title1}</span>
                      <span>{slide.title2}</span>
                    </h2>
                    <p
                      className={cx(
                        'max-w-[520px] mx-auto lg:mx-0 text-xs md:text-sm mb-8 !leading-relaxed',
                        textColor
                      )}
                    >
                      {slide.text}
                    </p>
                    <div className="flex flex-col items-center lg:flex-row">
                      {slide.buttonPrimary?.url && slide.buttonPrimary?.label && (
                        <>
                          <Button
                            href={slide.buttonPrimary?.url}
                            variant="stroke"
                            color={slide.theme}
                            className="hidden lg:flex"
                          >
                            {slide.buttonPrimary?.label}
                          </Button>
                          <Button
                            href={slide.buttonPrimary?.url}
                            variant="stroke"
                            color={slide.theme}
                            size="sm"
                            className="flex w-fit lg:hidden"
                          >
                            {slide.buttonPrimary?.label}
                          </Button>
                        </>
                      )}
                      {slide.buttonSecondary?.url && slide.buttonSecondary?.label && (
                        <>
                          <Button
                            href={slide.buttonSecondary?.url}
                            variant="stroke"
                            color={slide.theme}
                            className="hidden lg:flex ml-3"
                          >
                            {slide.buttonSecondary?.label}
                          </Button>
                          <Button
                            href={slide.buttonSecondary?.url}
                            variant="stroke"
                            color={slide.theme}
                            size="sm"
                            className="flex w-fit lg:hidden mt-3"
                          >
                            {slide.buttonSecondary?.label}
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                  <div
                    className={cx(
                      'ImpactHero__slide__bumper flex justify-center w-full lg:w-fit lg:justify-end transition-all duration-[.5s]',
                      {
                        'translate-y-full opacity-0': !bumperIsActive
                      }
                    )}
                  >
                    <Link href="/blog/topo2-design-awards">
                      <a className="flex justify-end items-center relative">
                        <span
                          className={cx(
                            'font-grotesk-headline-news text-xs md:text-sm tracking-widest uppercase mr-4 animate-bounce',
                            textColor
                          )}
                        >
                          Award Winning →
                        </span>
                        <div className="mr-4">
                          <Image
                            hideLoadingPlaceholder={true}
                            width="110"
                            height="57"
                            src={ifLogo}
                            alt="iF Design Awards 2023 Logo for Escapod Trailers"
                          />
                        </div>
                        <div className="mr-0">
                          <Image
                            hideLoadingPlaceholder={true}
                            width="223"
                            height="57"
                            src={redDotLogo}
                            alt="Red Dot Design Awards 2023 Logo for Escapod Trailers"
                          />
                        </div>
                      </a>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </BlockWrapper>
  );
};

export default ImpactHero;
