import React, { RefObject } from 'react';
import cx from 'classnames';

type Props = {
  id?: string;
  name: string;
  value: boolean;
  variant?: 'primary' | 'secondary';
  defaultChecked?: boolean;
  required?: boolean;
  className?: string;
  disabled?: boolean;
  label: string;
  reverse?: boolean;
  elemRef?: RefObject<HTMLInputElement> | ((ref: HTMLInputElement) => void);
  onBlur?: (e: boolean) => void;
  onFocus?: (e: boolean) => void;
  onChange?: (e: boolean) => void;
};

const Checkbox: React.FC<Props> = ({
  id,
  name,
  value,
  defaultChecked = false,
  required = false,
  className = '',
  elemRef,
  label,
  reverse = false,
  variant = 'secondary',
  disabled = false,
  onBlur = () => {
    return;
  },
  onFocus = () => {
    return;
  },
  onChange = () => {
    return;
  }
}) => {
  const _id = id || name;

  return (
    <div
      className={cx('Checkbox flex flex-nowrap justify-end items-center cursor', className, {
        'flex-row-reverse': reverse
      })}
    >
      <div className="Checkbox__label mt-[1px]">
        <span
          className={cx('block ml-3 rounded-sm tracking-wide border-none leading-relaxed', {
            'font-grotesk-news text-xs text-stone-700': variant === 'primary',
            'text-stone-400 text-xs': variant === 'secondary',
            'cursor-pointer': !disabled,
            'pointer-events-none opacity-80': disabled,
            'mr-2': !reverse
          })}
          onClick={() => onChange(!value)}
        >
          <span>{label}</span>
          {required && <span className="text-fire ml-[2px]">*</span>}
        </span>
      </div>
      <div className="Checkbox__input">
        <input
          className={cx('border-1 !ring-transparent !rounded-sm', {
            'border-stone-300 checked:!bg-fire p-[10px]': variant === 'primary',
            'border-stone-300 checked:!bg-stone-400 p-2': variant === 'secondary',
            'cursor-pointer': !disabled,
            'pointer-events-none opacity-80 checked:!bg-stone-400': disabled
          })}
          type="checkbox"
          id={_id}
          name={name}
          disabled={disabled}
          value={value?.toString() || 'false'}
          defaultChecked={defaultChecked}
          required={required}
          ref={elemRef as RefObject<HTMLInputElement>}
          onBlur={e => onBlur(e.target.checked)}
          onFocus={e => onFocus(e.target.checked)}
          onChange={e => onChange(e.target.checked)}
          checked={value}
        />
      </div>
    </div>
  );
};

export default Checkbox;
