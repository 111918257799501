import React, { FC, useState } from 'react';

import { Block, PortableText as TPortableText } from 'types';
import { BlockWrapper, Button, PortableText } from 'components';

export type TFinancingForm = Block<
  'financingForm',
  {
    title?: string;
    agreement: {
      title: string;
      agreement: TPortableText;
    };
  }
>;

// TO-DO: Add the ability to "crop and hotspot" from Sanity
export const FinancingForm: FC<TFinancingForm> = ({ title, agreement, meta }) => {
  const [consented, setConsented] = useState(false);

  return (
    <BlockWrapper className="FinancingForm relative mt-24">
      {consented ? (
        <div className="FinancingForm__form">
          <h2 className="mb-12 w-full text-center font-grotesk-headline-news text-2xl md:text-3xl">
            Financing Coming Soon
          </h2>
          <div className="max-w-prose lg:px-20 mx-auto text-center">
            <p>
              Our sales team is working hard to establish financing options for our customers. To
              learn more about this, contact us at{' '}
              <a className="underline" href="mailto:sales@escapod.us">
                sales@escapod.us
              </a>{' '}
              or call us at <a className="underline" href="tel:4356250586">{`(435)-625-0586`}</a>.
            </p>
          </div>
        </div>
      ) : (
        <div className="FinancingForm__consent-agreement flex flex-col items-center justify-center w-full">
          {!!title && meta.isFirstBlockWithTitle && (
            <h1 className="FinancingForm__title mb-12 w-full text-center font-grotesk-headline-news text-2xl md:text-3xl">
              {agreement.title}
            </h1>
          )}
          {!!title && !meta.isFirstBlockWithTitle && (
            <h2 className="FinancingForm__title mb-12 w-full text-center font-grotesk-headline-news text-2xl md:text-3xl">
              {agreement.title}
            </h2>
          )}
          <div className="max-w-prose lg:px-20 mx-auto">
            <PortableText content={agreement.agreement} />
          </div>
          <div className="flex justify-center mt-16">
            <Button className="uppercase" onClick={() => setConsented(true)}>
              I Agree
            </Button>
          </div>
        </div>
      )}
    </BlockWrapper>
  );
};

export default FinancingForm;
