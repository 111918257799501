import React, { FC } from 'react';
import cx from 'classnames';
import { Block } from 'types';
import { BlockWrapper, Button } from 'components';

export type TLinkList = Block<
  'linkList',
  {
    title?: string;
    items: {
      _key: string;
      title: string;
      primaryButton?: { url: string; label: string };
      link?: { url: string; label: string };
    }[];
  }
>;

export const LinkList: FC<TLinkList> = ({ title, items, meta }) => {
  return (
    <BlockWrapper layout="full" className="md:container">
      {!!title && meta.isFirstBlockWithTitle && (
        <div className="LinkList__title mb-8 px-4 md:px-0">
          <h1 className="text-2xl md:text-3xl font-grotesk-headline-news">{title}</h1>
        </div>
      )}
      {!!title && !meta.isFirstBlockWithTitle && (
        <div className="LinkList__title mb-8 px-4 md:px-0">
          <h2 className="text-2xl md:text-3xl font-grotesk-headline-news">{title}</h2>
        </div>
      )}
      <div className="LinkList__item flex flex-wrap w-full border-t-stone-200 border-t-[1px]">
        {items.map(item => {
          return (
            <div
              className="LinkList__item-inner-wrapper w-full mt-6 pb-8 md:pb-6 md:flex md:flex-nowrap md:items-center border-b-[1px] border-b-stone-200"
              key={`LinkList__item__${item._key}`}
            >
              <div className="LinkList__item__title w-full mb-4 px-4 md:px-0 md:mb-0 lg:w-[60%] xl:w-[70%]">
                <span className="text-sm lg:text-base font-grotesk-headline">{item.title}</span>
              </div>
              <div
                className={cx(
                  'LinkList__item__link-primaryButton-wrapper px-4 md:px-0 w-full lg:w-[40%] xl:w-[30%] flex items-center justify-end flex-row-reverse md:flex-row',
                  {
                    'md:!justify-end': !item.link
                  }
                )}
              >
                {!!item.link && (
                  <div className="LinkList__item__link-wrapper mr-6">
                    <Button className="text-xs" variant="link" href={item.link.url}>
                      {item.link.label}
                    </Button>
                  </div>
                )}
                {item.primaryButton && (
                  <div
                    className={cx('LinkList__item__primaryButton-wrapper pr-4 md:pr-0', {
                      'pr-0': !item.link
                    })}
                  >
                    <div className="LinkList__item__primaryButton transition-all rounded-sm h-[45px] bg-charcoal hover:opacity-90 flex items-center justify-center">
                      <Button variant="fill" href={item.primaryButton.url}>
                        {item.primaryButton.label}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </BlockWrapper>
  );
};

export default LinkList;
