import cx from 'classnames';
import { FC, useEffect } from 'react';

import { Button, Image, FormWrapper, TextField } from 'components';
import iconClose from 'public/images/v2-icon-close.svg';
import logo from 'public/images/logo-escapod-emblem-big.png';

type Props = {
  email: string;
  isActive: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: () => void;
  onChange: (email: string) => void;
  error?: { form?: string; email?: string };
};

const NewsletterPopup: FC<Props> = ({
  isActive,
  isLoading,
  onClose,
  onSubmit,
  onChange,
  email,
  error
}) => {
  useEffect(() => {
    console.warn(`Experiment Variant: DrawerB`);
  }, []);

  const imageSrc =
    'https://cdn.sanity.io/images/l3ps89do/production/87710765b3e1480ab37460ad478eda6808a3e1f9-2048x1365.jpg';
  const imageWidth = 2048;
  const imageHeight = 1365;

  return (
    <>
      <div
        className={cx(
          'NewsletterPopup__overlay bg-stone-900 bg-opacity-70 transition-all fixed bottom-0 left-0 right-0 top-0 z-50',
          { 'opacity-0 pointer-events-none': !isActive }
        )}
      />
      <div
        className={cx(
          'NewsletterPopup NewsletterPopup--DrawerB fixed bottom-0 left-0 right-0 w-full z-50 transition-transform duration-[500ms] overflow-hidden lg:flex lg:items-end',
          {
            'translate-y-full': !isActive
          }
        )}
      >
        <div
          className="relative z-10"
          style={{
            paddingBottom: `${(imageHeight / imageWidth) * 100}%`
          }}
        >
          <Image
            src={imageSrc}
            layout="fill"
            alt="A green TOPO2 fiberglass camping trailer is parked on a rocky outcropping with a backdrop of a red rock mesa in the distance."
          />
        </div>
        <div className="NewsletterPopup__inner-wrapper relative w-full bg-sand z-10 flex items-center">
          <div className="NewsletterPopup__eyebrow absolute top-0 left-0 -translate-y-full bg-stone-900 p-2 md:p-4 lg:left-[450px] xl:left-[600px]">
            <span className="uppercase font-grotesk-sub-headline tracking-widest text-[.5em] md:text-sm">
              <span className="text-stone-400 mr-2">No Spam Here.</span>
              <span className="text-white">Just rugged, outdoorsy goodness.</span>
            </span>
          </div>
          <div className="flex-shrink-0 hidden relative lg:block lg:h-[300px] lg:w-[450px] xl:h-[400px] xl:w-[600px]">
            <Image
              src={imageSrc}
              layout="fill"
              alt="A green TOPO2 fiberglass camping trailer is parked on a rocky outcropping with a backdrop of a red rock mesa in the distance."
            />
          </div>
          <div className="flex flex-wrap justify-start pt-6 px-6 pb-12 lg:p-8 xl:p-16 w-full relative">
            <div className="NewsletterPopup__text w-full pb-4 md:pb-0">
              <div className="NewsletterPopup__title-close-button-wrapper mb-4 flex items-center justify-between w-full lg:items-start">
                <div className="NewsletterPopup__title flex items-center">
                  <div className="mr-4 relative flex-shrink-0 md:hidden">
                    <Image src={logo} width="37" height="27" alt="Newsletter Escapod logo" />
                  </div>
                  <div className="mr-4 relative flex-shrink-0 hidden md:block">
                    <Image src={logo} width="63" height="45" alt="Newsletter Escapod logo" />
                  </div>
                  <span className="md:whitespace-nowrap font-grotesk-headline-news text-xl md:text-3xl lg:text-[2.25em] xl:text-[2.75em] tracking-wide text-charcoal uppercase">
                    Stay in the Know
                  </span>
                </div>
                <div className="NewsletterPopup__close-button mt-1">
                  <Button variant="link" onClick={onClose}>
                    <div className="relative h-[18px] w-[18px]">
                      <Image src={iconClose} layout="fill" alt="Newsletter popup close icon" />
                    </div>
                  </Button>
                </div>
              </div>
              <div className="NewsletterPopup__message-form-wrapper flex w-full items-end flex-wrap md:flex-nowrap lg:flex-col lg:items-start">
                <div className="NewsletterPopup__message text-left mb-4 md:w-1/2 md:pr-10 md:mb-0 lg:mb-12 xl:mb-16 lg:w-full lg:max-w-[800px]">
                  <span className="text-xs md:text-sm lg:text-lg">
                    We’re always dreaming up new ways to get you outdoors. Sign up and we’ll share
                    with you what we’re working on before anyone else.
                  </span>
                </div>
                <FormWrapper
                  className="NewsletterPopup__form-wrapper w-full md:w-1/2 flex-col lg:w-full lg:flex-row flex flex-nowrap justify-between lg:justify-start lg:max-w-[800px]"
                  id="NewsletterPopup-Subscribe"
                  name="NewsletterPopup-Subscribe"
                >
                  <div className="NewsletterPopup__email-input w-full lg:w-2/3 mr-4 mb-4 lg:mb-0 flex-grow">
                    <TextField
                      className="whitespace-nowrap w-full"
                      id="Newsletter-popup-form-email"
                      name="email"
                      placeholder="Email Address"
                      ariaLabel="Email Address"
                      value={email}
                      error={error?.email}
                      showError={!!error?.email}
                      onChange={value => onChange(value as string)}
                    />
                  </div>
                  <div className="NewsletterPopup__signup-button lg:w-48">
                    <Button
                      className="w-full"
                      type="button"
                      disabled={isLoading}
                      onClick={onSubmit}
                    >
                      {isLoading ? 'Sending' : 'Submit'}
                    </Button>
                  </div>
                </FormWrapper>
              </div>
              {error?.form && (
                <div className="NewsletterPopup__form-error absolute bottom-20 md:top-0 md:right-14">
                  <span className="text-center text-sm text-fire">{error.form}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsletterPopup;
