import { FC, useState, useCallback, useEffect } from 'react';
import { Button, Checkbox, Select, TextField } from 'components';

import {
  useFinancingContext,
  useFinancingMutate,
  EmploymentStatus as TEmploymentStatus
} from 'contexts/FinancingContext';
import { EmploymentStatus, EmploymentStatusesWithEmployer } from 'constants/Financing';
import phoneIsValid from 'utils/phoneIsValid';
import formatMoney from 'utils/formatMoney';

type Errors = { [key: string]: string };

const EmploymentPrimary: FC = () => {
  const { primary, hasSecondary } = useFinancingContext();
  const [hasAdditionalIncome, setHasAdditionalIncome] = useState(false);
  const [statusRequiresEmploymentInfo, setStatusRequiresEmploymentInfo] = useState(false);
  const [errors, setErrors] = useState<Errors>({});
  const mutate = useFinancingMutate();

  useEffect(() => {
    setStatusRequiresEmploymentInfo(
      EmploymentStatusesWithEmployer.includes(primary.employment.status)
    );
  }, [primary.employment.status]);

  const _continue = useCallback(() => {
    const _errors: Errors = {};
    if (!primary.employment.status) _errors.status = 'Please select an employment status.';

    if (EmploymentStatusesWithEmployer.includes(primary.employment.status)) {
      if (!primary.employment.employer) _errors.employer = 'Please enter your current employer.';
      if (!primary.employment.employerPhone || !phoneIsValid(primary.employment.employerPhone))
        _errors.employerPhone = 'Please enter a valid phone number.';
      if (!primary.employment.occupation) _errors.occupation = 'Please enter your occupation.';
      if (!primary.employment.salary) _errors.salary = 'Please enter your monthly salary.';
    }

    if (hasAdditionalIncome) {
      if (!primary.employment.additionalIncomeSource)
        _errors.additionalIncomeSource = 'Please describe your additional income source(s).';
      if (!primary.employment.additionalIncome)
        _errors.additionalIncome = 'Please state your additional monthly income.';
    }

    setErrors(_errors);
    if (!!Object.keys(_errors).length) return;

    return hasSecondary ? mutate?.setStage('ID_SECONDARY') : mutate?.setStage('REVIEW');
  }, [primary, hasSecondary, hasAdditionalIncome, mutate]);

  return (
    <div className="FinancingApplication__EmploymentPrimary flex flex-col items-center justify-center w-full max-w-prose mx-auto animate-fade-in">
      <div className="mb-8 flex flex-col w-full">
        <span className="mb-12 font-grotesk-headline tracking-wide">
          Primary Applicant&rsquo;s Employment{' '}
          <span className="text-sand ml-3">({primary.firstName})</span>
        </span>
        <div className="lg:grid lg:grid-cols-3 lg:gap-8">
          <div className="mb-8">
            <Select
              name="employment_status"
              ariaLabel="Employment Status"
              label="Employment Status"
              required={true}
              value={primary.employment.status}
              onChange={value =>
                mutate?.setEmployment('primary', { status: value as TEmploymentStatus })
              }
              error={errors?.status}
              showError={!!errors?.status}
            >
              {Object.entries(EmploymentStatus).map(([value, label]) => (
                <option key={`Employment__option--${value}`} value={value}>
                  {label}
                </option>
              ))}
            </Select>
          </div>
          <div className="mb-8">
            <TextField
              name="employer"
              ariaLabel="Current Employer"
              label="Current Employer"
              required={statusRequiresEmploymentInfo}
              value={primary.employment.employer}
              onChange={value => mutate?.setEmployment('primary', { employer: value as string })}
              error={errors?.employer}
              showError={!!errors?.employer}
            />
          </div>
          <div className="mb-8">
            <TextField
              name="employer_phone"
              ariaLabel="Current Employer Phone"
              label="Current Employer Phone"
              required={statusRequiresEmploymentInfo}
              value={primary.employment.employerPhone}
              onChange={value =>
                mutate?.setEmployment('primary', { employerPhone: value as string })
              }
              error={errors?.employerPhone}
              showError={!!errors?.employerPhone}
            />
          </div>
        </div>

        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="mb-8">
            <TextField
              name="occupation"
              ariaLabel="Current Occupation"
              label="Current Occupation"
              required={statusRequiresEmploymentInfo}
              value={primary.employment.occupation}
              onChange={value => mutate?.setEmployment('primary', { occupation: value as string })}
              error={errors?.occupation}
              showError={!!errors?.occupation}
            />
          </div>

          <div className="mb-8">
            <TextField
              name="salary"
              ariaLabel="Monthly Salary"
              label="Monthly Salary"
              required={statusRequiresEmploymentInfo}
              value={formatMoney(primary.employment.salary || 0)}
              onChange={value =>
                mutate?.setEmployment('primary', {
                  salary: parseInt((value as string).replace(/\D/g, ''))
                })
              }
              error={errors?.salary}
              showError={!!errors?.salary}
            />
          </div>
        </div>

        <div className="mb-8 w-full pb-8 border-b-1 border-stone-200">
          <Checkbox
            className="w-full"
            variant="primary"
            reverse={true}
            name="has_additional_income"
            label="Do you have additional monthly income to report?"
            value={hasAdditionalIncome}
            onChange={value => setHasAdditionalIncome(value)}
          />
        </div>

        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="mb-8">
            <TextField
              name="additionalIncomeSource"
              ariaLabel="Additional Income Source"
              label="Additional Income Source"
              value={primary.employment.additionalIncomeSource}
              onChange={value =>
                mutate?.setEmployment('primary', { additionalIncomeSource: value as string })
              }
              error={errors?.additionalIncomeSource}
              showError={!!errors?.additionalIncomeSource}
              disabled={!hasAdditionalIncome}
            />
          </div>

          <div className="mb-8">
            <TextField
              name="additionalIncome"
              ariaLabel="Additional Monthly Income Amount"
              label="Additional Monthly Income Amount"
              value={formatMoney(primary.employment.additionalIncome || 0)}
              onChange={value =>
                mutate?.setEmployment('primary', {
                  additionalIncome: parseInt((value as string).replace(/\D/g, ''))
                })
              }
              error={errors?.additionalIncome}
              showError={!!errors?.additionalIncome}
              disabled={!hasAdditionalIncome}
            />
          </div>
        </div>
      </div>
      <div className="w-full flex justify-between">
        <Button
          color="gray"
          variant="fill"
          size="lg"
          onClick={() => mutate?.setStage('ID_PRIMARY')}
        >
          <span className="mr-2">&larr;</span> Back
        </Button>
        <Button color="sand" variant="fill" size="lg" onClick={_continue}>
          Continue <span className="ml-2">&rarr;</span>
        </Button>
      </div>
    </div>
  );
};

export default EmploymentPrimary;
