import React, { FC, useMemo, useRef } from 'react';
import { IoNavigateCircleSharp } from 'react-icons/io5';
import Image from 'next/image';

import { Block, Image as TImage, PortableText as TPortableText } from 'types';
import { BlockWrapper, Button, PortableText } from 'components';
import formatPhoneNumber from 'utils/formatPhoneNumber';
import angle from 'utils/angle';
import useMousePosition from 'hooks/useMousePosition';

export type TContactInfo = Block<
  'contactInfo',
  {
    title: string;
    image?: TImage;
    description?: TPortableText;
    locations: {
      name: string;
      phone: string;
      extension: string;
      image: TImage;
      address: {
        address1: string;
        address2?: string;
        city: string;
        state: string;
        zip: string;
      };
      buttons: { _key: string; label: string; url: string }[];
      hours: TPortableText;
    }[];
  }
>;

export const ContactInfo: FC<TContactInfo> = ({ title, image, description, locations, meta }) => {
  const arrowRef = useRef<HTMLDivElement>(null);
  const { x, y } = useMousePosition();

  const rotate = useMemo(
    () =>
      angle(
        {
          x: arrowRef.current?.getBoundingClientRect().x || 0,
          y: arrowRef.current?.getBoundingClientRect().y || 0
        },
        { x: x || 0, y: y || 0 }
      ),
    [arrowRef, x, y]
  );
  const imageWidth = image?.metadata?.dimensions.width || 1;
  const imageHeight = image?.metadata?.dimensions.height || 1;

  return (
    <BlockWrapper className="ContactInfo">
      {!!title && meta.isFirstBlockWithTitle && (
        <h1 className="ContactInfo__title mb-8 w-full text-center font-grotesk-headline-news text-2xl lg:text-3xl lg:text-left">
          {title}
        </h1>
      )}
      {!!title && !meta.isFirstBlockWithTitle && (
        <h2 className="ContactInfo__title mb-8 w-full text-center font-grotesk-headline-news text-2xl lg:text-3xl lg:text-left">
          {title}
        </h2>
      )}
      {!!image && (
        <div
          className="ContactInfo__image relative w-full"
          style={{
            paddingBottom: `${(imageHeight / imageWidth) * 100}%`
          }}
        >
          <Image
            className="w-full"
            src={image.src}
            layout="fill"
            objectFit="contain"
            alt={image.alt}
          />
        </div>
      )}
      {!!description && (
        <div className="ContactInfo__description p-5 bg-sand flex flex-wrap items-start">
          <div className="w-full lg:w-3/4">
            <span className="font-grotesk-sub-headline text-sm leading-relaxed">
              <PortableText content={description} />
            </span>
          </div>
          <div className="flex lg:justify-end w-full lg:w-1/4 mt-4 lg:mt-0">
            <Button href="https://calendly.com/escapodtrailers/shop-tour" variant="fill">
              Schedule a Tour
            </Button>
          </div>
        </div>
      )}
      {!!locations && !!locations.length && (
        <div className="ContactInfo__locations w-full flex flex-col mt-24">
          <h2 className="ContactInfo__locations__title mb-8 w-full text-center font-grotesk-headline-news text-2xl lg:text-3xl lg:text-left">
            Locations
          </h2>
          <div className="ContactInfo__locations-grid grid lg:grid-cols-2 gap-8">
            {locations.map(location => (
              <div
                key={`LocationCard--${location.name}`}
                className="ContactInfo__location border-2 rounded-lg mb-4 relative transition-all border-stone-200 shadow-sm hover:shadow-md"
              >
                <div className="absolute top-0 left-0 rounded-br-md px-2 py-1 z-20 bg-stone-200">
                  <span className="font-grotesk-headline tracking-wide text-xs">
                    {location.name.toLocaleLowerCase().includes('service')
                      ? 'Service Center'
                      : location.name.toLocaleLowerCase().includes('sales')
                      ? 'Dealer & Rentals'
                      : 'Headquarters'}
                  </span>
                </div>
                <div
                  className="relative overflow-hidden rounded-t-lg"
                  style={{
                    paddingBottom: `${(9 / 16) * 100}%`
                  }}
                >
                  <Image
                    src={location.image.src}
                    layout="fill"
                    objectFit="cover"
                    alt={location.name}
                  />
                </div>
                <div className="ContactInfo__location-info p-4 grid grid-cols-2 gap-6">
                  <div className="flex flex-col text-xs">
                    <span className="font-grotesk-headline mb-3 tracking-wide">
                      {location.name}
                    </span>
                    <span className="flex flex-col mb-3 tracking-wide">
                      <span>{location.address.address1}</span>
                      <span>
                        {location.address.city}, {location.address.state} {location.address.zip}
                      </span>
                      <a href={`tel:${location.phone}`} rel="noreferrer" target="_blank">
                        <span>{formatPhoneNumber(location.phone)}</span>
                      </a>
                    </span>

                    <div className="flex flex-col">
                      <PortableText
                        content={location.hours}
                        components={{
                          block: {
                            normal: ({ children }) => {
                              return <p>{children}</p>;
                            }
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col items-end">
                    {location.buttons?.map(({ _key, label, url }) => (
                      <Button
                        key={_key}
                        href={url}
                        variant="stroke"
                        color="gray"
                        size="sm"
                        className="mt-2"
                      >
                        {label}
                      </Button>
                    ))}
                  </div>
                </div>
              </div>
            ))}
            <div className="ContactInfo__location border-2 rounded-lg mb-4 relative transition-all border-stone-200 shadow-sm flex flex-col justify-center items-center py-8 px-8 lg:px-16 text-center hover:shadow-md">
              <div ref={arrowRef} className="mb-4">
                <IoNavigateCircleSharp
                  className="w-8 h-8 fill-fire"
                  style={{ transform: `rotate(${rotate}deg)` }}
                />
              </div>
              <span className="font-grotesk-headline text-xl mb-4">
                Looking for something closer?
              </span>
              <span className="text-xs mb-8 !leading-relaxed">
                Our Trailer Finder makes it easy to see and schedule dealer viewings, rentals, and
                experiences near your current location.
              </span>
              <Button href="/trailer-finder" size="sm" color="gray" variant="stroke">
                Find a Trailer
              </Button>
            </div>
          </div>
        </div>
      )}
    </BlockWrapper>
  );
};

export default ContactInfo;
