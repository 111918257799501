import { FC, useState, useCallback } from 'react';
import { Button, Select, TextField } from 'components';

import { useFinancingContext, useFinancingMutate } from 'contexts/FinancingContext';
import ssnIsValid from 'utils/ssnIsValid';
import formatSSN from 'utils/formatSSN';
import dobIsValid from 'utils/dobIsValid';
import { StateCodes } from 'constants/Financing';

type Errors = { [key: string]: string };
const STATE_OPTIONS = StateCodes.map(state => ({ label: state, value: state }));

const IdPrimary: FC = () => {
  const { primary } = useFinancingContext();
  const [errors, setErrors] = useState<Errors>({});
  const mutate = useFinancingMutate();

  const _continue = useCallback(() => {
    const _errors: Errors = {};
    if (!primary.ssn || !ssnIsValid(primary.ssn))
      _errors.ssn = 'Please enter a valid 9-digit SSN, only numbers, no symbols.';
    if (!primary.dob || !dobIsValid(primary.dob)) _errors.dob = 'Please enter a valid date.';

    if (!primary.address1) _errors.address1 = 'Please enter a valid street address.';
    if (!primary.city) _errors.city = 'Please enter a city.';
    if (!primary.state) _errors.state = 'Please select a state.';
    if (!primary.zip) _errors.zip = 'Please enter a postal code.';

    setErrors(_errors);
    if (!!Object.keys(_errors).length) return;

    return mutate?.setStage('EMPLOYMENT_PRIMARY');
  }, [primary, setErrors, mutate]);

  return (
    <div className="FinancingApplication__IdPrimary flex flex-col items-center justify-center w-full max-w-prose m-auto animate-fade-in">
      <div className="w-full">
        <div className="mb-12 flex flex-col">
          <span className="mb-12 font-grotesk-headline tracking-wide">
            Primary Applicant&rsquo;s Identification{' '}
            <span className="text-sand ml-3">({primary.firstName})</span>
          </span>
          <div className="lg:grid w-full lg:grid-cols-2 lg:gap-8">
            <div className="mb-6 lg:mb-8">
              <TextField
                name="ssn"
                required={true}
                ariaLabel="Social Security Number"
                label="Social Security Number"
                value={formatSSN(primary.ssn)}
                onChange={value =>
                  mutate?.setApplicant('primary', { ssn: (value as string).split('-').join('') })
                }
                error={errors?.ssn}
                showError={!!errors?.ssn}
              />
            </div>
            <div className="mb-6 lg:mb-8">
              <TextField
                name="dob"
                type="date"
                required={true}
                ariaLabel="Date of Birth"
                label="Date of Birth"
                value={primary.dob}
                onChange={value => mutate?.setApplicant('primary', { dob: value as string })}
                error={errors?.dob}
                showError={!!errors?.dob}
              />
            </div>
          </div>
        </div>
        <div className="mb-12 flex flex-col">
          <span className="mb-12 font-grotesk-headline tracking-wide">
            Primary Applicant&rsquo;s Current Address{' '}
            <span className="text-sand ml-3">({primary.firstName})</span>
          </span>
          <div className="lg:grid w-full lg:grid-cols-2 lg:gap-8">
            <div className="mb-6 lg:mb-8">
              <TextField
                name="address1"
                required={true}
                ariaLabel="Address"
                label="Address"
                value={primary.address1}
                onChange={value => mutate?.setApplicant('primary', { address1: value as string })}
                error={errors?.address1}
                showError={!!errors?.address1}
              />
            </div>
            <div className="mb-6 lg:mb-8">
              <TextField
                name="address2"
                ariaLabel="Apt / Unit #"
                label="Apt / Unit #"
                value={primary.address2}
                onChange={value => mutate?.setApplicant('primary', { address2: value as string })}
                error={errors?.address2}
                showError={!!errors?.address2}
              />
            </div>
          </div>

          <div className="lg:grid w-full lg:grid-cols-3 lg:gap-8">
            <div className="mb-6 lg:mb-8">
              <TextField
                name="city"
                required={true}
                ariaLabel="City"
                label="City"
                value={primary.city}
                onChange={value => mutate?.setApplicant('primary', { city: value as string })}
                error={errors?.city}
                showError={!!errors?.city}
              />
            </div>
            <div className="mb-6 lg:mb-8">
              <Select
                name="state"
                ariaLabel="State"
                label="State"
                required={true}
                value={primary.state}
                onChange={value => mutate?.setApplicant('primary', { state: value as string })}
                error={errors?.status}
                showError={!!errors?.status}
              >
                {STATE_OPTIONS.map(({ value, label }) => (
                  <option key={`IdPrimaryState__option--${value}`} value={value}>
                    {label}
                  </option>
                ))}
              </Select>
            </div>
            <div className="mb-6 lg:mb-8">
              <TextField
                name="zip"
                ariaLabel="ZIP"
                label="ZIP"
                required={true}
                value={primary.zip}
                onChange={value => mutate?.setApplicant('primary', { zip: value as string })}
                error={errors?.zip}
                showError={!!errors?.zip}
              />
            </div>
          </div>
        </div>
        <div className="w-full flex justify-between">
          <Button color="gray" variant="fill" size="lg" onClick={() => mutate?.setStage('BASIC')}>
            <span className="mr-2">&larr;</span> Back
          </Button>
          <Button color="sand" variant="fill" size="lg" onClick={_continue}>
            Continue <span className="ml-2">&rarr;</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default IdPrimary;
