import React, { FC, FormEvent, MouseEvent, useEffect } from 'react';
import cx from 'classnames';

import loader from '../../public/images/loader.gif';

import { Button, FormWrapper, Image } from 'components';
import BlockWrapper from './BlockWrapper';
import { useNavContext } from 'contexts/NavContext';

type Props = {
  name: string;
  title: string;
  step: number;
  steps: number;
  onNext?: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  onBack?: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  onSubmit?: (event: FormEvent<HTMLFormElement>) => void;
  backButtonIsDisabled?: boolean;
  nextButtonIsDisabled?: boolean;
  isLoading?: boolean;
  backButtonLabel?: string;
  nextButtonLabel?: string;
  children: React.ReactNode;
  className?: string;
};

const BuilderStageWrapper: FC<Props> = ({
  name,
  title,
  step,
  steps,
  onNext,
  onBack,
  onSubmit,
  backButtonIsDisabled = false,
  nextButtonIsDisabled = false,
  isLoading = false,
  backButtonLabel = 'Back',
  nextButtonLabel = 'Next',
  children,
  className = ''
}) => {
  const navContext = useNavContext();

  useEffect(() => {
    if (navContext.theme !== 'black') navContext.setContext({ ...navContext, theme: 'black' });
  }, [navContext]);

  return (
    <div
      className={cx(
        'BuilderStageWrapper w-full relative min-h-screen overflow-auto pt-32 lg:pt-48',
        className
      )}
    >
      <BlockWrapper>
        <h2 className="BuilderStageWrapper__title mb-8 w-full text-center font-grotesk-headline-news text-2xl md:text-3xl">
          {title}
        </h2>
        <FormWrapper
          id={`Builder__stage--${name}`}
          name={name}
          className="flex flex-col w-full"
          onSubmit={onSubmit}
        >
          <div className="min-h-[60vh] w-full relative flex items-start flex-col lg:flex-row animate-slide-up z-40">
            {children}
          </div>
          <div className="BuilderStageWrapper__navigation w-full flex justify-between items-center mt-8 relative">
            <div className="w-1/4">
              <Button
                variant="fill"
                size="sm"
                color="gray"
                className="sm:hidden"
                onClick={onBack}
                disabled={backButtonIsDisabled}
              >
                <span className="mr-1">←</span>
                {backButtonLabel}
              </Button>
              <Button
                variant="stroke"
                size="lg"
                color="gray"
                className="hidden sm:block"
                onClick={onBack}
                disabled={backButtonIsDisabled}
              >
                <span className="mr-3">←</span>
                {backButtonLabel}
              </Button>
            </div>
            <span className="font-grotesk-headline text-xs uppercase tracking-wide lg:mt-4 w-1/2 text-center hidden sm:block">
              Step {step} of {steps}
            </span>
            <div className="w-1/4 flex justify-end">
              <Button
                className="sm:hidden"
                variant="fill"
                color="sand"
                size="sm"
                type={!!onSubmit ? 'submit' : 'button'}
                onClick={!!onSubmit ? undefined : onNext}
                disabled={nextButtonIsDisabled || isLoading}
              >
                {nextButtonLabel}
                <span className="ml-1">→</span>
              </Button>
              <Button
                className="hidden sm:block"
                variant="fill"
                color="sand"
                size="lg"
                type={!!onSubmit ? 'submit' : 'button'}
                onClick={!!onSubmit ? undefined : onNext}
                disabled={nextButtonIsDisabled || isLoading}
              >
                {nextButtonLabel}
                <span className="ml-3">→</span>
              </Button>
            </div>
            <div
              className={cx(
                'BuilderStageWrapper__loader-gif absolute right-0 bottom-14 sm:right-[150px] sm:bottom-3',
                {
                  hidden: !isLoading
                }
              )}
            >
              <div className="h-5 w-5">
                <Image src={loader} layout="fill" alt="Submit button loading GIF" />
              </div>
            </div>
          </div>
        </FormWrapper>
      </BlockWrapper>
    </div>
  );
};

export default BuilderStageWrapper;
