import React, { FC, useCallback, useState } from 'react';
import * as Sentry from '@sentry/nextjs';

import EscapodAPI from 'clients/Escapod';
import * as Gtag from 'clients/Gtag';
import emailIsValid from 'utils/emailIsValid';
import Fbq from 'clients/Fbq';

import { Block, PortableText as TPortableText } from 'types';
import { BlockWrapper, FormWrapper, TextField, Button, PortableText } from 'components';

export type TNewsletterSignup = Block<
  'newsletterSignup',
  {
    title: string;
    variant: 'primary' | 'secondary';
    body?: TPortableText;
    tags?: {
      _key: string;
      _type: string;
      label: string;
      value: string;
    }[];
    listId?: string;
  }
>;

export const NewsletterSignup: FC<TNewsletterSignup> = props => {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [isSending, setIsSending] = useState<boolean>(false);

  const [error, setError] = useState<{ [key: string]: string }>({});

  const submit = useCallback(() => {
    setError({});

    if (!firstName) return setError({ firstName: 'Enter your first name.' });
    if (!lastName) return setError({ lastName: 'Enter your last name.' });
    if (!email || !emailIsValid(email)) return setError({ email: 'Enter a valid email.' });
    if (props.variant === 'primary' && !phone)
      return setError({ phone: 'Enter a valid phone number.' });

    Sentry.setUser({ email });
    setIsSending(true);

    EscapodAPI.klaviyo
      .subscribe({
        firstName,
        lastName,
        email,
        phone: phone ? `1${phone}` : '',
        form: 'newsletter_block',
        listId: props.listId
      })
      .then(res => {
        if (res.status === 201) {
          Fbq('Subscribe', { value: '0.00', currency: 'USD', predicted_ltv: '0.00' });
          Gtag.event('conversion', {
            send_to: 'AW-814232277/VfPlCMato6YDENXloIQD',
            transaction_id: window.btoa(email),
            event_callback: () => {
              return;
            }
          });
          setError({ form: 'Thank you for your submission!' });
          setTimeout(() => {
            setError({});
            setFirstName('');
            setLastName('');
            setEmail('');
            setPhone('');
            setIsSending(false);
          }, 3000);
        }
      })
      .catch(err => {
        setIsSending(false);
        if (err.response.status === 400) {
          if (err.response.data.message === 'email invalid') {
            return setError({ email: 'Please enter a valid email address.' });
          }
          if (err.response.data.message === 'listId invalid') {
            return setError({ form: 'Something went wrong. Please try again later!' });
            // TO-DO: Add Sentry to notify developers of a listId malfunction
          }
          if (err.response.data.message === 'member exists') {
            return setError({
              form: "You've already subscribed with that email address! Try a different one."
            });
          }
        }
        return setError({ form: 'Something went wrong. Please try again later!' });
        // TO-DO: Add Sentry to notify developers of an unknown malfunction
      });
  }, [firstName, lastName, email, phone, props]);

  if (props.variant === 'primary') {
    return (
      <BlockWrapper className="NewsletterSignup">
        <div className="NewsletterSignup-inner lg:flex">
          <div className="NewsletterSignup-title-body-wrapper lg:w-1/2 lg:pr-12">
            <div className="NewsletterSignup-title-wrapper mb-6 pr-3">
              {props.title && props.meta.isFirstBlockWithTitle && (
                <h1 className="NewsletterSignup-title whitespace-normal font-grotesk-headline-news text-2xl lg:text-3xl">
                  {props.title}
                </h1>
              )}
              {props.title && !props.meta.isFirstBlockWithTitle && (
                <h2 className="NewsletterSignup-title whitespace-normal font-grotesk-headline-news text-2xl lg:text-3xl">
                  {props.title}
                </h2>
              )}
            </div>
            <div className="NewsletterSignup-body-wrapper mb-8 font-grotesk text-xs lg:text-sm !leading-relaxed">
              <PortableText content={props.body} />
            </div>
          </div>
          <div className="NewsletterSignup-form-interest-signup-wrapper lg:w-1/2">
            <FormWrapper
              className="NewsletterSignup__form-wrapper"
              id="NewsletterSignup-Subscribe"
              name="NewsletterSignup-Subscribe"
            >
              <div className="NewsletterSignup__name-input mb-6 lg:mb-8 lg:w-full">
                <TextField
                  className="whitespace-nowrap"
                  name="first_name"
                  label="First Name"
                  ariaLabel="Last Name"
                  value={firstName}
                  error={error.firstName}
                  showError={!!error.firstName}
                  onChange={value => setFirstName(value as string)}
                />
              </div>
              <div className="NewsletterSignup__name-input mb-6 lg:mb-8 lg:w-full">
                <TextField
                  className="whitespace-nowrap"
                  name="last_name"
                  label="Last Name"
                  ariaLabel="Last Name"
                  value={lastName}
                  error={error.lastName}
                  showError={!!error.lastName}
                  onChange={value => setLastName(value as string)}
                />
              </div>
              <div className="NewsletterSignup__email-input mb-10 lg:w-full">
                <TextField
                  className="whitespace-nowrap"
                  name="email"
                  label="Email Address"
                  ariaLabel="Email Address"
                  value={email}
                  error={error.email}
                  showError={!!error.email}
                  onChange={value => setEmail(value as string)}
                />
              </div>
              <div className="NewsletterSignup__email-input mb-10 lg:w-full">
                <TextField
                  className="whitespace-nowrap"
                  name="phone"
                  label="Phone Number"
                  ariaLabel="Phone Number"
                  value={phone}
                  error={error.phone}
                  showError={!!error.phone}
                  onChange={value => setPhone(value as string)}
                />
              </div>
              {error.form && (
                <div className="NewsletterSignup__form-error mt-8 lg:mt-4 lg:-mb-6">
                  <span className="text-center text-xs text-fire">{error.form}</span>
                </div>
              )}
              <div className="NewsletterSignup__signup-button mt-10">
                <Button
                  className="w-full lg:w-auto"
                  type="button"
                  disabled={isSending}
                  onClick={submit}
                >
                  Submit
                </Button>
              </div>
            </FormWrapper>
          </div>
        </div>
      </BlockWrapper>
    );
  }

  return (
    <BlockWrapper className="NewsletterSignup">
      <div className="NewsletterSignup__inner-wrapper relative flex flex-col justify-center rounded-sm bg-stone-200 p-6 text-center lg:flex-row lg:items-center">
        <div className="NewsletterSignup__title-wrapper mb-7 text-center lg:pr-8 lg:mb-0">
          <span className="NewsletterSignup__title md:whitespace-nowrap font-grotesk-headline text-lg tracking-wide text-charcoal lg:text-sm xl:text-base">
            {props.title}
          </span>
        </div>
        {error.form && (
          <div className="NewsletterSignup__form-error mb-8 flex self-center lg:absolute lg:top-0 lg:right-1 lg:-mt-6">
            <span className="text-center text-xs text-fire">{error.form}</span>
          </div>
        )}
        <FormWrapper
          className="NewsletterSignup__form-wrapper lg:flex lg:w-full lg:items-center"
          id="NewsletterSignup-Subscribe"
          name="NewsletterSignup-Subscribe"
        >
          <div className="NewsletterSignup__name-input mb-6 lg:mb-0 lg:mr-3 lg:w-full">
            <TextField
              className="whitespace-nowrap"
              name="first_name"
              placeholder="First Name"
              ariaLabel="First Name"
              value={firstName}
              error={error.firstName}
              showError={!!error.firstName}
              onChange={value => setFirstName(value as string)}
            />
          </div>
          <div className="NewsletterSignup__name-input mb-6 lg:mb-0 lg:mr-3 lg:w-full">
            <TextField
              className="whitespace-nowrap"
              name="last_name"
              placeholder="Last Name"
              ariaLabel="Last Name"
              value={lastName}
              error={error.lastName}
              showError={!!error.lastName}
              onChange={value => setLastName(value as string)}
            />
          </div>
          <div className="NewsletterSignup__email-input mb-6 lg:mb-0 lg:mr-3 lg:w-full">
            <TextField
              className="whitespace-nowrap"
              name="email"
              placeholder="Email Address"
              ariaLabel="Email Address"
              value={email}
              error={error.email}
              showError={!!error.email}
              onChange={value => setEmail(value as string)}
            />
          </div>
          <div className="NewsletterSignup__signup-button pt-4 lg:mb-0 lg:pt-0">
            <Button className="w-full" type="button" disabled={isSending} onClick={submit}>
              Signup
            </Button>
          </div>
        </FormWrapper>
      </div>
    </BlockWrapper>
  );
};

export default NewsletterSignup;
