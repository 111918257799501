import React, { FC, useCallback, useRef, useEffect, useState, useMemo } from 'react';
import mapboxgl, { Marker } from 'mapbox-gl';
import cx from 'classnames';
import hash from 'object-hash';
import AnimateHeight from 'react-animate-height';
import * as Sentry from '@sentry/nextjs';
import { useDebounce } from 'usehooks-ts';
import { animateScroll } from 'react-scroll';
import RSelect, { components, OptionProps, Options } from 'react-select';
import distance from '@turf/distance';

import { useNavContext } from 'contexts/NavContext';
import { Block, Location, GeoJSON } from 'types';
import { BlockWrapper, Button, Checkbox, Image, Select, TextField } from 'components';
import { LocationFilters, Radii } from 'constants/Finder';
import * as Gtag from 'clients/Gtag';
import EscapodApi from 'clients/Escapod';
import Fbq from 'clients/Fbq';
import Klaviyo from 'clients/Klaviyo/browser';

import formatPhoneNumber from 'utils/formatPhoneNumber';
import formatOpenHours from 'utils/formatOpenHours';

import loader from '../../public/images/loader.gif';
import immersiveCloseIcon from 'public/images/escapod-immersive-close-icon.svg';
import whiteCloseIcon from 'public/images/icon-close-circle.svg';
import emailIsValid from 'utils/emailIsValid';
import { compareAsc, format } from 'date-fns';
import { IoCheckmarkCircle } from 'react-icons/io5';

mapboxgl.accessToken = process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN || '';

const DESKTOP_DEFAULT_LNG = -107.5;
const DESKTOP_DEFAULT_LAT = 40.95;
const DESKTOP_DEFAULT_ZOOM = 5.25;

const MOBILE_DEFAULT_LNG = -111.5;
const MOBILE_DEFAULT_LAT = 40.25;
const MOBILE_DEFAULT_ZOOM = 5;

const DEFAULT_IMG =
  'https://cdn.sanity.io/images/l3ps89do/production/90a6e36fac5781beb9d617a0cb5147b529f1ec7a-2048x1365.jpg';

export type TMap = Block<
  'map',
  {
    title: string;
  }
>;

const Option: FC<OptionProps> = ({ children, ...props }) => (
  <components.Option
    {...props}
    className="border-b-1 border-stone-200 last-of-type:border-b-0 !py-4 text-xs"
  >
    {children}
  </components.Option>
);

const createMarker = (config: {
  isSelected: boolean;
  isActive: boolean;
  isEvent?: boolean;
  name: string;
  onClick?: (e: MouseEvent) => void;
}) => {
  const delay = Math.random();
  const $inner = document.createElement('div');
  const $container = document.createElement('div');
  const $pin = document.createElement('div');
  const $label = document.createElement('div');

  $pin.className = 'marker';
  $pin.style.width = `46px`;
  $pin.style.height = `60px`;
  $pin.style.backgroundImage = !!config.isSelected
    ? `url('https://cdn.sanity.io/images/l3ps89do/production/805879bf981c969460e6b37ba06e905dfdbc8f0f-42x54.svg')`
    : !!config.isEvent
    ? `url('https://cdn.sanity.io/images/l3ps89do/production/627732a62e8fc19ca0d1c66e518c83ebe596e782-42x54.svg')`
    : `url('https://cdn.sanity.io/images/l3ps89do/production/805879bf981c969460e6b37ba06e905dfdbc8f0f-42x54.svg')`;
  $pin.style.backgroundSize = '100%';
  $pin.style.opacity = '0';
  $pin.style.animationDelay = `${delay}s`;
  $pin.style.animationDuration = '.75s';
  $pin.classList.add('animate-fade-in');
  $pin.style.zIndex = '0';
  // $pin.style.boxShadow = '0 2px 8px rgba(0, 0, 0, .35)'
  $label.style.padding = `6px 14px 6px 36px`;
  $label.style.boxShadow = '0 3px 4px rgba(0,0,0,.1)';
  $label.style.whiteSpace = 'nowrap';
  $label.style.fontFamily = 'Escapod Grotesk Headline';
  $label.style.fontSize = '12px';
  $label.style.letterSpacing = '.25px';
  $label.style.zIndex = '-1';
  $label.style.borderRadius = `999px`;
  $label.style.backgroundColor = config.isSelected ? `#F93F39` : `#FFFFFF`;
  $label.style.color = config.isSelected ? `#FFFFFF` : `#222222`;
  $label.style.position = 'absolute';
  $label.style.top = '6px';
  $label.style.left = '12px';
  $label.style.opacity = '0';
  $label.style.animationDelay = `${delay}s`;
  $label.style.animationDuration = '.75s';

  $label.classList.add('animate-fade-in');
  $label.appendChild(document.createTextNode(config.name));
  if (!!config.isEvent) {
    $inner.classList.add('animate-pulse');
    $inner.style.animationDelay = `${delay}s`;
    $inner.style.animationDuration = '2s';
  }
  $inner.appendChild($label);
  $inner.appendChild($pin);
  $container.style.cursor = 'pointer';
  $container.appendChild($inner);
  return $container;
};

export const Map: FC<TMap> = ({ title, meta }) => {
  const navContext = useNavContext();
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const markersRef = useRef('');

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [zip, setZip] = useState('');
  const [viewingFormErrors, setViewingFormErrors] = useState<{ [key: string]: string }>({});
  const [viewingFormSubmitting, setViewingFormSubmitting] = useState(false);
  const [newsletterOptIn, setNewsletterOptIn] = useState(true);
  const [desiredRadius, setDesiredRadius] = useState<string>(Object.keys(Radii)[0]);
  const [requestFormMessage, setRequestFormMessage] = useState('');
  const [isSubmittingRequest, setIsSubmittingRequest] = useState(false);
  const [markers, setMarkers] = useState<Marker[]>([]);
  const [locationTooltipActive, setLocationTooltipActive] = useState(false);
  const [activeLocation, setActiveLocation] = useState('');
  const [activeFilters, setActiveFilters] = useState<string[]>(['all']);
  const [radius, setRadius] = useState<string>(Object.keys(Radii)[0]);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce<string>(search, 500);
  const [userLocation, setUserLocation] = useState<GeoJSON | null>(null);
  const [userLocations, setUserLocations] = useState<string[]>([]);
  const [searchResults, setSearchResults] = useState<GeoJSON[]>([]);
  const [viewingFormActive, setViewingFormActive] = useState(false);
  const [selectedNextEventIntent, setSelectedNextEventIntent] = useState<boolean | null>(null);
  const [nextEventDrawerIsActive, setNextEventDrawerIsActive] = useState(false);

  const [eventName, setEventName] = useState('');
  const [eventEmail, setEventEmail] = useState('');
  const [eventMessage, setEventMessage] = useState('');
  const [eventFormErrors, setEventFormErrors] = useState<{ [key: string]: string }>({});
  const [eventFormSubmitting, setEventFormSubmitting] = useState(false);
  const [eventFormSuccess, setEventFormSuccess] = useState(false);
  const [eventOptIn, setEventOptIn] = useState(true);

  const [results, setResults] = useState<Location[]>([]);
  const nudge = useCallback(() => {
    setLocationTooltipActive(true);
    void animateScroll.scrollMore(170);
    setTimeout(() => setLocationTooltipActive(false), 3000);
  }, []);
  useEffect(() => {
    if (!userLocation && radius !== '0') {
      setLocationTooltipActive(true);
      setTimeout(() => setLocationTooltipActive(false), 3000);
    }
  }, [radius, userLocation]);

  const _toggleFilter = useCallback(
    (key: string) => {
      const filters =
        key === 'all'
          ? ['all']
          : activeFilters.includes(key)
          ? activeFilters.filter(filter => filter !== key && filter !== 'all')
          : [...activeFilters, key].filter(filter => filter !== 'all');

      setActiveFilters(filters.length ? filters : ['all']);
    },
    [activeFilters, setActiveFilters]
  );

  const _submitFinderRequest = useCallback(() => {
    const submit = async () => {
      if (!userLocation || !name || !email) return;

      setIsSubmittingRequest(true);
      await EscapodApi.locations.finderRequest({
        templateParams: {
          NAME: name,
          EMAIL: email,
          LOCATION: userLocation.place_name,
          LNG: userLocation?.center[0] || 0,
          LAT: userLocation?.center[1] || 0
        }
      });

      Sentry.setUser({ email });

      await EscapodApi.klaviyo
        .subscribe({
          listId: 'SEft9S',
          firstName: name.split(' ')?.[0],
          lastName: name.split(' ')?.[name.split(' ').length - 1] || '',
          email,
          form: 'finder_request'
        })
        .then(res => {
          if (res.status === 201) {
            Fbq('Subscribe', { value: '0.00', currency: 'USD', predicted_ltv: '0.00' });
            Gtag.event('conversion', {
              send_to: 'AW-814232277/VfPlCMato6YDENXloIQD',
              transaction_id: window.btoa(email),
              event_callback: () => {
                return;
              }
            });
          }
        });

      setIsSubmittingRequest(false);
      setRequestFormMessage('Your request has been received!');
      setTimeout(() => setRequestFormMessage(''), 5000);
    };

    submit();
  }, [name, email, userLocation]);

  useEffect(() => {
    if (map.current) return;

    navContext.setContext({
      ...navContext,
      theme: 'black',
      immersive: {
        title: `Escapod Trailer Finder`,
        subtitle: `Loading options for viewing a trailer`
      }
    });

    map.current = new mapboxgl.Map({
      container: mapContainer.current || '',
      style: 'mapbox://styles/escapod/clm70b9q6002501pvbf4a29j5',
      pitch: 40,
      center:
        (mapContainer.current?.clientWidth || 0) <= 620
          ? [MOBILE_DEFAULT_LNG, MOBILE_DEFAULT_LAT]
          : [DESKTOP_DEFAULT_LNG, DESKTOP_DEFAULT_LAT],
      zoom:
        (mapContainer.current?.clientWidth || 0) <= 620 ? MOBILE_DEFAULT_ZOOM : DESKTOP_DEFAULT_ZOOM
    });
  }, [mapContainer, navContext]);

  useEffect(() => {
    if (!results.length || navContext.immersive.subtitle.includes(results.length.toString()))
      return;
    navContext.setContext({
      ...navContext,
      theme: 'black',
      immersive: {
        title: `Escapod Trailer Finder`,
        subtitle: `${!!results.length ? results.length : 'Loading'} options for viewing a trailer`
      }
    });
  }, [results, navContext]);

  useEffect(() => {
    const fetchSearchResults = async () => {
      const locations = await EscapodApi.locations.geolocate(debouncedSearch);
      if (locations.length) setSearchResults(locations);
    };

    fetchSearchResults();
  }, [debouncedSearch]);

  useEffect(() => {
    const fetchResults = async () => {
      const results = await EscapodApi.locations.fetch();
      setResults(results);
    };

    fetchResults();
  }, []);

  useEffect(() => {
    if (!map.current || !userLocation) return;
    map.current.flyTo({
      center: userLocation.center,
      duration: 1500,
      zoom:
        radius === '0'
          ? 9
          : radius === '50'
          ? 12
          : radius === '100'
          ? 9
          : radius === '250'
          ? 6
          : radius === '500'
          ? 5
          : 4
    });
  }, [radius, map, userLocation]);

  const searchResultsOptions: Options<{ value: string; label: string }> = useMemo(
    () =>
      searchResults.map(result => ({
        value: result.id,
        label: result.place_name
      })),
    [searchResults]
  );

  const resultsDistanceFromUserLocation = useMemo(
    () =>
      results.reduce<{ [key: string]: number }>((_results, result) => {
        return {
          ..._results,
          [result.id]: !!userLocation?.center
            ? distance([result.geo.lng, result.geo.lat], userLocation?.center, { units: 'miles' })
            : 0
        };
      }, {}),
    [results, userLocation]
  );
  const idsByDistance = useMemo(
    () =>
      Object.keys(resultsDistanceFromUserLocation).sort((a, b) => {
        return resultsDistanceFromUserLocation[a] - resultsDistanceFromUserLocation[b];
      }),
    [resultsDistanceFromUserLocation]
  );
  const filteredResults = results
    .filter(
      result => !parseInt(radius) || parseInt(radius) >= resultsDistanceFromUserLocation[result.id]
    )
    .filter(
      result =>
        activeFilters.includes('all') || result.tags.some(tag => activeFilters.includes(tag))
    );

  useEffect(() => {
    const $el = document.querySelector(`#LocationCard--${activeLocation}`);
    if ($el) $el.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, [activeLocation]);

  useEffect(() => {
    const hashed = hash({ results, activeLocation, activeFilters, radius });
    if (!map.current || !results.length || markersRef.current === hashed) return;
    markers.forEach(marker => marker.remove());

    const _markers = results
      .filter(result => {
        const withinRadius =
          !parseInt(radius) || parseInt(radius) >= resultsDistanceFromUserLocation[result.id];
        const withinFilters =
          activeFilters.includes('all') || result.tags.some(tag => activeFilters.includes(tag));
        return withinFilters && withinRadius;
      })
      .reduce<Marker[]>((results, result) => {
        if (!map.current) return results;
        const $el = createMarker({
          name: result.tags.includes('event') ? result.name : result.display,
          isActive: true,
          isEvent: result.tags.includes('event'),
          isSelected: activeLocation === result.id
        });
        const marker = new mapboxgl.Marker($el)
          .setLngLat([result.geo.lng, result.geo.lat])
          .addTo(map.current);

        marker.getElement().addEventListener('click', () => setActiveLocation(result.id));

        return [...results, marker];
      }, []);

    markersRef.current = hashed;
    setMarkers(_markers);
  }, [
    map,
    markers,
    results,
    activeLocation,
    activeFilters,
    radius,
    resultsDistanceFromUserLocation
  ]);

  useEffect(() => {
    if (!userLocation) return;
    if (userLocations.includes(userLocation.id)) return;

    setUserLocations([...userLocations, userLocation.id]);
    const recordInterest = async () => {
      await EscapodApi.locations.interest({
        name,
        email,
        location: userLocation.place_name,
        lng: userLocation?.center[0] || 0,
        lat: userLocation?.center[1] || 0
      });
    };
    recordInterest();
  }, [userLocation, userLocations, name, email]);

  useEffect(() => {
    if (viewingFormActive) {
      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = '15px';
    }
    if (!viewingFormActive) {
      document.body.style.overflow = 'unset';
      document.body.style.paddingRight = '0px';
    }
  }, [viewingFormActive]);

  const submitViewingForm = useCallback(() => {
    if (!name) return setViewingFormErrors({ name: 'Please enter your full name.' });
    if (!email || !emailIsValid(email))
      return setViewingFormErrors({ email: 'Please enter a valid email address.' });
    if (!phone) return setViewingFormErrors({ phone: 'Please enter a valid phone number.' });
    if (!zip) return setViewingFormErrors({ zip: 'Please enter a valid ZIP or Postal Code.' });

    const result = results.find(result => result.id === activeLocation);

    setViewingFormErrors({});

    const submit = async () => {
      try {
        setViewingFormSubmitting(true);
        await EscapodApi.locations.viewingRequest({
          templateParams: {
            NAME: name,
            EMAIL: email,
            PHONE: phone,
            ZIP: zip,
            DESIRED_RADIUS: desiredRadius,
            SELECTION: !!result ? `${result.category} - ${result.name}` : ''
          }
        });

        if (newsletterOptIn) {
          EscapodApi.klaviyo.subscribe({ email, form: 'trailer_viewing_request' });
          Fbq('Subscribe', { value: '0.00', currency: 'USD', predicted_ltv: '0.00' });
          Gtag.event('conversion', {
            send_to: 'AW-814232277/VfPlCMato6YDENXloIQD',
            transaction_id: window.btoa(email),
            event_callback: () => {
              return;
            }
          });
        }

        if (
          process.env.NODE_ENV !== 'development' &&
          process.env.NEXT_PUBLIC_SANITY_DATASET !== 'staging'
        ) {
          Fbq('Lead');
          Gtag.event('trailer_viewing_request', {
            path: window?.location.pathname
          });
          Gtag.event('conversion', {
            send_to: 'AW-814232277/inG3CMPa2fgYENXloIQD',
            event_callback: () => {
              return;
            }
          });
          Klaviyo.events.viewingRequest({ name, phone, zip, desiredRadius });
        }

        setViewingFormSubmitting(false);
        setViewingFormErrors({
          form: 'Thanks! We have received your request. Our sales team will respond to your request soon!'
        });
        setTimeout(() => {
          setViewingFormErrors({});
          setViewingFormActive(false);
        }, 5000);
      } catch (err) {
        console.log(err);
        Sentry.captureException(err);
        setViewingFormErrors({ form: 'Unknown error occurred.' });
      }
    };

    submit();
  }, [name, email, zip, phone, newsletterOptIn, desiredRadius, activeLocation]);

  const nextEvent = useMemo(() => {
    const events = results
      .filter(result => result.category === 'Event')
      .sort((a, b) => compareAsc(new Date(a.startDate || ''), new Date(b.startDate || '')));

    return events?.[0];
  }, [results]);

  const submitEventAttendanceForm = useCallback(() => {
    if (!eventName) return setEventFormErrors({ name: 'Please enter your full name.' });
    if (!eventEmail || !emailIsValid(eventEmail))
      return setEventFormErrors({ email: 'Please enter a valid email address.' });

    setEventFormErrors({});
    const submit = async () => {
      try {
        setEventFormSubmitting(true);
        await EscapodApi.locations.eventAttendanceRequest({
          templateParams: {
            NAME: eventName,
            EMAIL: eventEmail,
            MESSAGE: eventMessage,
            EVENT: nextEvent.name,
            INTENT: selectedNextEventIntent ? "I'm going!" : 'Not going.'
          }
        });

        if (eventOptIn) {
          EscapodApi.klaviyo.subscribe({ email: eventEmail, form: 'event_attendance_form' });
          Fbq('Subscribe', { value: '0.00', currency: 'USD', predicted_ltv: '0.00' });
          Gtag.event('conversion', {
            send_to: 'AW-814232277/VfPlCMato6YDENXloIQD',
            transaction_id: window.btoa(eventEmail),
            event_callback: () => {
              return;
            }
          });
        }

        if (
          process.env.NODE_ENV !== 'development' &&
          process.env.NEXT_PUBLIC_SANITY_DATASET !== 'staging'
        ) {
          Fbq('Lead');
          Gtag.event('trailer_viewing_request', {
            path: window?.location.pathname
          });
          Gtag.event('conversion', {
            send_to: 'AW-814232277/inG3CMPa2fgYENXloIQD',
            event_callback: () => {
              return;
            }
          });
          Klaviyo.events.eventAttendance({
            name: eventName,
            event: nextEvent.name,
            intent: !!selectedNextEventIntent
          });
        }

        setEventFormSubmitting(false);
        setEventFormSuccess(true);
        setTimeout(() => {
          setEventFormErrors({});
        }, 5000);
      } catch (err) {
        console.log(err);
        Sentry.captureException(err);
        setEventFormErrors({ form: 'Unknown error occurred.' });
      } finally {
        setEventFormSubmitting(false);
      }
    };

    submit();
  }, [eventName, eventEmail, eventMessage, selectedNextEventIntent, nextEvent, eventOptIn]);

  useEffect(() => {
    const result = results.find(result => result.id === activeLocation);
    if (activeLocation === nextEvent?.id) {
      setTimeout(() => setNextEventDrawerIsActive(true), 2000);
    } else {
      setSelectedNextEventIntent(null);
      setNextEventDrawerIsActive(false);
    }
    if (!map.current || !activeLocation || !result) return;
    map.current.flyTo({
      center: [result?.geo.lng, result?.geo.lat],
      duration: 1500,
      zoom: result.name.toLowerCase().includes('escapod') ? 12.25 : 11.25
    });
  }, [results, map, activeLocation, nextEvent]);

  return (
    <BlockWrapper layout="full" className="flex flex-col lg:flex-row lg:justify-end !mb-0">
      <div className="Map__title-body-wrapper hidden">
        <div className="Map__title">
          {!!title && meta.isFirstBlockWithTitle && (
            <h1 className="font-grotesk-headline-news text-2xl md:text-3xl lg:text-4xl">{title}</h1>
          )}
          {!!title && !meta.isFirstBlockWithTitle && (
            <h2 className="font-grotesk-headline-news text-2xl md:text-3xl lg:text-4xl">{title}</h2>
          )}
        </div>
      </div>

      <div className="w-full h-[220px] md:h-[468px] lg:h-[calc(100%+40px)] lg:fixed top-0 left-0 lg:right-0 lg:bottom-0 overflow-hidden">
        <div
          ref={mapContainer}
          className="w-full h-[260px] md:h-[468px] lg:h-[calc(100%+40px)] lg:fixed top-0 left-0 lg:right-0 lg:bottom-0 bg-amber-50 animate-fade-in"
        />
        {nextEvent && (
          <div className="Map__next-event absolute left-8 bottom-16 animate-slide-up w-full max-w-[560px] hidden lg:block">
            <AnimateHeight
              height={nextEvent.id === activeLocation && nextEventDrawerIsActive ? 'auto' : 0}
            >
              <div className="Map__next-event__attendance-buttons flex items-center justify-between mt-4">
                <div className="flex flex-wrap">
                  <Button
                    onClick={() => setSelectedNextEventIntent(true)}
                    className="shadow-md"
                    size="md"
                    variant="fill"
                    color={!!selectedNextEventIntent ? 'black' : 'white'}
                  >
                    I&apos;m Going!
                  </Button>
                  <Button
                    onClick={() => setSelectedNextEventIntent(false)}
                    className="shadow-md ml-4"
                    size="md"
                    variant="fill"
                    color={selectedNextEventIntent === false ? 'black' : 'white'}
                  >
                    Not Going
                  </Button>
                </div>
                <div>
                  <Button
                    variant="no-style"
                    className={cx('h-[42px] shadow-md', {
                      'opacity-0': selectedNextEventIntent === null
                    })}
                    onClick={() => setSelectedNextEventIntent(null)}
                  >
                    <Image
                      width="34"
                      height="34"
                      hideLoadingPlaceholder={true}
                      src={whiteCloseIcon}
                      alt="Escapod Close Icon"
                    />
                  </Button>
                </div>
              </div>
            </AnimateHeight>
            <Button
              onClick={() => setActiveLocation(nextEvent.id)}
              className={cx(
                'Map__next-event__banner hover:bg-stone-100 flex justify-start cursor-pointer bg-white overflow-hidden shadow-md rounded-md h-[108px] mt-4 !p-0 !text-left w-full',
                { 'animate-bounce3': activeLocation !== nextEvent.id }
              )}
              variant="no-style"
            >
              <div className="flex items-start justify-start w-full">
                <div className="Map__next-event__image relative h-[108px]">
                  <Image
                    width="192px"
                    height="108px"
                    src={nextEvent.image}
                    alt={`Escapod Trailers at ${nextEvent.name}`}
                  />
                </div>
                <div className="Map__next-event__info flex flex-col px-4 py-4">
                  <span className="text-xs uppercase tracking-widest text-stone-400 font-grotesk-headline mb-1 flex">
                    <span>Upcoming Event</span>
                  </span>
                  <span className="text-xl tracking-wide font-grotesk-headline">
                    {nextEvent.name} <span className="ml-1 text-fire">&rarr;</span>
                  </span>
                  <span className="mt-3 text-xs font-grotesk-sub-headline-news text-stone-400">
                    <span>
                      {format(
                        new Date(nextEvent.startDate?.replace(/-/g, '/') || ''),
                        nextEvent.startDate !== nextEvent.endDate ? 'MMM dd' : 'MMM dd, yyyy'
                      )}
                    </span>
                    {!!nextEvent.endDate && nextEvent.startDate !== nextEvent.endDate && (
                      <span>
                        {' '}
                        - {format(new Date(nextEvent.endDate?.replace(/-/g, '/')), 'MMM dd, yyyy')}
                      </span>
                    )}
                  </span>
                </div>
              </div>
            </Button>
            <AnimateHeight
              height={
                nextEvent.id === activeLocation &&
                selectedNextEventIntent !== null &&
                eventFormSuccess
                  ? 'auto'
                  : 0
              }
            >
              <div className="Map__next-event__form-confirmation bg-white rounded-md shadow-md px-5 py-12 mt-4">
                <div className="w-full flex flex-col items-center justify-center">
                  <span className="text-3xl font-grotesk-headline-news tracking-wide">
                    <IoCheckmarkCircle className="text-green-500" />
                  </span>
                  <span className="text-sm font-grotesk-headline-news tracking-wide mt-2">
                    {!!selectedNextEventIntent ? 'See you soon!' : 'Thanks for letting us know'}
                  </span>
                  <span className="text-xs font-grotesk-news text-stone-400 mt-2">
                    {selectedNextEventIntent
                      ? 'Well keep you updated with news about this show.'
                      : "We'll respond to your message as soon as we can!"}
                  </span>
                </div>
              </div>
            </AnimateHeight>
            <AnimateHeight
              height={
                nextEvent.id === activeLocation &&
                selectedNextEventIntent !== null &&
                !eventFormSuccess
                  ? 'auto'
                  : 0
              }
            >
              <div className="Map__next-event__form bg-white rounded-md shadow-md px-5 py-6 mt-4">
                <div className="flex flex-col">
                  <span className="text-sm font-grotesk-headline-news tracking-wide">
                    {selectedNextEventIntent
                      ? "We'll put aside some time for you."
                      : "Why can't you make it?"}
                  </span>
                  <span className="text-xs font-grotesk-news text-stone-400 mt-2">
                    {selectedNextEventIntent
                      ? 'Our team is ready to answer your questions. Tell us more about yourself.'
                      : "Let us know if there's a better way to connect with you."}
                  </span>
                </div>
                <div className="grid grid-cols-2 gap-6 mt-6">
                  <TextField
                    name="next_event_name"
                    placeholder="Full Name"
                    ariaLabel="Name"
                    type="text"
                    value={eventName}
                    error={eventFormErrors.name}
                    showError={!!eventFormErrors.name}
                    onChange={value => setEventName(value as string)}
                  />
                  <TextField
                    name="next_event_email"
                    placeholder="Email"
                    ariaLabel="Email"
                    type="text"
                    value={eventEmail}
                    error={eventFormErrors.email}
                    showError={!!eventFormErrors.email}
                    onChange={value => setEventEmail(value as string)}
                  />
                </div>
                <TextField
                  className="mt-6"
                  name="next_event_message"
                  ariaLabel="Optional message"
                  placeholder="Optional message"
                  type="textarea"
                  value={eventMessage}
                  onChange={value => setEventMessage(value as string)}
                />
                <div className="flex justify-between mt-4">
                  <Checkbox
                    reverse={true}
                    name="subscribe"
                    value={eventOptIn}
                    label="Keep me updated about events."
                    onChange={value => setEventOptIn(value)}
                  />
                  <div className="flex justify-between items-center">
                    <div
                      className={cx('relative w-4 h-4 mr-2 transition-opacity', {
                        'opacity-0': !eventFormSubmitting
                      })}
                    >
                      <Image src={loader} layout="fill" alt="loading GIF" />
                    </div>
                    <Button
                      disabled={eventFormSubmitting}
                      variant="fill"
                      size="sm"
                      color="sand"
                      onClick={submitEventAttendanceForm}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </AnimateHeight>
          </div>
        )}
      </div>

      <div className="Map__ui bg-white w-full md:px-48 lg:px-0 lg:w-[452px] xl:w-[472px] pb-12 overflow-auto z-0 lg:min-h-screen">
        <div className="Map__ui__nudge hidden lg:flex px-6 lg:px-12 w-full mb-12 animate-fade-in">
          <Button variant="no-style" onClick={nudge} className="w-full">
            <div className="border-t-2 border-stone-200 w-full flex justify-center pt-3 group">
              <span className="text-xs uppercase tracking-widest font-grotesk-headline text-stone-400">
                View an Escapod
              </span>
              <span className="text-xs uppercase tracking-widest font-grotesk-headline ml-1 mr-4">
                TOPO2
              </span>
              <span className="text-xs uppercase tracking-widest font-grotesk-headline text-fire group-hover:animate-bounce2">
                &darr;
              </span>
            </div>
          </Button>
        </div>
        <div className="Map__ui__title w-full text-center mb-12 pt-12 px-6 md:px-8 lg:px-16 lg:hidden">
          <span className="grotesk-headline font-grotesk-headline text-2xl lg:text-3xl tracking-wide">
            Escapod Trailer Finder
          </span>
          <div className="Map__ui__title__sub flex items-center justify-center mt-2">
            {!results.length && (
              <div className="relative w-4 h-4 mr-2">
                <Image src={loader} layout="fill" alt="loading GIF" />
              </div>
            )}
            <span className="font-grotesk-news text-xs text-stone-400">
              {!results.length
                ? 'Loading options for viewing a trailer'
                : `${results.length} options for viewing a trailer`}
            </span>
          </div>
        </div>
        <div className="Map__ui__input px-6 lg:px-12 mb-12 relative animate-fade-in z-10">
          <RSelect
            id="LocationSelect"
            placeholder="Location / ZIP Code"
            name="location_zip"
            value={searchResultsOptions.find(option => option.value === userLocation?.id) || null}
            inputValue={search}
            options={searchResultsOptions}
            components={{ Option }}
            className="text-xs scroll-m-[200px]"
            isSearchable={true}
            isClearable={true}
            onInputChange={value => setSearch(value as string)}
            menuPortalTarget={
              typeof window !== 'undefined' ? document?.querySelector('body') : null
            }
            classNames={{
              menu: () => 'text-xs',
              control: () => '!border-stone-200',
              indicatorSeparator: () => '!bg-stone-200'
            }}
            onChange={option => {
              if (!option) return setUserLocation(null);
              const location = searchResults.find(
                result => result.id === (option as { value: string }).value
              );
              setUserLocation(location || null);
            }}
          />
          <AnimateHeight height={!!userLocation ? 'auto' : 0}>
            <div className="mt-6">
              <Button
                variant="fill"
                color="sand"
                onClick={() => setViewingFormActive(true)}
                className="w-full"
              >
                Request Trailer Viewing
              </Button>
            </div>
          </AnimateHeight>
          <div
            className={cx(
              'absolute pointer-events-none top-[3rem] left-0 w-full px-6 lg:px-12 transition-all',
              { 'opacity-0 translate-y-1': !locationTooltipActive }
            )}
          >
            <div className="absolute h-3 w-3 bg-sand top-0 -mt-1 left-[calc(50%-6px)] shadow-md rotate-45" />
            <div className="bg-sand p-3 rounded-lg shadow-md relative">
              <span className="font-grotesk-headline tracking-wide text-xs">
                Start typing your location to find trailers and experiences near you.
              </span>
            </div>
          </div>
        </div>
        <div className="Map__ui__filters px-6 lg:px-12 mb-8 animate-fade-in flex flex-wrap">
          {Object.keys(LocationFilters).map(key => (
            <Button
              key={`LocationFilter__button--${key}`}
              onClick={() => _toggleFilter(key)}
              variant={activeFilters.includes(key) ? 'fill' : 'stroke'}
              color={activeFilters.includes(key) ? 'black' : 'gray'}
              size="xs"
              className="mb-[10px] mr-[10px] text-[.625rem] tracking-widest"
            >
              {key in LocationFilters ? LocationFilters[key as keyof typeof LocationFilters] : ''}
            </Button>
          ))}
        </div>
        <div className="Map__ui__results-ui px-6 lg:px-12 mb-8 animate-fade-in">
          <span className="font-grotesk-news text-xs text-stone-400 tracking-wide">
            <span className="mr-1">{filteredResults.length}</span>
            <span className="mr-[6px]">options for viewing within</span>
            <span>
              <Select
                name="search_radius"
                ariaLabel="Search Radius"
                value={radius}
                onChange={value => setRadius(value as string)}
                className="inline !w-fit"
                inputClassName="inline !w-fit !text-xs"
                variant="secondary"
              >
                {Object.keys(Radii).map(radius => (
                  <option key={`SearchRadius__option--${radius}`} value={radius}>
                    {Radii[radius as keyof typeof Radii]}
                  </option>
                ))}
              </Select>
            </span>
          </span>
        </div>
        <div className="Map__ui__results px-6 lg:px-12 flex flex-col relative">
          {!results ||
            (!results.length && (
              <div
                key="LocationCard--Loader"
                id="LocationCard--Loader"
                className="Map__ui__result border-2 rounded-lg mb-4 relative transition-all pointer-events-none scroll-m-[240px] border-stone-200 animate-fade-in"
              >
                <div className="flex flex-col justify-center items-center relative px-4 py-8">
                  <span className="font-grotesk-headline mb-3 text-stone-500">
                    Loading Locations
                  </span>
                  <div className="relative w-4 h-4 ml-4">
                    <Image src={loader} layout="fill" alt="loading GIF" />
                  </div>
                </div>
              </div>
            ))}
          {!filteredResults.length && !!userLocation && (
            <div className="Map__ui__no-results flex flex-col animate-slide-up">
              <div className="rounded-lg px-4 py-8 border-stone-200 border-1 mb-8 shadow-md">
                <div className="mb-12 text-center">
                  <span className="uppercase font-grotesk-headline tracking-wide text-sm">
                    There aren&rsquo;t any locations or experiences in your area
                  </span>
                </div>
                <div className="mb-12">
                  <TextField
                    ariaLabel="name"
                    name="name"
                    label="Name"
                    className="mb-4"
                    value={name}
                    onChange={value => setName(value as string)}
                  />
                  <TextField
                    ariaLabel="email"
                    name="email"
                    label="Email"
                    value={email}
                    onChange={value => setEmail(value as string)}
                  />
                </div>
                <div className="flex flex-col text-center px-4 mb-4">
                  <span className="text-xs mb-3 font-grotesk-news">
                    <span>Get notified when we introduce new locations near </span>
                    <span className="font-grotesk-headline tracking-wide text-xs">
                      {userLocation.text}
                    </span>
                  </span>
                </div>
                <div className="flex items-center justify-center">
                  <div className="relative w-fit">
                    <Button
                      disabled={isSubmittingRequest}
                      variant="fill"
                      onClick={_submitFinderRequest}
                    >
                      Get Notified
                    </Button>
                    {isSubmittingRequest && (
                      <div className="animate-fade-in absolute top-[8px] right-[-24px] pointer-events-none">
                        <div className="relative w-4 h-4 ml-4">
                          <Image src={loader} layout="fill" alt="loading GIF" />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <AnimateHeight height={!!requestFormMessage ? 'auto' : 0}>
                  <div className="pt-4 flex justify-center">
                    <span className="text-xs font-grotesk-headline pt-4">{requestFormMessage}</span>
                  </div>
                </AnimateHeight>
              </div>
              <div className="flex flex-col justify-center items-center">
                <Button
                  className="px-4 mb-4"
                  variant="stroke"
                  href="https://calendly.com/escapodtrailers/virtual-trailer-viewing?month=2023-09"
                >
                  Schedule a Virtual Tour
                </Button>
                <Button className="px-4" variant="stroke" href="/contact-us">
                  Contact Us
                </Button>
              </div>
            </div>
          )}
          {idsByDistance.map(id => {
            const result = filteredResults.find(result => result.id === id);
            const distance = resultsDistanceFromUserLocation[id];

            if (!result) return;

            const hours = formatOpenHours({
              monday: { start: result.mondayStart || '', end: result.mondayEnd || '' },
              tuesday: { start: result.tuesdayStart || '', end: result.tuesdayEnd || '' },
              wednesday: { start: result.wednesdayStart || '', end: result.wednesdayEnd || '' },
              thursday: { start: result.thursdayStart || '', end: result.thursdayEnd || '' },
              friday: { start: result.fridayStart || '', end: result.fridayEnd || '' },
              saturday: { start: result.saturdayStart || '', end: result.saturdayEnd || '' },
              sunday: { start: result.sundayStart || '', end: result.sundayEnd || '' }
            });

            return (
              <div
                key={`LocationCard--${result.id}`}
                id={`LocationCard--${result.id}`}
                className={cx(
                  'Map__ui__result border-2 rounded-lg mb-4 relative transition-all cursor-pointer scroll-m-[240px] animate-slide-up',
                  {
                    'border-fire shadow-md': activeLocation === result.id,
                    'border-stone-200': activeLocation !== result.id
                  }
                )}
                onClick={() => setActiveLocation(result.id)}
              >
                <div
                  className={cx(
                    'absolute top-0 left-0 rounded-br-md pl-2 pr-[10px] pt-1 pb-[6px] z-20 transition-colors',
                    {
                      'bg-fire text-white': activeLocation === result.id,
                      'bg-stone-200': activeLocation !== result.id
                    }
                  )}
                >
                  <span className="font-grotesk-headline tracking-wide text-xs">
                    {result.tags.includes('hq')
                      ? 'Headquarters'
                      : result.tags.includes('dealer')
                      ? 'Dealership'
                      : result.tags.includes('rentals')
                      ? 'Rentals'
                      : result.tags.includes('glamping')
                      ? 'Glamping'
                      : result.tags.includes('event')
                      ? 'Event'
                      : 'Ambassador'}
                  </span>
                </div>
                <div
                  className="relative overflow-hidden rounded-t-lg"
                  style={{
                    paddingBottom: `${(9 / 16) * 100}%`
                  }}
                >
                  <Image
                    src={!!result.image ? result.image : DEFAULT_IMG}
                    layout="fill"
                    objectFit="cover"
                    alt={result.name}
                  />
                </div>
                <div className="Map__ui__result-info p-4 grid grid-cols-2 gap-6">
                  <div className="flex flex-col text-xs">
                    <span className="font-grotesk-headline mb-3 tracking-wide">
                      {result.category === 'Ambassador'
                        ? result.name.includes(' and ')
                          ? `Ambassadors ${result.name}`
                          : `Ambassador ${result.name}`
                        : result.name}
                    </span>
                    <span className="flex flex-col mb-3 tracking-wide">
                      {result.category === 'Event' && <span>{result.display}</span>}
                      {result.category !== 'Ambassador' && <span>{result.address}</span>}
                      <span>
                        {result.city}, {result.state} {result.zip}
                      </span>
                      {result.category !== 'Ambassador' && (
                        <a href={`tel:${result.phone}`} rel="noreferrer" target="_blank">
                          <span>{formatPhoneNumber(result.phone)}</span>
                        </a>
                      )}
                    </span>

                    {result.category === 'Business' && (
                      <div className="flex flex-col">
                        {Object.keys(hours).map(key => {
                          return (
                            <div
                              className="flex flex-col mb-3"
                              key={`LocationCard--${result.id}--hours-${key}`}
                            >
                              <span className="capitalize">{key}</span>
                              <span>{hours[key]}</span>
                            </div>
                          );
                        })}
                      </div>
                    )}

                    {userLocation && (
                      <span className="text-stone-500">{distance.toFixed(2)} miles away</span>
                    )}
                  </div>
                  <div className="flex flex-col items-end">
                    <a
                      className="mb-2"
                      target="_blank"
                      rel="noreferrer"
                      href={`http://maps.google.com/?q=${encodeURIComponent(
                        result.category === 'Ambassador'
                          ? `${result.city}, ${result.state}, ${result.zip}`
                          : `${result.address}, ${result.city}, ${result.state}, ${result.zip}`
                      )}`}
                    >
                      <Button variant="stroke" color="gray" size="sm" className="!text-[.625rem]">
                        Get Directions
                      </Button>
                    </a>
                    {!!result.schedule && (
                      <a target="_blank" rel="noreferrer" href={result.schedule}>
                        <Button variant="stroke" color="gray" size="sm" className="!text-[.625rem]">
                          Schedule Call
                        </Button>
                      </a>
                    )}
                    {(result.category === 'Ambassador' || result.category === 'Event') && (
                      <Button
                        variant="stroke"
                        color="gray"
                        size="sm"
                        className="!text-[.625rem]"
                        onClick={() => setViewingFormActive(true)}
                      >
                        Schedule Viewing
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div
        className={cx(
          'Map__viewing-form fixed top-0 left-0 right-0 bottom-0 w-full h-full flex justify-center items-center p-1 md:p-4 z-50',
          {
            hidden: !viewingFormActive
          }
        )}
      >
        <div className="Map__viewing-form__overlay fixed top-0 left-0 right-0 bottom-0 w-full h-full bg-brown bg-opacity-50 z-0 animate-fade-in" />
        <div className="Map__viewing-form__form bg-white rounded-md shadow-lg max-w-prose w-11/12 md:w-[61%] lg:w-1/3 max-h-[90vh] relative overflow-hidden pb-24 animate-slide-up">
          <div className="Map__viewing-form__header flex justify-between items-center px-5 pt-5 pb-3">
            <div className="Map__viewing-form__name">
              <span className="font-grotesk-headline text-sm xl:text-lg tracking-wide">
                TOPO2 Viewing Request
              </span>
            </div>
            <div className="Map__viewing-form__close h-[42px] relative">
              <Button
                variant="no-style"
                className="h-[42px]"
                onClick={() => setViewingFormActive(false)}
              >
                <Image
                  width="42"
                  height="42"
                  hideLoadingPlaceholder={true}
                  src={immersiveCloseIcon}
                  alt="Escapod Close Icon"
                />
              </Button>
            </div>
          </div>
          <div className="Map__viewing-form__body max-h-[90vh] overflow-auto px-5">
            <div className="mb-10">
              <span className="text-sm text-stone-500">
                Enter your information below and someone from the sales team with reach out with
                viewing options.
              </span>
            </div>
            <div className="mb-4">
              <span className="font-grotesk-news text-xs tracking-wide">
                <span className="mr-[6px]">I&lsquo;m interested in viewings within</span>
                <span>
                  <Select
                    name="search_radius"
                    ariaLabel="Search Radius"
                    value={desiredRadius}
                    onChange={value => setDesiredRadius(value as string)}
                    className="inline !w-fit"
                    inputClassName="inline !w-fit !text-xs"
                    variant="secondary"
                  >
                    {Object.keys(Radii).map(radius => (
                      <option key={`SearchRadius__option--${radius}`} value={radius}>
                        {Radii[radius as keyof typeof Radii]}
                      </option>
                    ))}
                  </Select>
                </span>
              </span>
            </div>
            <TextField
              className="mb-4"
              type="text"
              name="name"
              id="name_viewing_request_form"
              label="Name"
              ariaLabel="Name"
              value={name}
              onChange={value => setName(value as string)}
              showError={!!viewingFormErrors.name}
              error={viewingFormErrors.name}
            />
            <TextField
              className="mb-4"
              type="text"
              name="email"
              id="email_viewing_request_form"
              label="Email"
              ariaLabel="Email"
              value={email}
              onChange={value => setEmail(value as string)}
              showError={!!viewingFormErrors.email}
              error={viewingFormErrors.email}
            />
            <TextField
              className="mb-4"
              type="text"
              name="phone"
              id="phone_viewing_request_form"
              label="Phone"
              ariaLabel="Phone"
              value={phone}
              onChange={value => setPhone(value as string)}
              showError={!!viewingFormErrors.phone}
              error={viewingFormErrors.phone}
            />
            <TextField
              className="mb-4"
              type="text"
              name="zip"
              id="zip_viewing_request_form"
              label="ZIP / Postal Code"
              ariaLabel="ZIP / Postal Code"
              value={zip}
              onChange={value => setZip(value as string)}
              showError={!!viewingFormErrors.zip}
              error={viewingFormErrors.zip}
            />

            <div className="w-full mb-6 md:mb-8">
              <Checkbox
                name="newsletterOptIn"
                label="Subscribe to our Newsletter?"
                defaultChecked={true}
                variant="primary"
                reverse={true}
                value={newsletterOptIn}
                onChange={value => setNewsletterOptIn(value)}
              />
            </div>
          </div>
          {!!viewingFormErrors.form && (
            <div className="px-5 mb-6">
              <span className="text-fire text-xs">{viewingFormErrors.form}</span>
            </div>
          )}
          <div className="Map__viewing-form__totals absolute z-10 bottom-0 left-0 flex items-center justify-end w-full bg-white border-t-2 border-stone-200 p-5 shadow-above transition-opacity">
            <div
              className={cx('mr-3', {
                'opacity-0': !viewingFormSubmitting
              })}
            >
              <div className="h-5 w-5 relative">
                <Image src={loader} layout="fill" alt="Submit button loading GIF" />
              </div>
            </div>
            <Button
              onClick={submitViewingForm}
              variant="fill"
              color="sand"
              disabled={viewingFormSubmitting}
            >
              Submit Request
            </Button>
          </div>
        </div>
      </div>
    </BlockWrapper>
  );
};

export default Map;
