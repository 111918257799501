import React, { FC, useCallback, useEffect, useState } from 'react';
import { dampen } from 'utils/dampen';

import { Block, Image as TImage } from 'types';
import { BlockWrapper, Image } from 'components';

export type TThreeSixty = Block<
  'threeSixty',
  {
    title?: string;
    images: TImage[];
  }
>;

export const ThreeSixty: FC<TThreeSixty> = ({ title, images }) => {
  const steps = (images.length - 1) * 2;
  const [activeImage, setActiveImage] = useState(0);
  const [hasTouched, setHasTouched] = useState(false);
  const [scrollDistance, setScrollDistance] = useState(0);

  const firstImageWidth = images?.[0]?.metadata?.dimensions.width || 1;
  const firstImageHeight = images?.[0]?.metadata?.dimensions.height || 1;

  useEffect(() => {
    setScrollDistance(dampen(window.scrollY));
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const y = dampen(window.scrollY);

      if (y > scrollDistance && !hasTouched) {
        setActiveImage(Math.min(images.length - 1, activeImage + 1));
      }

      if (y < scrollDistance && !hasTouched) {
        setActiveImage(Math.max(0, activeImage - 1));
      }

      setScrollDistance(y);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [steps, hasTouched, activeImage, images.length, scrollDistance]);

  const onTouch = useCallback(() => {
    setHasTouched(true);
  }, []);

  return (
    <BlockWrapper className="ThreeSixty">
      <div className="ThreeSixty__title mb-4 text-center md:text-left">
        <h2 className="font-grotesk-headline-news text-2xl md:text-3xl">{title}</h2>
      </div>
      <div
        className="ThreeSixty__images relative"
        style={{
          paddingBottom: `${(firstImageHeight / firstImageWidth) * 100}%`
        }}
      >
        {images.map((image, i) => {
          const imageWidth = image.metadata?.dimensions.width || 1;
          const imageHeight = image.metadata?.dimensions.height || 1;

          return (
            <div
              className="ThreeSixty__image-wrapper absolute top-0 left-0 w-full"
              style={{
                paddingBottom: `${(imageHeight / imageWidth) * 100}%`,
                opacity: activeImage === i ? '1' : '0'
              }}
              key={`ThreeSixty__image__${image.src}`}
            >
              <Image
                src={image.src}
                layout="fill"
                alt="360 viewer image"
                hideLoadingPlaceholder={true}
              />
            </div>
          );
        })}
      </div>
      <div className="ThreeSixty__slider-wrapper mt-4 flex justify-center md:mx-28">
        <div className="ThreeSixty__slider-number ml-5">
          <span className="font-grotesk-headline">0°</span>
        </div>
        <input
          className="ThreeSixty__slider mx-10 w-full"
          type="range"
          aria-label="Grab and drag this range slider to spin the trailer 3D model"
          min={0}
          max={steps}
          value={activeImage * 2}
          onFocus={onTouch}
          onChange={e => setActiveImage(Math.floor(parseInt(e.currentTarget.value) / 2))}
        />
        <div className="ThreeSixty__slider-number mr-5">
          <span className="font-grotesk-headline">360°</span>
        </div>
      </div>
    </BlockWrapper>
  );
};

export default ThreeSixty;
