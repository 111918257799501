import React, { FC } from 'react';
import cx from 'classnames';

import { Block, LinkCard as TLinkCard } from 'types';
import { BlockWrapper, LinkCard } from 'components';

export type TRecirculationLinkCards = Block<
  'recirculationLinkCards',
  {
    title?: string;
    layout: 'long-form-2' | 'full-width-3';
    links: TLinkCard[];
  }
>;

export const RecirculationLinkCards: FC<TRecirculationLinkCards> = ({
  links,
  layout,
  title,
  meta
}) => {
  return (
    <BlockWrapper
      layout={layout === 'full-width-3' ? 'container' : 'prose'}
      className="RecirculationLinkCards"
    >
      {!!title && meta.isFirstBlockWithTitle && (
        <h1 className="TextBlock__title font-grotesk-headline-news text-2xl md:text-3xl mb-6 lg:mb-10 text-center">
          {title}
        </h1>
      )}
      {!!title && !meta.isFirstBlockWithTitle && (
        <h2 className="TextBlock__title font-grotesk-headline-news text-2xl md:text-3xl mb-6 lg:mb-10 text-center">
          {title}
        </h2>
      )}
      <div
        className={cx('RecirculationLinkCards__LinkCards-wrapper', {
          'lg:grid lg:grid-cols-2 lg:gap-4 xl:grid-cols-3': layout === 'full-width-3',
          'lg:grid lg:grid-cols-2 lg:gap-4': layout === 'long-form-2'
        })}
      >
        {links.map(link => (
          <div
            className={cx('RecirculationLinkCards__LinkCard', {
              'mb-4 last-of-type:!mb-0 lg:!mb-0': links.length > 1
            })}
            key={`RecirculationLinkCards__LinkCard__${link._key}`}
          >
            <LinkCard link={link} />
          </div>
        ))}
      </div>
    </BlockWrapper>
  );
};

export default RecirculationLinkCards;
