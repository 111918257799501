export const LocationFilters = {
  all: 'All',
  hq: 'HQ',
  rentals: 'Rentals',
  ambassador: 'Ambassadors',
  glamping: 'Glamping',
  dealer: 'Dealers',
  event: 'Events'
};

export const Radii = {
  '0': 'anywhere',
  '50': '50 miles',
  '100': '100 miles',
  '250': '250 miles',
  '500': '500 miles',
  '1000': '1000 miles'
};

export const LocationKeyLabels = {
  Category: 'category',
  Name: 'name',
  Display: 'display',
  Address: 'address',
  City: 'city',
  State: 'state',
  ZIP: 'zip',
  Phone: 'phone',
  Email: 'email',
  Website: 'website',
  'Image Link': 'image',
  'Schedule Link': 'schedule',
  'Virtual Tours': 'virtualTours',
  Tours: 'tours',
  Viewings: 'viewings',
  Rentals: 'rentals',
  Dealer: 'dealer',
  Glamping: 'glamping',
  Timezone: 'timezone',
  'Sunday - Start': 'sundayStart',
  'Sunday - End': 'sundayEnd',
  'Monday - Start': 'mondayStart',
  'Monday - End': 'mondayEnd',
  'Tuesday - Start': 'tuesdayStart',
  'Tuesday - End': 'tuesdayEnd',
  'Wednesday - Start': 'wednesdayStart',
  'Wednesday - End': 'wednesdayEnd',
  'Thursday - Start': 'thursdayStart',
  'Thursday - End': 'thursdayEnd',
  'Friday - Start': 'fridayStart',
  'Friday - End': 'fridayEnd',
  'Saturday - Start': 'saturdayStart',
  'Saturday - End': 'saturdayEnd',
  'Start Date': 'startDate',
  'End Date': 'endDate'
};
