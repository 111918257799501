import React, { FC, useCallback } from 'react';
import { scroller } from 'react-scroll';

import { Block } from 'types';
import { BlockWrapper, Button, TextField } from 'components';

import { useSearchContext } from 'contexts/SearchContext';

export type TAccordionListSearch = Block<
  'accordionListSearch',
  {
    title: string;
  }
>;

export const AccordionListSearch: FC<TAccordionListSearch> = props => {
  const { search, accordionBlocks, setSearch } = useSearchContext();

  const filteredBlocks = Object.values(accordionBlocks).filter(block => {
    return JSON.stringify(block).includes(search.toLowerCase());
  });

  const filteredItems = filteredBlocks.reduce((totalItems: number, block) => {
    // TO-DO Re-write this so that the type is meaningful
    const filteredItemsInBlock = block.items.filter((item: object) => {
      return JSON.stringify(item).includes(search.toLowerCase());
    });
    return totalItems + filteredItemsInBlock.length;
  }, 0);

  const onSectionButtonClick = useCallback((key: string) => {
    scroller.scrollTo(`AccordionList__${key}`, {
      smooth: true
    });
  }, []);

  return (
    <BlockWrapper className="AccordionListSearch">
      <div className="AccordionListSearch-title-wrapper mb-6 text-center md:text-left">
        {!!props.title && props.meta.isFirstBlockWithTitle && (
          <h1 className="AccordionListSearch-title font-grotesk-headline-news text-2xl md:text-3xl">
            {props.title}
          </h1>
        )}
        {!!props.title && !props.meta.isFirstBlockWithTitle && (
          <h2 className="AccordionListSearch-title font-grotesk-headline-news text-2xl md:text-3xl">
            {props.title}
          </h2>
        )}
      </div>
      <div className="AccordionListSearch-text-field-wrapper">
        <TextField
          id="AccordionListSearch-search"
          name="search"
          ariaLabel="Search the Support Pages"
          placeholder="Search the Support Pages"
          value={search}
          onChange={value => setSearch({ search: value as string, accordionBlocks, setSearch })}
        />
      </div>
      <div className="AccordionListSearch-stats-wrapper mt-1">
        <span className="AccordionListSearch-stats font-grotesk text-xs text-fire">{`There are ${filteredItems} answers below in ${filteredBlocks.length} categories`}</span>
      </div>
      <div className="AccordionListSearch-section-button-wrapper mt-8 flex flex-wrap">
        {filteredBlocks.map(block => (
          <div
            className="AccordionListSearch-section-button-inner-wrapper mb-3 mr-3"
            key={block._key}
          >
            <Button
              variant="fill"
              className="AccordionListSearch-section-button"
              onClick={() => onSectionButtonClick(block._key)}
            >
              {block.title}
            </Button>
          </div>
        ))}
      </div>
    </BlockWrapper>
  );
};

export default AccordionListSearch;
