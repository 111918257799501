import { useEffect, useState } from 'react';

const useScrollDistance = () => {
  const [scrollDistance, setScrollDistance] = useState(0);

  useEffect(() => {
    const updateDistance = () => {
      setScrollDistance(window.scrollY);
    };
    window.addEventListener('scroll', updateDistance);
    updateDistance();
    return () => window.removeEventListener('scroll', updateDistance);
  }, []);

  return scrollDistance;
};

export default useScrollDistance;
