import React, { FC, FormEvent } from 'react';

// TO-DO: Add FormWrapper with integrated validation lib for error checking
type Props = {
  id: string;
  name: string;
  className?: string;
  children: React.ReactNode;
  onSubmit?: (event: FormEvent<HTMLFormElement>) => void;
};

const FormWrapper: FC<Props> = props => {
  const { id, name, children, onSubmit, className = '' } = props;

  return (
    <form id={id} name={name} className={className} onSubmit={onSubmit}>
      {children}
    </form>
  );
};

export default FormWrapper;
