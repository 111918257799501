import React, { FC, useState, useEffect, useMemo, useCallback, useRef } from 'react';
import cx from 'classnames';
import { animateScroll, Element, scroller } from 'react-scroll';
import { useRouter } from 'next/router';
import AnimateHeight from 'react-animate-height';
import Slider from 'react-slick';
import hashify from 'object-hash';

import iconCheck from '../../public/images/icon-check-v3.svg';
import iconPlus from '../../public/images/icon-plus-v3.svg';
import immersiveCloseIcon from 'public/images/escapod-immersive-close-icon.svg';

import { Button, BlockWrapper, PortableText, Image } from 'components';
import { useBuilderContext, useBuilderMutate } from 'contexts/BuilderContext';
import { useNavContext } from 'contexts/NavContext';
import formatMoney from 'utils/formatMoney';
import useScrollDistance from 'hooks/useScrollDistance';

import { ProductLineModification, ProductLinePackage, Topo2Trim } from 'escapod';
import { Block, Image as TImage } from 'types';
import { TImageText } from './ImageText';
import { TProductCards } from './ProductCards';
import Link from 'next/link';
import priceFromRules from 'utils/priceFromRules';

export type TBuilder = Block<
  'builder',
  {
    title: string;
    trailer: string;
    image?: TImage;
    confirmationContent: (TImageText | TProductCards)[];
    submissionDisabled: boolean;
    collapseBaseFeatures: boolean;
    collapseModifications: boolean;
  }
>;

export const Builder: FC<TBuilder> = ({
  title,
  trailer,
  image,
  // submissionDisabled,
  // collapseBaseFeatures,
  // collapseModifications,
  confirmationContent,
  meta
}) => {
  const scrollDistance = useScrollDistance();
  const navContext = useNavContext();
  const sliderRef = useRef<null | Slider>(null);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [showFinancingCta, setShowFinancingCta] = useState(false);
  const [toggleRef, setToggleRef] = useState(0);
  const [showModifications, setShowModifications] = useState(false);
  const [infoOverlay, setInfoOverlay] = useState<
    ProductLineModification | ProductLinePackage | null
  >(null);
  const [infoOverlayImage, setInfoOverlayImage] = useState<{
    _key: string;
    alt: string;
    src: string;
    metadata?: {
      dimensions: {
        aspectRatio: number;
        width: number;
        height: number;
      };
    };
  } | null>(null);

  const [showStandardFeatureOverlay, setShowStandardFeatureOverlay] = useState(false);
  const [infoOverlayIntent, setInfoOverlayIntent] = useState<'add' | 'remove'>('add');

  const { selectedTrailer, selectedTrim, selectedVariantOptions, trailers, total, modifications } =
    useBuilderContext();
  const modificationsBySku = useMemo(() => modifications.map(mod => mod.sku), [modifications]);
  const mutate = useBuilderMutate();
  const [stickyDelta, setStickyDelta] = useState(0);
  const handleResize = useCallback(
    node => {
      if (!node) return;
      const viewportHeight =
        typeof window !== 'undefined' ? (window as { innerHeight: number }).innerHeight : 0;
      const uiHeight = node?.clientHeight;
      setStickyDelta(uiHeight < viewportHeight ? 0 : Math.abs(viewportHeight - uiHeight));
    },
    [selectedTrim]
  );

  const closeStandardFeatureOverlay = useCallback(() => setShowStandardFeatureOverlay(false), []);

  const router = useRouter();

  useEffect(() => {
    if (mutate && !!trailers.length && (!selectedTrailer || trailer !== selectedTrailer._id)) {
      mutate.selectTrailer(trailer, confirmationContent);
    }
  }, [trailer, trailers, mutate, selectedTrailer, modifications, confirmationContent]);

  useEffect(() => {
    setInfoOverlayImage(infoOverlay?.images?.[0] || null);
    if (!!infoOverlay || showStandardFeatureOverlay) {
      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = '15px';
    }
    if (!infoOverlay && !showStandardFeatureOverlay) {
      document.body.style.overflow = 'unset';
      document.body.style.paddingRight = '0px';
    }
  }, [infoOverlay, showStandardFeatureOverlay]);
  useEffect(() => {
    const navTitle = `${new Date().getFullYear()} Escapod TOPO2`;
    const subtitle = `Current Estimated Delivery: ${selectedTrailer?.leadTime}`;

    if (navContext.immersive.title === navTitle && navContext.immersive.subtitle === subtitle)
      return;

    navContext.setContext({
      ...navContext,
      theme: 'black',
      immersive: {
        title: navTitle,
        subtitle
      }
    });
  }, [selectedTrailer, navContext, title]);

  useEffect(() => {
    const showCta = scrollDistance > 200;
    if (showCta !== showFinancingCta) setShowFinancingCta(showCta);
  }, [scrollDistance, showFinancingCta]);

  const nudge = useCallback(() => void animateScroll.scrollMore(240), []);
  const add = useCallback(
    (mod: ProductLineModification | ProductLinePackage, removeOthers?: string[]) => {
      if (!mutate) return;

      if (
        mutate.unmetDependenciesForModification(mod).length ||
        mutate.conflictsForModification(mod).length
      ) {
        setInfoOverlayIntent('add');
        return setInfoOverlay(mod);
      }

      return mutate.addModification(mod, removeOthers);
    },
    [mutate]
  );

  const addFromInfoOverlay = useCallback(() => {
    if (!mutate || !infoOverlay) return;

    const modsToAdd = [infoOverlay, ...mutate.unmetDependenciesForModification(infoOverlay)];
    const modsToRemove = mutate.conflictsForModification(infoOverlay);

    modsToRemove.forEach(mutate.removeModification);
    modsToAdd.forEach(mod => {
      const group = selectedTrailer?.modificationGroups.find(_group => {
        return _group.modifications.find(_mod => mod.sku === _mod.sku && mod._key === _mod._key);
      });
      const groupSkus = !!group
        ? group.modifications
            .filter(_mod => _mod.sku !== infoOverlay.sku && _mod.sku !== mod.sku)
            .map(_mod => _mod.sku)
        : [];
      mutate.addModification(mod, groupSkus);
    });
    setInfoOverlay(null);
  }, [mutate, infoOverlay, selectedTrailer]);

  const removeFromInfoOverlay = useCallback(() => {
    if (!mutate || !infoOverlay) return;

    const modsToRemove = [infoOverlay, ...mutate.selectedDependentsForModification(infoOverlay)];

    modsToRemove.forEach(mutate.removeModification);
    setInfoOverlay(null);
  }, [mutate, infoOverlay]);

  const remove = useCallback(
    (mod: ProductLineModification) => {
      if (!mutate) return;

      if (mutate.selectedDependentsForModification(mod).length) {
        setInfoOverlayIntent('remove');
        return setInfoOverlay(mod);
      }

      return mutate.removeModification(mod);
    },
    [mutate]
  );

  const closeInfoOverlay = useCallback(() => setInfoOverlay(null), []);

  const images = useMemo(() => {
    return (
      selectedTrailer?.images?.filter(image => {
        const unmatchedVariants = [selectedTrim, ...Object.values(selectedVariantOptions)].reduce(
          (unmatchedVariants, option) => {
            const firstMatch = unmatchedVariants.findIndex(unmatched => option === unmatched);

            return unmatchedVariants.filter((_, i) => i !== firstMatch);
          },
          image.values
        );

        return unmatchedVariants.length === 0;
      }) || []
    );
  }, [selectedVariantOptions, selectedTrailer, selectedTrim]);

  const variantsRef = useRef<string>('');
  useEffect(() => {
    const hash = hashify(selectedVariantOptions);
    if (hash === variantsRef.current) return;

    setTimeout(() => sliderRef.current?.slickGoTo(0), 0);
    variantsRef.current = hash;
  }, [sliderRef, selectedVariantOptions, variantsRef]);

  const flattenedMods = useMemo(
    () => selectedTrailer?.modificationGroups.flatMap(_g => _g.modifications),
    [selectedTrailer]
  );

  const featuredModGroups = useMemo(
    () => selectedTrailer?.modificationGroups.filter(group => !!group.isFeatured) || [],
    [selectedTrailer]
  );

  const featuredMods = useMemo(
    () =>
      selectedTrailer?.modificationGroups.reduce<ProductLineModification[]>((features, group) => {
        const featuredGroupMods = group.modifications.filter(mod => mod.isFeatured);
        return [...features, ...featuredGroupMods];
      }, []) || [],
    [selectedTrailer]
  );

  const _continue = useCallback(() => {
    router.push('/order-now/topo-2/info'); // TO-DO: Add 'slug' field to Product in PODS so we can use it here to dynamically route the product
  }, [router]);

  const trimPackages = useMemo(
    () =>
      selectedTrailer?.packages.filter(pkg => !!selectedTrim && pkg.trims.includes(selectedTrim)) ||
      [],
    [selectedTrim, selectedTrailer]
  );

  if (!selectedTrailer) return null;

  return (
    <BlockWrapper className="Builder Builder--V2 !md:container !mb-0" layout="full">
      <div className="Builder__inner flex relative">
        <div className="Builder__images hidden md:flex flex-col md:w-[calc(100%-340px)] lg:w-[calc(100%-452px)] xl:w-[calc(100%-472px)]">
          {!selectedTrim && !images.length && (
            <div className="w-full h-screen sticky top-0">
              <Image
                alt={image?.alt || ''}
                src={image?.src || ''}
                layout="fill"
                objectFit="cover"
                quality={90}
              />
            </div>
          )}
          {images.map(image => {
            const imageWidth = image?.metadata?.dimensions.width;
            const imageHeight = image?.metadata?.dimensions.height;
            return (
              <div
                key={`Builder__image--${image.src}${image.values.join('-')}`}
                className="Builder__images__image-wrapper relative w-full mb-3 last-of-type:mb-0"
                style={{
                  paddingBottom: `${((imageHeight || 0) / (imageWidth || 0)) * 100}%`
                }}
              >
                <Image
                  src={image?.src || ''}
                  layout="fill"
                  quality={90}
                  alt={`${selectedTrailer.name} in ${Object.values(selectedVariantOptions).join(
                    ', '
                  )}`}
                />
              </div>
            );
          })}
        </div>
        <div className="Builder__ui w-full md:w-[340px] lg:w-[452px] xl:w-[472px] pb-12 relative">
          <div
            ref={handleResize}
            key={`Builder__ui-ref-${toggleRef}`}
            className="Builder__ui__inner sticky transition-all !duration-[1s]"
            style={{ top: `-${stickyDelta + 32}px` }}
          >
            <div className="Builder__title w-full text-center mb-12 px-6 md:px-8 lg:px-16 lg:hidden">
              {meta.isFirstBlockWithTitle ? (
                <h1 className="grotesk-headline font-grotesk-headline text-2xl lg:text-3xl tracking-wide">
                  {new Date().getFullYear()} {title}
                </h1>
              ) : (
                <h2 className="grotesk-headline font-grotesk-headline text-2xl lg:text-3xl tracking-wide">
                  {new Date().getFullYear()} {title}
                </h2>
              )}
              <div className="Builder__title__estimated-delivery">
                <span className="font-grotesk-news text-xs text-stone-400">
                  Current Estimated Delivery: {selectedTrailer.leadTime}
                </span>
              </div>
            </div>
            <div className="hidden lg:flex px-20 w-full mb-12">
              <Button variant="no-style" onClick={nudge} className="w-full">
                <div className="border-t-2 border-stone-200 w-full flex justify-center pt-3 group">
                  <span className="text-xs uppercase tracking-widest font-grotesk-headline text-stone-400">
                    Choose Your
                  </span>
                  <span className="text-xs uppercase tracking-widest font-grotesk-headline ml-1 mr-4">
                    TOPO2 Trim
                  </span>
                  <span className="text-xs uppercase tracking-widest font-grotesk-headline text-fire group-hover:animate-bounce2">
                    &darr;
                  </span>
                </div>
              </Button>
            </div>
            <div className="Builder__ui__trims w-full px-6 lg:px-12 mb-12">
              {!!selectedTrailer.trims &&
                selectedTrailer.trims.map(trim => {
                  const trimWidth = trim.image?.metadata?.dimensions.width;
                  const trimHeight = trim.image?.metadata?.dimensions.height;
                  const trimIsSelected = trim.name === selectedTrim;

                  return (
                    <div key={`Builder__trim--${trim._key}`} className="Builder__trim w-full mb-10">
                      <div
                        className="Builder__trim__image w-full mb-6 relative"
                        style={{
                          paddingBottom: `${((trimHeight || 0) / (trimWidth || 0)) * 100}%`
                        }}
                      >
                        <Image src={trim.image.src} alt={trim.image.alt} layout="fill" />
                      </div>
                      <div className="px-8">
                        <Button
                          variant="no-style"
                          onClick={() => {
                            scroller.scrollTo('Builder__ui__colors', { smooth: true });
                            mutate?.selectTrim(trim.name as Topo2Trim);
                          }}
                          className={cx(
                            'border-2 border-fire hover:bg-fire hover:text-white w-full px-6 py-4 rounded-full text-xs tracking-wide font-grotesk-headline uppercase group mb-6',
                            {
                              'bg-fire text-white': trimIsSelected
                            }
                          )}
                        >
                          <div className="flex justify-between w-full">
                            <span>
                              <span className="mr-1">{selectedTrailer.name}</span>
                              <span
                                className={cx(
                                  'text-fire group-hover:text-white transition-colors',
                                  {
                                    '!text-white': trimIsSelected
                                  }
                                )}
                              >
                                {trim.name}
                              </span>
                            </span>
                            <span>${formatMoney(trim.price)}</span>
                          </div>
                        </Button>
                        {!!trim.description && (
                          <div className="border-t-1 border-stone-200 pt-4">
                            <p className="text-stone-400 text-[.625rem]">{trim.description}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="Builder__ui__carousel relative w-full md:hidden mb-10">
              <Slider
                ref={sliderRef}
                arrows={false}
                dots={false}
                infinite={true}
                speed={500}
                beforeChange={(_, next) => setCarouselIndex(next)}
              >
                {images.map(image => {
                  const imageWidth = image?.metadata?.dimensions.width;
                  const imageHeight = image?.metadata?.dimensions.height;

                  return (
                    <div key={`Builder__carousel-image--${image.src}${image.values.join('-')}`}>
                      <div
                        className="Builder__images__image-wrapper relative w-full mb-3 last-of-type:mb-0"
                        style={{
                          paddingBottom: `${((imageHeight || 0) / (imageWidth || 0)) * 100}%`
                        }}
                      >
                        <Image
                          src={image?.src || ''}
                          layout="fill"
                          quality={90}
                          alt={`${selectedTrailer.name} in ${Object.values(
                            selectedVariantOptions
                          ).join(', ')}`}
                        />
                      </div>
                    </div>
                  );
                })}
              </Slider>
              <div className="Builder__ui__carousel-ui px-6 mt-2">
                <div className="w-full flex justify-between items-center">
                  <div className="Builder__ui__carousel-dots flex">
                    {images.map((image, i) => (
                      <Button
                        key={`Builder__carousel-dot--${image.src}`}
                        variant="no-style"
                        onClick={() => sliderRef?.current?.slickGoTo(i)}
                      >
                        <div
                          className={cx('h-2 w-2 rounded-full border-1 border-stone-400 mr-[6px]', {
                            'bg-stone-400': carouselIndex === i
                          })}
                        />
                      </Button>
                    ))}
                  </div>
                  <Button
                    variant="no-style"
                    onClick={sliderRef?.current?.slickNext}
                    className={cx('flex', { 'pointer-events-none opacity-0': !images.length })}
                  >
                    <span className="font-grotesk-news text-[.625rem] mr-1 text-stone-400">
                      Swipe
                    </span>
                    <span className="font-grotesk-news text-[.625rem] animate-bounce-right text-stone-400">
                      &rarr;
                    </span>
                  </Button>
                </div>
              </div>
            </div>
            <div className="hidden lg:flex px-20 w-full mb-12">
              <Button variant="no-style" onClick={nudge} className="w-full">
                <div className="border-t-2 border-stone-200 w-full flex justify-center pt-3 group">
                  <span className="text-xs uppercase tracking-widest font-grotesk-headline text-stone-400">
                    Configure Your
                  </span>
                  <span className="text-xs uppercase tracking-widest font-grotesk-headline ml-1 mr-4">
                    {selectedTrailer.name}
                  </span>
                  <span className="text-xs uppercase tracking-widest font-grotesk-headline text-fire group-hover:animate-bounce2">
                    &darr;
                  </span>
                </div>
              </Button>
            </div>
            <Element name="Builder__ui__colors">
              <div className="Builder__color-picker flex flex-col w-full mb-12 px-6 md:px-8 lg:px-12 xl:px-16">
                <div className="Builder__color-picker__groups">
                  {(selectedTrailer.variantGroups_2024 || selectedTrailer.variantGroups)
                    .filter(group => !group.exclusiveVariantGroup)
                    .map(group => {
                      const selectedVariant = selectedTrailer.variantGroups_2024
                        ?.find(_group => group.name === _group.name)
                        ?.variants.find(
                          variant => variant.name === selectedVariantOptions?.[group.name]
                        );
                      const selectionPrice = priceFromRules(
                        selectedVariant?.priceRules || [],
                        { trim: selectedTrim || '' },
                        0
                      );

                      return (
                        <div
                          key={`Builder__color-picker__group--${group._key}`}
                          className="Builder__color-picker__group mb-12"
                        >
                          <div className="w-full text-center mb-4">
                            <span className="uppercase font-grotesk-headline tracking-wide">
                              <span>{group.name}</span>
                              <span
                                className={cx('ml-1 text-stone-400', {
                                  hidden: group.name.includes('Door')
                                })}
                              >
                                {!!selectionPrice ? `- $${selectionPrice}` : '- Standard'}
                              </span>
                            </span>
                          </div>
                          <div className="Builder__color-picker__group__icon flex justify-center w-full">
                            {group.variants.map(variant => {
                              if (
                                group.name.includes('Door') &&
                                variant.name !== selectedVariantOptions?.['Body Color'] &&
                                variant.name !== 'Obsidian'
                              )
                                return null;

                              return (
                                <div
                                  className="Builder__color-picker__variant-groups__variant-group__icons__icon-wrapper"
                                  key={`color-picker__icon__${variant._key}`}
                                >
                                  <Button
                                    className="ml-3 relative"
                                    variant="no-style"
                                    onClick={() => {
                                      if (
                                        group.name.includes('Body') &&
                                        variant.name !== selectedVariantOptions?.['Door Color'] &&
                                        selectedVariantOptions?.['Door Color'] !== 'Obsidian'
                                      ) {
                                        return mutate?.selectVariantOptions({
                                          [group.name]: variant.name,
                                          'Door Color': variant.name
                                        });
                                      }

                                      return mutate?.selectVariantOptions({
                                        [group.name]: variant.name
                                      });
                                    }}
                                  >
                                    <div
                                      className={cx(
                                        'Builder__color-picker__variant-groups__variant-group__icons__icon-image w-8 h-8 relative rounded-circle overflow-hidden',
                                        {
                                          'shadow-md':
                                            selectedVariantOptions?.[group.name] === variant.name
                                        }
                                      )}
                                    >
                                      <Image
                                        hideLoadingPlaceholder={true}
                                        src={variant.icon}
                                        alt={`${variant.name} color selector`}
                                        layout="fill"
                                      />
                                    </div>
                                    <div
                                      className={cx(
                                        'w-3 h-3 bg-white absolute top-[10px] left-[10.25px] rounded-circle transition-opacity',
                                        {
                                          'opacity-0':
                                            selectedVariantOptions?.[group.name] !== variant.name,
                                          '!bg-stone-300':
                                            selectedVariantOptions?.[
                                              group.name
                                            ]?.toLocaleLowerCase() === 'powder'
                                        }
                                      )}
                                    />
                                  </Button>
                                </div>
                              );
                            })}
                          </div>
                          <div className="Bulder__color-picker__variant-groups__variant-group__info mt-4">
                            <div className="flex justify-center">
                              <span className="font-grotesk-sub-headline-news uppercase tracking-wide text-xs ">
                                <span className="text-charcoal">
                                  {selectedVariantOptions?.[group.name]}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </Element>
            {!!trimPackages.length && (
              <div className="px-6 md:px-8 lg:px-12 xl:px-16 mb-12">
                <div className="w-full text-center mb-6">
                  <span className="uppercase font-grotesk-headline tracking-wide">Packages</span>
                </div>
                {trimPackages.map(pkg => {
                  const pkgImageWidth = pkg.images?.[0]?.metadata?.dimensions.width;
                  const pkgImageHeight = pkg.images?.[0]?.metadata?.dimensions.height;
                  const isSelected = modificationsBySku.includes(pkg.sku);

                  return (
                    <div
                      key={`Builder__package--${pkg._key}`}
                      className="Builder__ui__package mb-8"
                    >
                      <div
                        className={cx('w-full border-2 relative mb-4 rounded-lg', {
                          'border-stone-200': !isSelected,
                          'border-fire': isSelected
                        })}
                        style={{
                          paddingBottom: `${((pkgImageHeight || 0) / (pkgImageWidth || 0)) * 100}%`
                        }}
                        onClick={isSelected ? () => remove(pkg) : () => add(pkg)}
                      >
                        <Image
                          src={pkg.images?.[0]?.src || ''}
                          alt={pkg.images?.[0]?.alt || ''}
                          layout="fill"
                        />
                        <span
                          className={cx(
                            'absolute px-3 py-3 text-xs rounded-tr-lg bottom-0 left-0 font-grotesk-headline',
                            {
                              'bg-fire text-white': isSelected,
                              'bg-stone-200': !isSelected
                            }
                          )}
                        >
                          ${formatMoney(pkg.price)}
                        </span>
                      </div>
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <Button
                            variant="no-style"
                            onClick={isSelected ? () => remove(pkg) : () => add(pkg)}
                            className={cx(
                              'mr-3 relative h-8 w-8 rounded-circle border-2 border-transparent box-content flex flex-shrink-0',
                              { 'hover:border-sand': !isSelected }
                            )}
                          >
                            {isSelected ? (
                              <Image
                                width="32"
                                height="32"
                                hideLoadingPlaceholder={true}
                                src={iconCheck}
                                alt="Checkmark Icon (item added)"
                              />
                            ) : (
                              <Image
                                width="32"
                                height="32"
                                hideLoadingPlaceholder={true}
                                src={iconPlus}
                                alt="Add Icon (add item)"
                              />
                            )}
                          </Button>
                          <span className="text-xs font-grotesk-news tracking-wide">
                            {pkg.name}
                          </span>
                        </div>
                        <Button variant="no-style" onClick={() => setInfoOverlay(pkg)}>
                          <span className="mb-2 px-1 pb-[2px] uppercase text-xs font-grotesk-news transition-colors border-b-2 border-stone-200 hover:border-fire">
                            Info
                          </span>
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            <div className="Builder__featured-mod-groups px-6 md:px-8 lg:px-12 xl:px-16">
              {featuredModGroups.map(group => {
                if (!!selectedTrim && group.exclusions?.includes(selectedTrim)) return null;

                return (
                  <div
                    key={`Builder__fmg__group--${group._key}`}
                    className="Builder__featured-mod-groups__group w-full mb-6"
                  >
                    <div className="text-center w-full mb-6">
                      <span className="uppercase font-grotesk-headline tracking-wide">
                        {group.name}
                      </span>
                    </div>
                    <div className="Builder__feature-mod-groups__group-items w-full grid grid-cols-2 gap-4">
                      {group.modifications.map(mod => {
                        if (!!mod.isHidden) return null;
                        if (!!selectedTrim && mod.exclusions?.includes(selectedTrim)) return null;
                        const price = mod.priceRules
                          ? priceFromRules(
                              mod.priceRules || [],
                              { trim: selectedTrim || '' },
                              mod.price
                            )
                          : mod.price;
                        const isStandard = mod.isStandard && price === 0;
                        const isSelected =
                          modificationsBySku.includes(mod.sku) ||
                          (isStandard &&
                            !group.modifications.some(_mod => {
                              return !!_mod.sku && modificationsBySku.includes(_mod.sku);
                            }));

                        const onClick = () => {
                          const skusToRemove = group.modifications
                            .map(_mod => _mod.sku)
                            .filter(sku => !!sku);
                          const deps = modifications
                            .flatMap(_mod => ('dependencies' in _mod ? _mod.dependencies : []))
                            .map(dep => flattenedMods?.find(_mod => _mod.sku === dep));
                          const requiredDep = deps?.find(
                            dep => !!dep && skusToRemove.includes(dep.sku)
                          );

                          if (
                            !!requiredDep &&
                            mutate?.selectedDependentsForModification(requiredDep).length
                          ) {
                            setInfoOverlayIntent('remove');
                            return setInfoOverlay(requiredDep);
                          }
                          return add(mod, skusToRemove);
                        };

                        return (
                          <div
                            key={`Builder__fmg__item--${mod._key}`}
                            className="Builder__feature-mod-groups__group-item flex flex-col mb-4"
                          >
                            <Button variant="no-style" onClick={onClick} className="w-full">
                              <div
                                className={cx(
                                  'Builder__feature-mod-groups__item-thumbnail relative mb-4 border-2 rounded-md transition-colors w-full',
                                  { 'border-stone-200': !isSelected, 'border-fire': isSelected }
                                )}
                                style={{ paddingBottom: '100%' }}
                              >
                                <Image
                                  src={mod?.thumbnail?.src || ''}
                                  sizes="(max-width: 1200px) 50vw, 15vw"
                                  layout="fill"
                                  objectFit="cover"
                                  quality={80}
                                  alt={`${group.name} - ${mod.name}`}
                                />
                                <div
                                  className={cx(
                                    'Builder__feature-mod-groups__item-thumbnail__price absolute bottom-0 left-0 py-1 px-2 rounded-tr-md transition-colors',
                                    {
                                      'bg-stone-200 text-charcoal': !isSelected,
                                      'bg-fire text-white': isSelected
                                    }
                                  )}
                                >
                                  <span className="font-grotesk-headline tracking-wide text-xs">
                                    {isStandard ? 'Standard' : `$${price}`}
                                  </span>
                                </div>
                              </div>
                            </Button>
                            <div className="flex flex-col text-center px-2">
                              <span className="mb-2 text-xs">{mod.name}</span>
                              <Button variant="no-style" onClick={() => setInfoOverlay(mod)}>
                                <span className="mb-2 px-1 pb-[2px] uppercase text-xs font-grotesk-news transition-colors border-b-2 border-stone-200 hover:border-fire">
                                  Info
                                </span>
                              </Button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="Builder__standard-features-button mb-10 px-6 md:px-8 lg:px-12 xl:px-16">
              <Button
                size="lg"
                color="gray"
                variant="stroke"
                className="w-full"
                onClick={() => setShowStandardFeatureOverlay(true)}
              >
                View All Standard Features
              </Button>
            </div>
            <div className="Builder__featured-modifications mb-10 px-6 md:px-8 lg:px-12 xl:px-16">
              <div className="text-center mb-6">
                <span className="font-grotesk-headline tracking-wide uppercase text-sm">
                  Featured Add-Ons
                </span>
              </div>
              {featuredMods.map(mod => {
                const isSelected = modificationsBySku.includes(mod.sku);
                if (!!mod.isHidden) return null;
                if (!!selectedTrim && mod.exclusions?.includes(selectedTrim)) return null;

                return (
                  <div
                    key={`Builder__fm__mod--${mod._key}`}
                    className="Builder__featured-modifications__mod flex items-center justify-between mb-3"
                  >
                    <div className="flex items-center">
                      <Button
                        variant="no-style"
                        className={cx(
                          'mr-3 relative h-8 w-8 rounded-circle border-2 border-transparent box-content flex flex-shrink-0',
                          { 'hover:border-sand': !isSelected }
                        )}
                        onClick={isSelected ? () => remove(mod) : () => add(mod)}
                      >
                        {isSelected ? (
                          <Image
                            width="32"
                            height="32"
                            hideLoadingPlaceholder={true}
                            src={iconCheck}
                            alt="Checkmark Icon (item added)"
                          />
                        ) : (
                          <Image
                            width="32"
                            height="32"
                            hideLoadingPlaceholder={true}
                            src={iconPlus}
                            alt="Add Icon (add item)"
                          />
                        )}
                      </Button>
                      <span className="font-grotesk-news text-xs pr-2">{mod.name}</span>
                    </div>
                    <div className="flex items-center flex-shrink-0">
                      <Button
                        variant="no-style"
                        className="mr-3"
                        onClick={() => setInfoOverlay(mod)}
                      >
                        <span className="px-1 pb-[2px] uppercase text-xs font-grotesk-news transition-colors border-b-2 border-stone-200 hover:border-fire">
                          Info
                        </span>
                      </Button>
                      <span className="font-grotesk-news w-12 text-right text-xs">
                        ${mod.price}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="Builder__toggle-modifications-visibility text-center mb-10 px-6 md:px-8 lg:px-12 xl:px-16 flex justify-center">
              <Button variant="no-style" onClick={() => setShowModifications(!showModifications)}>
                <span className="uppercase text-sm tracking-wide transition-colors border-b-2 border-stone-200 hover:border-fire pb-1">
                  View Full List of Add-Ons <span className="ml-1">&darr;</span>
                </span>
              </Button>
            </div>
            <div className="Builder__modifications-groups px-6 md:px-8 lg:px-12 xl:px-16">
              <AnimateHeight
                height={showModifications ? 'auto' : 0}
                onAnimationEnd={() => setToggleRef(toggleRef + 1)}
              >
                {selectedTrailer.modificationGroups.map(group => {
                  if (group.isFeatured) return null;
                  if (!!selectedTrim && group.exclusions?.includes(selectedTrim)) return null;

                  return (
                    <div
                      key={`Builder__mg__group--${group._key}`}
                      className="Builder__modification-group mb-10"
                    >
                      <div className="text-center mb-6">
                        <span className="font-grotesk-headline tracking-wide uppercase text-sm">
                          {group.name}
                        </span>
                      </div>
                      <div className="Builder__modification-group__mods">
                        {group.modifications.map(mod => {
                          if (!!mod.isHidden) return null;
                          if (!!selectedTrim && mod.exclusions?.includes(selectedTrim)) return null;
                          const isSelected = modificationsBySku.includes(mod.sku);

                          return (
                            <div
                              key={`Builder__mg__mod--${mod._key}`}
                              className="Builder__featured-modifications__mod flex items-center justify-between mb-3"
                            >
                              <div className="flex items-center">
                                <Button
                                  variant="no-style"
                                  className={cx(
                                    'mr-3 relative h-8 w-8 rounded-circle border-2 border-transparent box-content flex flex-shrink-0',
                                    { 'hover:border-sand': !isSelected }
                                  )}
                                  onClick={isSelected ? () => remove(mod) : () => add(mod)}
                                >
                                  {isSelected ? (
                                    <Image
                                      width="32"
                                      height="32"
                                      hideLoadingPlaceholder={true}
                                      src={iconCheck}
                                      alt="Checkmark Icon (item added)"
                                    />
                                  ) : (
                                    <Image
                                      width="32"
                                      height="32"
                                      hideLoadingPlaceholder={true}
                                      src={iconPlus}
                                      alt="Add Icon (add item)"
                                    />
                                  )}
                                </Button>
                                <span className="font-grotesk-news text-xs pr-2">{mod.name}</span>
                              </div>
                              <div className="flex items-center flex-shrink-0">
                                <Button
                                  variant="no-style"
                                  className="mr-1"
                                  onClick={() => setInfoOverlay(mod)}
                                >
                                  <span className="mb-2 px-1 pb-1 uppercase text-xs font-grotesk-news transition-colors border-b-2 border-stone-200 hover:border-fire">
                                    Info
                                  </span>
                                </Button>
                                <span className="font-grotesk-news text-xs w-12 text-right">
                                  ${mod.price}
                                </span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </AnimateHeight>
            </div>
            <div className="px-6 md:px-8 lg:px-12 xl:px-16">
              <div className="Builder__summary mb-10 py-10 border-y-2 border-stone-200">
                <div className="Builder__summary__totals text-center flex flex-col mb-10">
                  <span className="font-grotesk-headline tracking-wide text-2xl mb-2">
                    Total: ${formatMoney(total)}
                  </span>
                  <span className="font-grotesk-news text-stone-400 text-xs">
                    Pricing before taxes & fees
                  </span>
                </div>
                <div className="w-full">
                  <Button
                    variant="fill"
                    size="lg"
                    color="sand"
                    className="w-full"
                    onClick={_continue}
                  >
                    Continue Your Build
                  </Button>
                </div>
              </div>
              <div className="Builder__financing-callout flex flex-col">
                <span className="font-grotesk-headline tracking-wide text-xs uppercase mb-4">
                  Interested in Financing?
                </span>
                <span className="font-grotesk-news text-xs text-stone-400 !leading-relaxed">
                  Complete your build and our team will work with you to get pre-approved and
                  deliver an estimated monthly payment for your TOPO2.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={cx(
          'Builder__financing-cta hidden lg:block fixed bottom-0 bg-fire py-4 px-6 xl:pt-6 xl:pb-5 xl:px-16 rounded-t-[6px] -translate-x-[50%] transition-transform',
          {
            'translate-y-full': !showFinancingCta,
            'left-[calc(((100%-452px)/2)-7.5px)]': !!infoOverlay,
            'left-[calc((100%-452px)/2)]': !infoOverlay
          }
        )}
      >
        <div className="flex">
          <span className="text-white font-grotesk-news uppercase tracking-widest text-xs xl:text-sm">
            Finance for $0 down, and as low as $400/mo
          </span>
          <span className="mx-2 text-white text-sm uppercase">–</span>
          <Link href="/purchase-process/financing">
            <a className="font-grotesk-headline text-white tracking-widest text-xs xl:text-sm uppercase">
              Learn More
            </a>
          </Link>
        </div>
      </div>

      {infoOverlay && (
        <div className="Builder__info-overlay fixed top-0 right-0 left-0 bottom-0 flex items-center justify-center z-50">
          <div
            className="Builder__info-overlay__background absolute top-0 left-0 right-0 bottom-0 bg-brown bg-opacity-50 -z-10"
            onClick={closeInfoOverlay}
          />
          <div className="Builder__info-overlay__inner-wrapper inline-block bg-white w-11/12 md:w-[61%] lg:w-1/3 rounded-md shadow-lg max-h-[90vh] relative overflow-hidden">
            <div className="Builder__info-overlay__top-bar flex items-center justify-between w-full px-6 py-5 border-b-2 border-stone-200 relative z-10">
              <div className="Builder__info-overlay__name">
                <span className="font-grotesk-headline text-sm xl:text-lg tracking-wide">
                  {infoOverlay.name}
                </span>
              </div>
              <div className="Builder__info-overlay__close h-[42px] relative">
                <Button variant="no-style" className="h-[42px]" onClick={closeInfoOverlay}>
                  <Image
                    width="42"
                    height="42"
                    hideLoadingPlaceholder={true}
                    src={immersiveCloseIcon}
                    alt="Escapod Close Icon"
                  />
                </Button>
              </div>
            </div>
            <div className="Builder__info-overlay__content max-h-[90vh] overflow-auto pb-40">
              <div
                className="Builder__info-overlay__image relative"
                key={infoOverlay._key}
                style={{
                  paddingBottom: `${
                    ((infoOverlayImage?.metadata?.dimensions.height || 0) /
                      (infoOverlayImage?.metadata?.dimensions.width || 0)) *
                    100
                  }%`
                }}
              >
                <Image
                  src={infoOverlayImage?.src || ''}
                  layout="fill"
                  objectFit="cover"
                  alt={`${infoOverlay.name}`}
                />
              </div>
              <div className="Builder__info-overlay__thumbnails flex items-center px-6">
                <div className="flex">
                  {!!infoOverlay.images &&
                    !!infoOverlay.images.length &&
                    infoOverlay.images.length > 1 &&
                    infoOverlay.images?.map(image => (
                      <div
                        key={`btk__${image._key}`}
                        className="mr-4 mt-6 relative rounded-sm overflow-hidden h-[50px] w-[50px]"
                      >
                        <Image
                          src={image.src}
                          hideLoadingPlaceholder={true}
                          width="50"
                          height="50"
                          objectFit="cover"
                          alt={`${infoOverlay.name}`}
                          onClick={() => setInfoOverlayImage(image)}
                        />
                      </div>
                    ))}
                </div>
              </div>
              {infoOverlay.description && typeof infoOverlay.description === 'object' && (
                <div className="Builder__info-overlay__description p-6 text-xs md:text-sm tracking-wide !leading-relaxed">
                  <PortableText
                    content={infoOverlay.description}
                    components={{
                      listItem: {
                        number: ({ children }) => {
                          return <li className="ml-4 mb-4 last-of-type:mb-0">{children}</li>;
                        },
                        bullet: ({ children }) => {
                          return <li className="ml-4 mb-4 last-of-type:mb-0">{children}</li>;
                        }
                      }
                    }}
                  />
                </div>
              )}
              {infoOverlay.description && typeof infoOverlay.description === 'string' && (
                <div className="Builder__info-overlay__description p-6 text-xs md:text-sm tracking-wide !leading-relaxed">
                  <span>{infoOverlay.description}</span>
                </div>
              )}
              {infoOverlayIntent === 'add' &&
                !!mutate?.conflictsForModification(infoOverlay).length && (
                  <div className="px-6">
                    <div className="Builder__info-overlay__conflicts bg-rose-50 rounded-sm border-1 border-rose-200 mb-6 p-3">
                      <span className="text-xs text-fire">
                        These items conflict with this add-on and will be removed:{' '}
                      </span>
                      <span className="text-xs">
                        {mutate
                          ?.conflictsForModification(infoOverlay)
                          .map(conflict => conflict.name)
                          .join(', ')}
                      </span>
                    </div>
                  </div>
                )}
              {infoOverlayIntent === 'add' &&
                !!mutate?.unmetDependenciesForModification(infoOverlay).length && (
                  <div className="px-6">
                    <div className="Builder__info-overlay__unmet-dependencies bg-rose-50 rounded-sm border-1 border-rose-200 mb-6 p-3">
                      <span className="text-xs text-fire font-grotesk-news">
                        These items are required dependencies for this add-on and will also be
                        added:{' '}
                      </span>
                      <span className="text-xs font-grotesk-news">
                        {mutate
                          ?.unmetDependenciesForModification(infoOverlay)
                          .map(dependency => dependency.name)
                          .join(', ')}
                      </span>
                    </div>
                  </div>
                )}
              {infoOverlayIntent === 'remove' &&
                !!mutate?.selectedDependentsForModification(infoOverlay).length && (
                  <div className="px-6 mt-6">
                    <div className="Builder__info-overlay__selected-dependencies bg-rose-50 rounded-sm border-1 border-rose-200 mb-6 p-3">
                      <span className="text-xs text-fire">
                        These items require this add-on and will also be removed:{' '}
                      </span>
                      <span className="text-xs">
                        {mutate
                          ?.selectedDependentsForModification(infoOverlay)
                          .map(conflict => conflict.name)
                          .join(', ')}
                      </span>
                    </div>
                  </div>
                )}
            </div>
            <div
              className={cx(
                'Buillder__info-overlay__totals absolute z-10 bottom-0 left-0 flex items-center w-full bg-white border-t-2 border-stone-200 py-4 px-6 shadow-above'
              )}
            >
              <div className="flex flex-col">
                <span className="mb-1 font-grotesk-news text-stone-400 text-xs">Price</span>
                <span className="text-lg font-grotesk-headline tracking-wide">
                  $
                  {formatMoney(
                    priceFromRules(
                      infoOverlay.priceRules || [],
                      { trim: selectedTrim || '' },
                      infoOverlay.price
                    )
                  )}
                </span>
              </div>
              <div className="flex">
                {infoOverlayIntent === 'add' && (
                  <div className="Builder__info-overlay__add-button ml-4">
                    <Button onClick={addFromInfoOverlay} variant="stroke" color="fire">
                      Add and Close
                    </Button>
                  </div>
                )}
                {infoOverlayIntent === 'remove' && (
                  <div className="Builder__info-overlay__remove-button ml-4">
                    <Button onClick={removeFromInfoOverlay} variant="stroke" color="fire">
                      Remove
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {showStandardFeatureOverlay && (
        <div className="Builder__standard-feature-overlay fixed top-0 right-0 left-0 bottom-0 flex items-center justify-center z-50">
          <div
            className="Builder__standard-feature-overlay__background absolute top-0 left-0 right-0 bottom-0 bg-brown bg-opacity-50 -z-10"
            onClick={closeStandardFeatureOverlay}
          />
          <div className="Builder__standard-feature-overlay__inner-wrapper inline-block bg-white w-11/12 md:w-3/4 xl:w-2/3 rounded-md shadow-lg max-h-[90vh] relative overflow-auto p-5 md:p-6 xl:p-10">
            <div className="Builder__standard-feature-overlay__top-bar">
              <div className="flex justify-between items-center mb-12">
                <span className="text-sm text-stone-400 font-grotesk-news">
                  {selectedTrailer.name} Standard Features
                </span>
                <div className="Builder__standard-feature-overlay__close h-[42px] relative">
                  <Button
                    variant="no-style"
                    className="h-[42px]"
                    onClick={closeStandardFeatureOverlay}
                  >
                    <Image
                      width="42"
                      height="42"
                      hideLoadingPlaceholder={true}
                      src={immersiveCloseIcon}
                      alt="Escapod Close Icon"
                    />
                  </Button>
                </div>
              </div>
            </div>
            {selectedTrailer.featureGroups.map(group => {
              if (!!selectedTrim && group.exclusions?.includes(selectedTrim)) return null;

              return (
                <div
                  key={`fgb__group--${group._key}`}
                  className="grid grid-cols-2 gap-6 lg:grid-cols-3 lg:gap-10"
                >
                  {group.features.map(mod => {
                    if (!mod.images?.[0].src) return null;
                    if (!!selectedTrim && mod.exclusions?.includes(selectedTrim)) return null;

                    return (
                      <div key={`builder__fg_overlay--${mod._key}`} className="flex flex-col">
                        <div
                          className="relative mb-5 w-full"
                          style={{ paddingBottom: `${(9 / 16) * 100}%` }}
                        >
                          <Image
                            src={mod.images[0].src}
                            layout="fill"
                            objectFit="cover"
                            alt={mod.name}
                          />
                        </div>
                        <span className="font-grotesk-headline uppercase tracking-wide text-xs">
                          {mod.name}
                        </span>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </BlockWrapper>
  );
};

export default Builder;
