import cx from 'classnames';
import { FC, useState, useCallback } from 'react';
import { Button, Checkbox, TextField } from 'components';

import { useFinancingContext, useFinancingMutate } from 'contexts/FinancingContext';
import AnimateHeight from 'react-animate-height';
import emailIsValid from 'utils/emailIsValid';
import phoneIsValid from 'utils/phoneIsValid';
import formatPhoneNumber from 'utils/formatPhoneNumber';

type Errors = { [key: string]: { [key: string]: string } };

const BasicInfo: FC = () => {
  const { primary, secondary, hasSecondary } = useFinancingContext();
  const [errors, setErrors] = useState<Errors>({});
  const mutate = useFinancingMutate();

  const _continue = useCallback(() => {
    const _errors: Errors = { primary: {}, secondary: {} };
    if (!primary.firstName) _errors.primary['firstName'] = 'Please enter your first name.';
    if (!primary.lastName) _errors.primary['lastName'] = 'Please enter your last name.';
    if (!primary.email || !emailIsValid(primary.email))
      _errors.primary['email'] = 'Please enter a valid email address.';
    if (!primary.phone || !phoneIsValid(primary.phone))
      _errors.primary['phone'] = 'Please enter a valid phone number, only numbers, no symbols.';

    if (hasSecondary) {
      if (!secondary.firstName) _errors.secondary['firstName'] = 'Please enter your first name.';
      if (!secondary.lastName) _errors.secondary['lastName'] = 'Please enter your last name.';
      if (!secondary.email || !emailIsValid(secondary.email))
        _errors.secondary['email'] = 'Please enter a valid email address.';
      if (!secondary.phone || !phoneIsValid(secondary.phone))
        _errors.secondary['phone'] = 'Please enter a valid phone number, only numbers, no symbols.';
    }

    setErrors(_errors);
    if (!!Object.keys(_errors.primary).length || !!Object.keys(_errors.secondary).length) return;

    mutate?.setStage('ID_PRIMARY');
  }, [primary, secondary, hasSecondary, setErrors, mutate]);

  return (
    <div className="FinancingApplication__BasicInfo flex flex-col items-center justify-center w-full max-w-prose m-auto pb-12 animate-fade-in">
      <div
        className={cx('w-full mb-12 flex flex-col', {
          'border-b-1 border-stone-300 pb-8': hasSecondary
        })}
      >
        <span className="mb-12 font-grotesk-headline tracking-wide">
          Primary Applicant Information
        </span>
        <div className="lg:grid w-full lg:grid-cols-3 lg:gap-8">
          <div className="mb-6 lg:mb-8">
            <TextField
              name="first_name"
              ariaLabel="First Name"
              label="First Name"
              required={true}
              value={primary.firstName}
              onChange={value => mutate?.setApplicant('primary', { firstName: value as string })}
              error={errors.primary?.firstName}
              showError={!!errors.primary?.firstName}
            />
          </div>
          <div className="mb-6 lg:mb-8">
            <TextField
              name="middle_name"
              ariaLabel="Middle Name"
              label="Middle Name"
              value={primary.middleName}
              onChange={value => mutate?.setApplicant('primary', { middleName: value as string })}
              error={errors.primary?.middleName}
              showError={!!errors.primary?.middleName}
            />
          </div>
          <div className="mb-6 lg:mb-8">
            <TextField
              name="last_name"
              ariaLabel="Last Name"
              label="Last Name"
              required={true}
              value={primary.lastName}
              onChange={value => mutate?.setApplicant('primary', { lastName: value as string })}
              error={errors.primary?.lastName}
              showError={!!errors.primary?.lastName}
            />
          </div>
        </div>
        <div className="lg:grid w-full lg:grid-cols-2 lg:gap-8">
          <div className="mb-6 lg:mb-8">
            <TextField
              name="email"
              ariaLabel="Email Address"
              label="Email Address"
              required={true}
              value={primary.email}
              onChange={value => mutate?.setApplicant('primary', { email: value as string })}
              error={errors.primary?.email}
              showError={!!errors.primary?.email}
            />
          </div>
          <div className="mb-6 lg:mb-8">
            <TextField
              name="phone"
              ariaLabel="Phone Number"
              label="Phone Number"
              required={true}
              value={formatPhoneNumber(primary.phone)}
              onChange={value =>
                mutate?.setApplicant('primary', { phone: (value as string).replace(/\D/g, '') })
              }
              error={errors.primary?.phone}
              showError={!!errors.primary?.phone}
            />
          </div>
        </div>
        <div className="mb-6 w-full">
          <Checkbox
            className="w-full"
            variant="primary"
            reverse={true}
            name="has_secondary"
            label="Will you be applying jointly with a secondary applicant?"
            value={hasSecondary}
            onChange={value => mutate?.setHasSecondary(value)}
          />
        </div>
      </div>
      <AnimateHeight height={hasSecondary ? 'auto' : 0} className="w-full">
        <div className="w-full mb-6 lg:mb-12 flex flex-col">
          <span className="mb-12 font-grotesk-headline tracking-wide">
            Secondary Applicant Information
          </span>
          <div className="lg:grid w-full lg:grid-cols-3 lg:gap-8">
            <div className="mb-6 lg:mb-8">
              <TextField
                name="secondary_first_name"
                ariaLabel="First Name"
                label="First Name"
                required={true}
                value={secondary.firstName}
                onChange={value =>
                  mutate?.setApplicant('secondary', { firstName: value as string })
                }
                error={errors.secondary?.firstName}
                showError={!!errors.secondary?.firstName}
              />
            </div>
            <div className="mb-6 lg:mb-8">
              <TextField
                name="secondary_middle_name"
                ariaLabel="Middle Name"
                label="Middle Name"
                value={secondary.middleName}
                onChange={value =>
                  mutate?.setApplicant('secondary', { middleName: value as string })
                }
                error={errors.secondary?.middleName}
                showError={!!errors.secondary?.middleName}
              />
            </div>
            <div className="mb-6 lg:mb-8">
              <TextField
                name="secondary_last_name"
                ariaLabel="Last Name"
                label="Last Name"
                required={true}
                value={secondary.lastName}
                onChange={value => mutate?.setApplicant('secondary', { lastName: value as string })}
                error={errors.secondary?.lastName}
                showError={!!errors.secondary?.lastName}
              />
            </div>
          </div>
          <div className="lg:grid w-full lg:grid-cols-2 lg:gap-8">
            <div className="mb-6 lg:mb-8">
              <TextField
                name="secondary_email"
                ariaLabel="Email Address"
                label="Email Address"
                required={true}
                value={secondary.email}
                onChange={value => mutate?.setApplicant('secondary', { email: value as string })}
                error={errors.secondary?.email}
                showError={!!errors.secondary?.email}
              />
            </div>
            <div className="mb-6 lg:mb-8">
              <TextField
                name="secondary_phone"
                ariaLabel="Phone Number"
                label="Phone Number"
                required={true}
                value={formatPhoneNumber(secondary.phone)}
                onChange={value =>
                  mutate?.setApplicant('secondary', { phone: (value as string).replace(/\D/g, '') })
                }
                error={errors.secondary?.phone}
                showError={!!errors.secondary?.phone}
              />
            </div>
          </div>
        </div>
      </AnimateHeight>
      <div className="w-full flex justify-between">
        <Button color="gray" variant="fill" size="lg" onClick={() => mutate?.setStage('CONSENT')}>
          <span className="mr-2">&larr;</span> Back
        </Button>
        <Button color="sand" variant="fill" size="lg" onClick={_continue}>
          Continue <span className="ml-2">&rarr;</span>
        </Button>
      </div>
    </div>
  );
};

export default BasicInfo;
