export type ButtonVariant = 'stroke' | 'fill' | 'link' | 'no-style';
export type ButtonColor = 'sand' | 'charcoal' | 'black' | 'white' | 'fire' | 'gray';
export type ButtonSize = 'none' | 'xs' | 'sm' | 'md' | 'lg';

export const ButtonVariants = {
  stroke: ['border-2', 'rounded-full', 'uppercase'],
  fill: ['border-2', 'rounded-full', 'uppercase'],
  link: ['underline'],
  'no-style': []
};

export const ButtonSizes = {
  none: [],
  xs: ['pt-2', 'px-3', 'pb-1', 'text-[8px]'],
  sm: ['px-4', 'pt-2', 'pb-[6px]', 'text-xs'],
  md: ['h-[45px]', 'px-[1.6rem]', 'text-xs'],
  lg: ['h-[52px]', 'px-[1.6rem]', 'text-xs']
};

export const ButtonColors = {
  gray: {
    fill: 'bg-stone-200 border-stone-200 text-charcoal hover:bg-charcoal hover:border-charcoal hover:text-white',
    stroke: 'bg-transparent border-stone-200 text-charcoal hover:bg-stone-200'
  },
  charcoal: {
    fill: 'bg-charcoal border-charcoal text-white hover:text-sand hover:bg-stone-800 hover:border-800',
    stroke: 'bg-transparent border-charcoal text-charcoal hover:bg-charcoal hover:text-white'
  },
  white: {
    fill: 'bg-white border-white text-charcoal hover:bg-charcoal hover:text-white hover:border-charcoal',
    stroke: 'bg-transparent border-white text-white hover:bg-white hover:text-charcoal'
  },
  fire: {
    fill: 'bg-fire border-fire text-charcoal hover:bg-charcoal hover:border-charcoal hover:text-white',
    stroke: 'bg-transparent border-fire text-charcoal hover:bg-fire'
  },
  black: {
    fill: 'bg-charcoal border-charcoal text-white hover:text-sand hover:bg-stone-800 hover:border-800',
    stroke: 'bg-transparent border-charcoal text-charcoal hover:bg-charcoal hover:text-white'
  },
  sand: {
    fill: 'bg-sand border-sand text-charcoal hover:bg-charcoal hover:border-charcoal hover:text-white',
    stroke: 'bg-transparent border-sand text-charcoal hover:bg-sand hover:text-charcoal'
  }
};
