import { FC, useState, useCallback, useEffect } from 'react';
import { RiEdit2Fill } from 'react-icons/ri';
import cx from 'classnames';
import { Image, Button, Checkbox, TextField } from 'components';
import * as Sentry from '@sentry/nextjs';

import { useFinancingContext, useFinancingMutate } from 'contexts/FinancingContext';
import { EmploymentStatus, EmploymentStatusesWithEmployer } from 'constants/Financing';
import Secure from 'clients/Secure';
import * as Gtag from 'clients/Gtag';
import Fbq from 'clients/Fbq';

import loader from 'public/images/loader.gif';
import Klaviyo from 'clients/Klaviyo/browser';
import EscapodApi from 'clients/Escapod';
import formatSSN from 'utils/formatSSN';
import formatPhoneNumber from 'utils/formatPhoneNumber';
import formatMoney from 'utils/formatMoney';

const Review: FC = () => {
  const { primary, secondary, hasSecondary, downPayment, monthlyTarget } = useFinancingContext();
  const [hasReviewed, setHasReviewed] = useState(false);
  const [hasGrantedPermission, setHasGrantedPermission] = useState(false);
  const [isUSCitizen, setIsUSCitizen] = useState(false);
  const [optedInForMarketing, setOptedInForMarketing] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const mutate = useFinancingMutate();

  const _back = useCallback(() => {
    return hasSecondary
      ? mutate?.setStage('EMPLOYMENT_SECONDARY')
      : mutate?.setStage('EMPLOYMENT_PRIMARY');
  }, [hasSecondary, mutate]);

  const _continue = useCallback(() => {
    const sendApplication = async () => {
      setIsLoading(true);

      Sentry.withScope(scope => {
        scope.setExtra('params', {
          primary: { ...primary, employment: JSON.stringify(primary.employment) },
          secondary: { ...secondary, employment: JSON.stringify(secondary.employment) },
          hasSecondary,
          downPayment,
          monthlyTarget
        });
        Sentry.captureMessage(`Financing App Attempt Log`);
      });

      try {
        const result = await Secure.preApproveApplicants(
          hasSecondary
            ? { primary, secondary, downPayment, monthlyTarget }
            : { primary, downPayment, monthlyTarget }
        );

        if (optedInForMarketing) {
          // Turned these off from preventing ad algorithms from being rewarded for
          // subscription events produced on this page
          //
          //
          // Fbq('Subscribe', { value: '0.00', currency: 'USD', predicted_ltv: '0.00' });
          // Gtag.event('conversion', {
          //   send_to: 'AW-814232277/VfPlCMato6YDENXloIQD',
          //   transaction_id: window.btoa(primary.email),
          //   event_callback: () => {
          //     return;
          //   }
          // });
          //
          EscapodApi.klaviyo.subscribe({
            firstName: primary.firstName,
            lastName: primary.lastName,
            email: primary.email,
            form: 'financing_application'
          });

          if (hasSecondary) {
            EscapodApi.klaviyo.subscribe({
              firstName: secondary.firstName,
              lastName: secondary.lastName,
              email: secondary.email,
              form: 'financing_application'
            });
          }
        }

        if (
          process.env.NODE_ENV !== 'development' &&
          process.env.NEXT_PUBLIC_SANITY_DATASET !== 'staging'
        ) {
          Fbq('Lead');
          Gtag.event('financing_app_submission', {
            path: window?.location.pathname
          });
          Gtag.event('conversion', {
            send_to: 'AW-814232277/znXKCKnM-fIYENXloIQD',
            event_callback: () => {
              return;
            }
          });
          Klaviyo.events.financingApp({
            resultStatus: result.status,
            resultType: result.result,
            downPayment,
            monthlyTarget
          });
        }

        setIsLoading(false);
        mutate?.setResult(result);
        mutate?.setStage('CONFIRMATION');
      } catch (error) {
        setIsLoading(false);
        Sentry.captureException(error);
        return setErrors({ form: 'There was an error while processing your application' });
      }
    };

    sendApplication();

    return mutate?.setStage('REVIEW');
  }, [primary, secondary, downPayment, hasSecondary, monthlyTarget, mutate, optedInForMarketing]);

  useEffect(() => {
    if (primary.usCitizen === isUSCitizen) return;

    mutate?.setApplicant('primary', { usCitizen: isUSCitizen });
    mutate?.setApplicant('secondary', { usCitizen: isUSCitizen });
  }, [mutate, primary, isUSCitizen]);

  return (
    <div className="FinancingApplication__Review flex flex-col items-center justify-center w-full max-w-prose mx-auto animate-fade-in">
      <div className="mb-8 flex flex-col w-full">
        <span className="mb-12 font-grotesk-headline tracking-wide">Review Application</span>
        <div className="mb-6 lg:mb-12 w-full lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="bg-stone-100 rounded-md p-5 w-full mb-6 lg:mb-0">
            <div className="flex flex-col mb-8">
              <span className="mb-2 font-grotesk-headline tracking-wide text-xs text-stone-400 flex items-center">
                Primary Applicant
                <Button
                  className="ml-1 text-sm"
                  variant="no-style"
                  onClick={() => mutate?.setStage('BASIC')}
                >
                  <RiEdit2Fill />
                </Button>
              </span>
              <span className="font-grotesk-news text-sm">
                {primary.firstName}{' '}
                {!!primary.middleName
                  ? `${primary.middleName} ${primary.lastName}`
                  : primary.lastName}
              </span>
            </div>
            <div className="grid grid-cols-2 gap-8 mb-8">
              <div className="flex flex-col">
                <span className="mb-2 font-grotesk-headline tracking-wide text-xs text-stone-400 flex items-center">
                  SSN
                  <Button
                    className="ml-1 text-sm"
                    variant="no-style"
                    onClick={() => mutate?.setStage('ID_PRIMARY')}
                  >
                    <RiEdit2Fill />
                  </Button>
                </span>
                <span className="font-grotesk-news text-sm">{formatSSN(primary.ssn)}</span>
              </div>
              <div className="flex flex-col">
                <span className="mb-2 font-grotesk-headline tracking-wide text-xs text-stone-400 flex items-center">
                  DOB
                  <Button
                    className="ml-1 text-sm"
                    variant="no-style"
                    onClick={() => mutate?.setStage('ID_PRIMARY')}
                  >
                    <RiEdit2Fill />
                  </Button>
                </span>
                <span className="font-grotesk-news text-sm">{primary.dob}</span>
              </div>
            </div>
            <div className="flex flex-col mb-8">
              <span className="mb-2 font-grotesk-headline tracking-wide text-xs text-stone-400 flex items-center">
                Current Address
                <Button
                  className="ml-1 text-sm"
                  variant="no-style"
                  onClick={() => mutate?.setStage('ID_PRIMARY')}
                >
                  <RiEdit2Fill />
                </Button>
              </span>
              <span className="font-grotesk-news text-sm mb-1">
                {!!primary.address2 ? `${primary.address1}, ${primary.address2}` : primary.address1}
              </span>
              <span className="font-grotesk-news text-sm mb-1">
                {primary.city}, {primary.state}, {primary.zip}
              </span>
              <span className="font-grotesk-news text-sm mb-1">
                {formatPhoneNumber(primary.phone)}
              </span>
            </div>
            <div className="grid grid-cols-2 gap-8">
              <div className="flex flex-col">
                <span className="mb-2 font-grotesk-headline tracking-wide text-xs text-stone-400 flex items-center">
                  Job Status
                  <Button
                    className="ml-1 text-sm"
                    variant="no-style"
                    onClick={() => mutate?.setStage('EMPLOYMENT_PRIMARY')}
                  >
                    <RiEdit2Fill />
                  </Button>
                </span>
                <span className="font-grotesk-news text-sm mb-1">
                  {EmploymentStatus[primary.employment.status]}
                </span>
                {!!primary.employment.occupation && (
                  <span className="font-grotesk-news text-sm mb-1">
                    {primary.employment.occupation}
                  </span>
                )}
                {primary.employment.salary > 0 && (
                  <span className="font-grotesk-news text-sm mb-1">
                    ${formatMoney(primary.employment.salary)}/month
                  </span>
                )}
              </div>
              {['EMPLOYED', 'SELF_EMPLOYED', 'MILITARY', 'TEMP_STAFFING_AGENCY'].includes(
                primary.employment.status
              ) && (
                <div className="flex flex-col">
                  <span className="mb-2 font-grotesk-headline tracking-wide text-xs text-stone-400 flex items-center">
                    Employer
                    <Button
                      className="ml-1 text-sm"
                      variant="no-style"
                      onClick={() => mutate?.setStage('EMPLOYMENT_PRIMARY')}
                    >
                      <RiEdit2Fill />
                    </Button>
                  </span>
                  <span className="font-grotesk-news text-sm mb-1">
                    {primary.employment.employer}
                  </span>
                  <span className="font-grotesk-news text-sm mb-1">
                    {primary.employment.employerPhone}
                  </span>
                </div>
              )}
            </div>
            {!!primary.employment.additionalIncomeSource &&
              primary.employment.additionalIncome > 0 && (
                <div className="flex flex-col mt-8">
                  <span className="mb-2 font-grotesk-headline tracking-wide text-xs text-stone-400 flex items-center">
                    Additional Income
                    <Button
                      className="ml-1 text-sm"
                      variant="no-style"
                      onClick={() => mutate?.setStage('EMPLOYMENT_PRIMARY')}
                    >
                      <RiEdit2Fill />
                    </Button>
                  </span>
                  <span className="font-grotesk-news text-sm mb-1">
                    {primary.employment.additionalIncomeSource}
                  </span>
                  <span className="font-grotesk-news text-sm mb-1">
                    ${formatMoney(primary.employment.additionalIncome)}/month
                  </span>
                </div>
              )}
          </div>
          {hasSecondary ? (
            <div className="bg-stone-100 rounded-md p-5 w-full">
              <div className="flex flex-col mb-8">
                <span className="mb-2 font-grotesk-headline tracking-wide text-xs text-stone-400 flex items-center">
                  Secondary Applicant
                  <Button
                    className="ml-1 text-sm"
                    variant="no-style"
                    onClick={() => mutate?.setStage('BASIC')}
                  >
                    <RiEdit2Fill />
                  </Button>
                </span>
                <span className="font-grotesk-news text-sm">
                  {secondary.firstName}{' '}
                  {!!secondary.middleName
                    ? `${secondary.middleName} ${secondary.lastName}`
                    : secondary.lastName}
                </span>
              </div>
              <div className="grid grid-cols-2 gap-8 mb-8">
                <div className="flex flex-col">
                  <span className="mb-2 font-grotesk-headline tracking-wide text-xs text-stone-400 flex items-center">
                    SSN
                    <Button
                      className="ml-1 text-sm"
                      variant="no-style"
                      onClick={() => mutate?.setStage('ID_SECONDARY')}
                    >
                      <RiEdit2Fill />
                    </Button>
                  </span>
                  <span className="font-grotesk-news text-sm">{formatSSN(secondary.ssn)}</span>
                </div>
                <div className="flex flex-col">
                  <span className="mb-2 font-grotesk-headline tracking-wide text-xs text-stone-400 flex items-center">
                    DOB
                    <Button
                      className="ml-1 text-sm"
                      variant="no-style"
                      onClick={() => mutate?.setStage('ID_SECONDARY')}
                    >
                      <RiEdit2Fill />
                    </Button>
                  </span>
                  <span className="font-grotesk-news text-sm">{secondary.dob}</span>
                </div>
              </div>
              <div className="flex flex-col mb-8">
                <span className="mb-2 font-grotesk-headline tracking-wide text-xs text-stone-400 flex items-center">
                  Current Address
                  <Button
                    className="ml-1 text-sm"
                    variant="no-style"
                    onClick={() => mutate?.setStage('ID_SECONDARY')}
                  >
                    <RiEdit2Fill />
                  </Button>
                </span>
                <span className="font-grotesk-news text-sm mb-1">
                  {!!secondary.address2
                    ? `${secondary.address1}, ${secondary.address2}`
                    : secondary.address1}
                </span>
                <span className="font-grotesk-news text-sm mb-1">
                  {secondary.city}, {secondary.state}, {secondary.zip}
                </span>
                <span className="font-grotesk-news text-sm mb-1">
                  {formatPhoneNumber(secondary.phone)}
                </span>
              </div>
              <div className="grid grid-cols-2 gap-8">
                <div className="flex flex-col">
                  <span className="mb-2 font-grotesk-headline tracking-wide text-xs text-stone-400 flex items-center">
                    Job Status
                    <Button
                      className="ml-1 text-sm"
                      variant="no-style"
                      onClick={() => mutate?.setStage('EMPLOYMENT_SECONDARY')}
                    >
                      <RiEdit2Fill />
                    </Button>
                  </span>
                  <span className="font-grotesk-news text-sm mb-1">
                    {EmploymentStatus[secondary.employment.status]}
                  </span>
                  {!!secondary.employment.occupation && (
                    <span className="font-grotesk-news text-sm mb-1">
                      {secondary.employment.occupation}
                    </span>
                  )}
                  {secondary.employment.salary > 0 && (
                    <span className="font-grotesk-news text-sm mb-1">
                      ${formatMoney(secondary.employment.salary)}/month
                    </span>
                  )}
                </div>
                {EmploymentStatusesWithEmployer.includes(secondary.employment.status) && (
                  <div className="flex flex-col">
                    <span className="mb-2 font-grotesk-headline tracking-wide text-xs text-stone-400 flex items-center">
                      Employer
                      <Button
                        className="ml-1 text-sm"
                        variant="no-style"
                        onClick={() => mutate?.setStage('EMPLOYMENT_SECONDARY')}
                      >
                        <RiEdit2Fill />
                      </Button>
                    </span>
                    <span className="font-grotesk-news text-sm mb-1">
                      {secondary.employment.employer}
                    </span>
                    <span className="font-grotesk-news text-sm mb-1">
                      {secondary.employment.employerPhone}
                    </span>
                  </div>
                )}
              </div>
              {!!secondary.employment.additionalIncomeSource &&
                secondary.employment.additionalIncome > 0 && (
                  <div className="flex flex-col mt-8">
                    <span className="mb-2 font-grotesk-headline tracking-wide text-xs text-stone-400 flex items-center">
                      Additional Income
                      <Button
                        className="ml-1 text-sm"
                        variant="no-style"
                        onClick={() => mutate?.setStage('EMPLOYMENT_SECONDARY')}
                      >
                        <RiEdit2Fill />
                      </Button>
                    </span>
                    <span className="font-grotesk-news text-sm mb-1">
                      {secondary.employment.additionalIncomeSource}
                    </span>
                    <span className="font-grotesk-news text-sm mb-1">
                      ${formatMoney(secondary.employment.additionalIncome)}/month
                    </span>
                  </div>
                )}
            </div>
          ) : (
            <div className="border-dashed border-2 border-stone-300 hidden items-center justify-center rounded-md lg:flex">
              <span className="text-stone-500 text-sm font-grotesk-news">
                No Secondary Applicant
              </span>
            </div>
          )}
        </div>

        <div className="mb-8 lg:mb-4 w-full">
          <Checkbox
            className="w-full"
            variant="primary"
            reverse={true}
            name="has_reviewed"
            label="I have reviewed this information and declare that it is correct."
            required={true}
            value={hasReviewed}
            onChange={value => setHasReviewed(value)}
          />
        </div>
        <div className="mb-8 lg:mb-4 w-full">
          <Checkbox
            className="w-full"
            variant="primary"
            reverse={true}
            name="us_citizen"
            required={true}
            label="I am able to provide proof of US citizenship for the applicants above."
            value={isUSCitizen}
            onChange={value => setIsUSCitizen(value)}
          />
        </div>
        <div className="mb-12 w-full pb-12 border-b-1 border-stone-200">
          <Checkbox
            className="w-full"
            variant="primary"
            reverse={true}
            name="granted_permission"
            required={true}
            label="I give Escapod Sales & Rentals LLC permission to contact me in regards to application."
            value={hasGrantedPermission}
            onChange={value => setHasGrantedPermission(value)}
          />
        </div>

        <span className="mb-12 font-grotesk-headline tracking-wide">Loan Preferences</span>
        <div className="lg:grid lg:grid-cols-2 lg:gap-8 mb-8">
          <div className="mb-6">
            <TextField
              name="down_payment"
              ariaLabel="Preferred Down Payment"
              label="Preferred Down Payment"
              value={downPayment}
              onChange={value => mutate?.setDownPayment(value as number)}
              error={errors?.downPayment}
              showError={!!errors?.downPayment}
              disabled={!hasReviewed || !hasGrantedPermission}
            />
          </div>
          <div className="mb-6">
            <TextField
              name="monthly_target"
              ariaLabel="Monthly Payment Target"
              label="Monthly Payment Target"
              value={monthlyTarget}
              onChange={value => mutate?.setMonthlyTarget(value as number)}
              error={errors?.monthlyTarget}
              showError={!!errors?.monthlyTarget}
              disabled={!hasReviewed || !hasGrantedPermission}
            />
          </div>
        </div>
        <div className="mb-12">
          <Checkbox
            className="w-full"
            variant="primary"
            reverse={true}
            disabled={!hasReviewed || !hasGrantedPermission}
            name="has_secondary"
            label="I want to receive updates from Escapod in my inbox."
            value={optedInForMarketing}
            onChange={value => setOptedInForMarketing(value)}
          />
        </div>
      </div>
      {errors.form && <span className="text-xs text-fire">{errors.form}</span>}
      <div className="w-full flex justify-between">
        <Button variant="fill" color="gray" size="lg" onClick={_back}>
          <span className="mr-2">&larr;</span> Back
        </Button>
        <div className="flex items-center">
          <div
            className={cx('FinancingApplication__Review__loader-gif mr-4', {
              hidden: !isLoading
            })}
          >
            <div className="h-5 w-5 relative">
              <Image src={loader} layout="fill" alt="Submit button loading GIF" />
            </div>
          </div>
          <Button
            color="sand"
            size="lg"
            onClick={_continue}
            disabled={!hasReviewed || !hasGrantedPermission || isLoading}
          >
            <span className="md:hidden">
              Submit <span className="ml-2">&rarr;</span>
            </span>
            <span className="hidden md:inline">
              Get Pre-Approved Now <span className="ml-2">&rarr;</span>
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Review;
