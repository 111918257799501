import React, { ForwardRefRenderFunction, ReactNode, MouseEvent, forwardRef } from 'react';
import cx from 'classnames';
import Link from 'next/link';
import {
  ButtonVariant,
  ButtonColor,
  ButtonSize,
  ButtonSizes,
  ButtonVariants,
  ButtonColors
} from 'constants/UI';

type Props = {
  variant?: ButtonVariant;
  color?: ButtonColor;
  size?: ButtonSize;
  className?: string;
  href?: string;
  onClick?: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  type?: 'button' | 'submit';
  children: ReactNode;
  disabled?: boolean;
};

export const Button: ForwardRefRenderFunction<HTMLButtonElement, Props> = (
  {
    variant = 'fill',
    color = 'charcoal',
    size = 'md',
    href,
    children,
    className = '',
    onClick,
    type = 'button',
    disabled
  },
  ref
) => {
  if (!ButtonVariants[variant]) {
    console.warn(`Button.tsx: Failed to render variant for "${variant}".`);
    return null;
  }

  const baseClasses =
    'flex justify-center items-center transition-all font-grotesk-headline tracking-wide whitespace-nowrap';
  const variantClasses = ButtonVariants[variant].join(' ');
  const colorClasses =
    variant === 'fill' || variant === 'stroke' ? ButtonColors[color][variant] : '';
  const sizeClasses =
    variant === 'no-style' || variant === 'link' ? ButtonSizes.none : ButtonSizes[size];

  const classes = cx(className, baseClasses, variantClasses, colorClasses, sizeClasses, {
    'opacity-50 pointer-events-none': disabled
  });

  return !href ? (
    <button ref={ref} className={classes} onClick={onClick} type={type} disabled={disabled}>
      {children}
    </button>
  ) : href?.charAt(0) === '/' ? (
    <Link href={href}>
      <a className={classes} onClick={onClick}>
        {children}
      </a>
    </Link>
  ) : (
    <a href={href} className={classes} target="_blank" rel="noreferrer" onClick={onClick}>
      {children}
    </a>
  );
};

export default forwardRef<HTMLButtonElement, Props>(Button);
