import confetti from 'canvas-confetti';
import randomInRange from 'utils/randomInRange';

const COLORS = ['#ec092a', '#d1d1d1', '#3e461b', '#778083'];
const FIREWORKS_DURATION = 3 * 1000;
const FIREWORKS_INTERVAL = 500;
const DEFAULTS = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0, colors: COLORS };

const fireworks = () => {
  const animationEnd = Date.now() + FIREWORKS_DURATION;

  const interval: any = setInterval(function () {
    const timeLeft = animationEnd - Date.now();
    if (timeLeft <= 0) return clearInterval(interval);
    const particleCount = 50 * (timeLeft / FIREWORKS_DURATION);

    confetti({
      ...DEFAULTS,
      particleCount,
      origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 }
    });
    setTimeout(() => {
      confetti({
        ...DEFAULTS,
        particleCount,
        origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 }
      });
    }, FIREWORKS_INTERVAL / 4);
  }, FIREWORKS_INTERVAL);
};

export default fireworks;
