import React, { FC, useCallback, useState } from 'react';
import cx from 'classnames';
import AnimateHeight from 'react-animate-height';

import EscapodApi from 'clients/Escapod';
import Fbq from 'clients/Fbq';
import * as Gtag from 'clients/Gtag';

import { Block, PortableText as TPortableText, Image as TImage } from 'types';
import {
  BlockWrapper,
  Button,
  PortableText,
  Select,
  TextField,
  FormWrapper,
  Image,
  Checkbox
} from 'components';
import emailIsValid from 'utils/emailIsValid';
import loader from '../../public/images/loader.gif';
import Klaviyo from 'clients/Klaviyo/browser';

type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  subject: string;
  message: string;
};
const INITIAL_FORM_DATA: FormData = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  subject: '',
  message: ''
};

export type TContactForm = Block<
  'contactFormBlock',
  {
    title?: string;
    image?: TImage;
    layout: 'dropdown' | 'default';
    variant?: 'legalInfo';
    body: TPortableText;
    buttonLabel: string;
  }
>;
export const ContactForm: FC<TContactForm> = ({
  title,
  body,
  buttonLabel,
  layout,
  image,
  meta
}) => {
  const [formIsOpen, setFormIsOpen] = useState(layout !== 'dropdown');
  const openForm = useCallback(() => setFormIsOpen(true), [setFormIsOpen]);

  const [formIsSending, setFormIsSending] = useState(false);
  const [newsletterOptIn, setNewsletterOptIn] = useState(true);

  const [formData, setFormData] = useState<FormData>(INITIAL_FORM_DATA);
  const [formErrors, setFormErrors] = useState<FormData & { form?: string }>(INITIAL_FORM_DATA);

  const submitForm = useCallback(() => {
    setFormErrors(INITIAL_FORM_DATA);

    if (!formData.subject) {
      return setFormErrors({ ...INITIAL_FORM_DATA, subject: 'Please select a support topic.' });
    }
    if (!formData.firstName) {
      return setFormErrors({ ...INITIAL_FORM_DATA, firstName: 'Please enter a first name.' });
    }
    if (!formData.lastName) {
      return setFormErrors({ ...INITIAL_FORM_DATA, lastName: 'Please enter a last name.' });
    }
    if (!formData.email || !emailIsValid(formData.email)) {
      return setFormErrors({ ...INITIAL_FORM_DATA, email: 'Please enter a valid email.' });
    }
    if (!formData.phone) {
      return setFormErrors({ ...INITIAL_FORM_DATA, phone: 'Please enter a valid phone number.' });
    }
    if (!formData.message) {
      return setFormErrors({
        ...INITIAL_FORM_DATA,
        message: 'Please tell us what we can help you with.'
      });
    }

    setFormIsSending(true);

    const createContact = async () => {
      const response = await EscapodApi.contact.create({
        templateParams: {
          FIRST_NAME: formData.firstName,
          LAST_NAME: formData.lastName,
          EMAIL: formData.email,
          PHONE: formData.phone,
          SUBJECT: formData.subject,
          MESSAGE: formData.message
        }
      });

      if (response.message === 'ok') {
        setFormErrors(state => ({ ...state, form: 'Your message has been received.' }));
        setTimeout(() => {
          setFormIsSending(false);
          setFormData(INITIAL_FORM_DATA);
          setFormErrors(INITIAL_FORM_DATA);
        }, 3000);
      } else {
        setFormErrors(state => ({
          ...state,
          form: 'There was an unexpected error while submitting your message. Contact us directly by emailing hello@escapod.us or try again later.'
        }));

        setFormIsSending(false);
      }
    };

    Fbq('Contact');

    Gtag.event('contact_form_submission', {
      path: window?.location.pathname
    });
    Gtag.event('conversion', {
      send_to: 'AW-814232277/hlCNCP2unakYENXloIQD',
      event_callback: () => {
        return;
      }
    });

    if (newsletterOptIn) {
      EscapodApi.klaviyo.subscribe({ email: formData.email, form: 'contact_form' });
      Fbq('Subscribe', { value: '0.00', currency: 'USD', predicted_ltv: '0.00' });
      Gtag.event('conversion', {
        send_to: 'AW-814232277/VfPlCMato6YDENXloIQD',
        transaction_id: window.btoa(formData.email),
        event_callback: () => {
          return;
        }
      });
    }

    Klaviyo.events.contact({
      name: `${formData.firstName} ${formData.lastName}`,
      phone: formData.phone,
      subject: formData.subject,
      message: formData.message
    });

    createContact();
  }, [formData, setFormErrors, newsletterOptIn]);

  const imageWidth = image?.metadata?.dimensions.width || 1;
  const imageHeight = image?.metadata?.dimensions.height || 1;

  return (
    <BlockWrapper className="ContactForm">
      <div className="ContactForm__inner relative">
        <div className="ContactForm__header">
          {!!title && meta.isFirstBlockWithTitle && (
            <h1 className="ContactForm__title mb-8 w-full text-center font-grotesk-headline-news text-2xl md:text-3xl lg:text-left">
              {title}
            </h1>
          )}
          {!!title && !meta.isFirstBlockWithTitle && (
            <h2 className="ContactForm__title mb-8 w-full text-center font-grotesk-headline-news text-2xl md:text-3xl lg:text-left">
              {title}
            </h2>
          )}
        </div>
        <div className="flex flex-col lg:flex-row">
          <div className="ContactForm__image-outer-wrapper w-full lg:w-2/3 xl:w-3/4 lg:pr-0">
            <div
              className="ContactForm__image-inner-wrapper relative w-full"
              style={{
                paddingBottom: `${(imageHeight / imageWidth) * 100}%`
              }}
            >
              <Image
                className="ContactForm__image top-0 left-0 w-full"
                src={image?.src || ''}
                layout="fill"
                objectFit="contain"
                alt={image?.alt || ''}
                sizes="(max-width: 768px) 96vw, (max-width: 1200px) 80vw, (max-width: 1920px) 50vw"
              />
            </div>
            <div className="text-sm font-grotesk-sub-headline tracking-wide !leading-relaxed lg:text-stone-400 lg:w-2/3 mt-6 mb-12 lg:mb-0">
              <PortableText content={body} />
              <span className="mt-2 text-fire hidden lg:inline">&rarr;</span>
            </div>
          </div>
          <div className="w-full lg:mt-0 lg:w-1/3 lg:pt-16 xl:w-1/4 relative lg:flex lg:justify-end">
            <div className="lg:bg-stone-200 lg:p-6 lg:w-[170%] lg:shrink-0">
              <FormWrapper id="Contact-Form-Block" name="Contact-Form-Block">
                <AnimateHeight height={formIsOpen ? 'auto' : 0}>
                  <div className="flex flex-col">
                    <div className="mb-6">
                      <span className="text-sm text-fire font-grotesk-headline-news tracking-widest uppercase">
                        1. ADD YOUR INFO
                      </span>
                    </div>
                    <div className="grid grid-cols-2 gap-4 mb-6">
                      <TextField
                        ariaLabel="First Name"
                        label="First Name"
                        name="first_name"
                        id="ContantForm-first-name"
                        variant="secondary"
                        placeholder="First Name"
                        required={true}
                        value={formData.firstName}
                        showError={!!formErrors.firstName}
                        onBlur={() => setFormErrors(state => ({ ...state, firstName: '' }))}
                        onChange={value => setFormData({ ...formData, firstName: value as string })}
                      />
                      <TextField
                        ariaLabel="Last Name"
                        label="Last Name"
                        name="last_name"
                        id="ContantForm-last-name"
                        variant="secondary"
                        placeholder="Last Name"
                        required={true}
                        value={formData.lastName}
                        showError={!!formErrors.lastName}
                        onBlur={() => setFormErrors(state => ({ ...state, lastName: '' }))}
                        onChange={value => setFormData({ ...formData, lastName: value as string })}
                      />
                    </div>
                    <div className="grid grid-cols-2 gap-4 mb-12">
                      <TextField
                        ariaLabel="Email"
                        label="Email"
                        name="email"
                        required={true}
                        id="ContantForm-email"
                        placeholder="hello@escapod.us"
                        variant="secondary"
                        value={formData.email}
                        showError={!!formErrors.email}
                        onBlur={() => setFormErrors(state => ({ ...state, email: '' }))}
                        onChange={value => setFormData({ ...formData, email: value as string })}
                      />
                      <TextField
                        ariaLabel="Phone Number"
                        label="Phone"
                        required={true}
                        name="name"
                        id="ContantForm-phone"
                        placeholder="435-555-0123"
                        variant="secondary"
                        value={formData.phone}
                        showError={!!formErrors.phone}
                        onBlur={() => setFormErrors(state => ({ ...state, phone: '' }))}
                        onChange={value => setFormData({ ...formData, phone: value as string })}
                      />
                    </div>
                  </div>

                  <div className="mb-4">
                    <span className="text-sm text-fire font-grotesk-headline-news tracking-widest uppercase">
                      2. HOW CAN WE HELP?
                    </span>
                  </div>
                  <div className="w-full mt-4 flex flex-col">
                    <Select
                      className="mb-4"
                      inputClassName={cx('transition-color', {
                        'lg:!border-white': !formErrors.subject,
                        'border-fire': formErrors.subject,
                        'text-stone-400': !formData.subject
                      })}
                      placeholder="Pick a topic"
                      ariaLabel="Subject"
                      name="subject"
                      value={formData.subject}
                      showError={!!formErrors.subject}
                      onBlur={() => setFormErrors(state => ({ ...state, subject: '' }))}
                      onChange={value => setFormData({ ...formData, subject: value as string })}
                    >
                      <option value="" disabled selected>
                        Pick a topic
                      </option>
                      <option value="sales">Sales</option>
                      <option value="rentals">Rentals</option>
                      <option value="careers">Careers</option>
                      <option value="press">Press/Partnerships</option>
                      <option value="vendors">Vendor Relations</option>
                      <option value="warranty">Service & Warranty</option>
                    </Select>
                    <TextField
                      ariaLabel="How can we help? Write your message"
                      name="name"
                      type="textarea"
                      id="ContantForm-message"
                      inputClassName="h-64"
                      variant="secondary"
                      placeholder="Write your message here"
                      value={formData.message}
                      showError={!!formErrors.message}
                      onBlur={() => setFormErrors(state => ({ ...state, message: '' }))}
                      onChange={value => setFormData({ ...formData, message: value as string })}
                    />
                  </div>
                </AnimateHeight>
                {Object.values(formErrors).map((error, i) => (
                  <div key={`ContactFormError--${i}-${error}`} className="mt-2">
                    <span className="text-xs text-fire tracking-wide">{error}</span>
                  </div>
                ))}
                <div className="ContactForm__buttons flex flex-col justify-start items-start lg:flex-row lg:justify-between lg:items-center mt-6 mb-2 w-full">
                  <div className="pr-1">
                    <Checkbox
                      reverse={true}
                      name="subscribe"
                      value={newsletterOptIn}
                      label="I want product updates and company news send to my email inbox."
                      onChange={value => setNewsletterOptIn(value)}
                    />
                  </div>
                  <div className="ContactForm__primary-button relative flex flex-row-reverse items-center mt-4 lg:mt-0">
                    <Button
                      size="lg"
                      color="sand"
                      className="inline"
                      disabled={formIsSending}
                      onClick={formIsOpen ? submitForm : openForm}
                    >
                      {formIsOpen ? 'Send Message' : buttonLabel}
                    </Button>
                    <div
                      className={cx('ContactForm__loader-gif mr-2 transition-opacity', {
                        'opacity-0': !formIsSending
                      })}
                    >
                      <div className="h-5 w-5 relative">
                        <Image src={loader} layout="fill" alt="Submit button loading GIF" />
                      </div>
                    </div>
                  </div>
                </div>
              </FormWrapper>
            </div>
          </div>
        </div>
      </div>
    </BlockWrapper>
  );
};

export default ContactForm;
