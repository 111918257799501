import React, { FC } from 'react';
import merge from 'lodash/merge';
import { PortableText as SanityPortableText, PortableTextComponents } from '@portabletext/react';

import { PortableText as TPortableText } from 'types';
const DEFAULT_COMPONENTS: PortableTextComponents = {
  block: {
    normal: ({ children }) => {
      return <p className="mb-6 last-of-type:mb-0">{children}</p>;
    }
  },
  list: {
    number: ({ children }) => {
      return <ol className="list-decimal">{children}</ol>;
    },
    bullet: ({ children }) => {
      return <ul className="list-disc">{children}</ul>;
    }
  },
  listItem: {
    number: ({ children }) => {
      return <li className="ml-8 mb-6 md:mb-10 last-of-type:mb-0">{children}</li>;
    },
    bullet: ({ children }) => {
      return <li className="ml-4 mb-6 md:mb-10 last-of-type:mb-0">{children}</li>;
    }
  },
  marks: {
    strong: ({ children }) => {
      return <strong className="font-grotesk-headline-news">{children}</strong>;
    },
    link: ({ value, children }) => {
      const target = (value?.href || '').startsWith('http') ? '_blank' : undefined;

      return (
        <a
          className="underline"
          href={value?.href}
          target={target}
          rel={target === '_blank' ? 'noindex nofollow' : undefined}
        >
          {children}
        </a>
      );
    }
  }
};
export const PortableText: FC<{
  content: TPortableText;
  components?: Partial<PortableTextComponents>;
}> = props => {
  const components = !!props.components
    ? merge(DEFAULT_COMPONENTS, props.components)
    : DEFAULT_COMPONENTS;

  return <SanityPortableText components={components} value={props.content} />;
};

export default PortableText;
