import React, { FC } from 'react';
import cx from 'classnames';

import { Block, PortableText as TPortableText } from 'types';
import { BlockWrapper, PortableText, Button } from 'components';

export type TTextBlock = Block<
  'textBlock',
  {
    title?: string;
    layout: 'left' | 'center';
    variant?: 'legalInfo';
    body: TPortableText;
    buttons?: {
      primary?: { url: string; label: string };
      secondary?: { url: string; label: string };
    };
  }
>;
export const TextBlock: FC<TTextBlock> = ({ title, layout, variant, body, buttons, meta }) => (
  <BlockWrapper className="TextBlock">
    <div
      className={cx('TextBlock__inner flex flex-wrap', {
        'justify-center text-center max-w-prose mx-auto': layout === 'center',
        'justify-start md:pr-16': layout === 'left'
      })}
    >
      {!!title && meta.isFirstBlockWithTitle && (
        <h1 className="TextBlock__title font-grotesk-headline-news text-2xl md:text-3xl mb-6 lg:mb-10">
          {title}
        </h1>
      )}
      {!!title && !meta.isFirstBlockWithTitle && (
        <h2 className="TextBlock__title font-grotesk-headline-news text-2xl md:text-3xl mb-6 lg:mb-10">
          {title}
        </h2>
      )}
      <div
        className={cx(
          'TextBlock__body mb-6 text-lg !leading-relaxed tracking-wider md:text-[22px] md:leading-loose lg:mb-8',
          {
            '!text-sm text-stone-400 !leading-normal': variant === 'legalInfo'
          }
        )}
      >
        <PortableText content={body} />
      </div>
      <div
        className={cx('TextBlock__buttons flex flex-wrap', {
          'justify-center': layout === 'center'
        })}
      >
        {buttons?.primary?.label && buttons.primary.url && (
          <div
            className={cx('TextBlock__primary-button w-full', {
              'mb-4': !!buttons?.secondary?.label || !!buttons?.secondary?.url
            })}
          >
            <Button variant="fill" href={buttons.primary.url}>
              {buttons.primary.label}
            </Button>
          </div>
        )}
        {buttons?.secondary?.label && buttons.secondary.url && (
          <div className="TextBlock__link-button">
            <Button
              className="text-xs font-grotesk-sub-headline"
              variant="link"
              href={buttons.secondary.url}
            >
              {buttons.secondary.label}
            </Button>
          </div>
        )}
      </div>
    </div>
  </BlockWrapper>
);

export default TextBlock;
