import cx from 'classnames';
import React, { FC, useCallback, useEffect, createRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useRouter } from 'next/router';

// import Cookies from 'js-cookie';
// import * as Sentry from '@sentry/nextjs';
import { MdPhone } from 'react-icons/md';

import iconDown from '../public/images/v2-icon-down-charcoal.svg';
// import iconClose from '../public/images/v2-icon-close.svg';
import loader from '../public/images/loader.gif';

// import EscapodAPI from 'clients/Escapod';
import * as Gtag from 'clients/Gtag';
import Fbq from 'clients/Fbq';
import { useChatPopupContext } from 'contexts/ChatPopupContext';

import { Button, Image, TextField } from 'components';
import Link from 'next/link';
import EscapodApi from 'clients/Escapod';
import Klaviyo from 'clients/Klaviyo/browser';

export const ChatPopup: FC = () => {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSent, setIsSent] = useState<boolean>(false);
  const inputRef = createRef<HTMLInputElement | HTMLTextAreaElement>();

  const { isActive, customer, setCustomer, setIsActive } = useChatPopupContext();

  const close = useCallback(() => setIsActive(false), [setIsActive]);
  const open = useCallback(() => setIsActive(true), [setIsActive]);

  useEffect(close, [router.asPath, close]);

  const send = useCallback(() => {
    if (!customer.message) return inputRef.current?.focus();

    setIsLoading(true);

    const createContact = async () => {
      const response = await EscapodApi.contact.create({
        templateParams: {
          FIRST_NAME: customer.firstName,
          LAST_NAME: customer.lastName,
          EMAIL: customer.email,
          PHONE: customer.phone,
          SUBJECT: 'sales',
          MESSAGE: customer.message
        }
      });

      if (response.message === 'ok') {
        setTimeout(() => {
          setIsLoading(false);
          setIsSent(true);
          setCustomer({ ...customer, message: '' });
        }, 3000);
      } else {
        setIsLoading(false);
      }
    };

    Fbq('Contact');

    Gtag.event('contact_form_submission', {
      path: window?.location.pathname
    });
    Gtag.event('conversion', {
      send_to: 'AW-814232277/hlCNCP2unakYENXloIQD',
      event_callback: () => {
        return;
      }
    });

    Klaviyo.events.contact({
      name: `${customer.firstName} ${customer.lastName}`,
      phone: customer.phone,
      subject: 'sales',
      message: customer.message
    });

    createContact();
  }, [customer, inputRef]);

  return (
    <>
      <div
        className={cx('ChatPopup__overlay bg-charcoal z-10 fixed top-0 left-0 w-full h-full', {
          'opacity-0 pointer-events-none': !isActive,
          'opacity-20': isActive
        })}
      />
      <div
        className={cx(
          'ChatPopup fixed bottom-0 right-0 w-full md:w-[480px] md:mr-12 transition z-50',
          {
            'translate-y-0': isActive,
            'translate-y-full md:translate-y-[calc(100%-64px)]': !isActive
          }
        )}
      >
        <div className="ChatPopup__inner rounded-t-md w-full bg-white shadow-lg border-1 border-stone-200">
          <div className="ChatPopup__header w-full flex justify-between items-center mb-6 pt-5 px-5">
            <span className="font-grotesk-headline tracking-wide text-lg">
              Questions? Let&lsquo;s Chat.
            </span>
            <Button className="relative" variant="no-style" onClick={isActive ? close : open}>
              <Image
                width="24px"
                className={cx('transition', { 'rotate-180': !isActive })}
                src={iconDown}
                alt="Down Menu Icon"
              />
            </Button>
          </div>
          <div className={cx('ChatPopup__contact mb-8 px-5', { 'opacity-60': !!customer.message })}>
            <div className="ChatPopup__contact-card flex p-5 border-1 border-stone-200 rounded-sm shadow-sm">
              <div className="ChatPopup__contact-avatars w-[80px]">
                <div className="ChatPopup__contact-avatars__troy">
                  <div className="w-[80px] h-[80px] rounded-circle overflow-hidden relative">
                    <Image
                      width="80"
                      height="80"
                      alt="Troy Roper, Sales, Product Expert, Escapod Trailers"
                      src="https://cdn.sanity.io/images/l3ps89do/production/b4f4cc6af7dbbbe341f4629e6fe1d051b5f2aa58-800x800.jpg"
                    />
                  </div>
                </div>
                <div className="ChatPopup__contact-avatars__escapod mt-[-30px]">
                  <div className="w-[40px] h-[40px] rounded-circle overflow-hidden relative">
                    <Image
                      width="40"
                      height="40"
                      alt="Verified by Escapod Trailers"
                      src="https://cdn.sanity.io/images/l3ps89do/production/24cc2cbbe202aea0c4e149b69a78bf36351c9a8b-1081x1081.png"
                    />
                  </div>
                </div>
              </div>
              <div className="ChatPopup__contact-info flex flex-col ml-4">
                <span className="font-grotesk-headline text-sm mb-1">Troy Roper</span>
                <span className="font-grotesk-sub-headline-news uppercase tracking-wide text-stone-500 text-xs">
                  Sales, Product Expert
                </span>
                <Button
                  href="tel:4355038191"
                  className="animate-pulse mt-4"
                  color={!customer.message ? 'sand' : 'gray'}
                  variant={!customer.message ? 'fill' : 'stroke'}
                  size="sm"
                >
                  <span className="flex mr-2 md:mr-6">
                    <MdPhone className="mr-1 mt-[.25px]" />
                    <span className="hidden md:inline">Call Now</span>
                  </span>
                  <span>{`(435) - 503 - 8191`}</span>
                </Button>
              </div>
            </div>
          </div>
          <AnimateHeight height={isSent ? 'auto' : 0}>
            <div className="px-5 mb-4">
              <span className="font-grotesk-sub-headline-news uppercase text-xs text-stone-500">
                {`Message received! We'll respond shortly.`}
              </span>
            </div>
          </AnimateHeight>
          <AnimateHeight height={!isSent ? 'auto' : 0}>
            <div className="ChatPopup__form-field">
              <div className="flex mb-4 px-5 justify-between items-center">
                <span className="font-grotesk-sub-headline-news uppercase text-xs text-stone-500 flex items-center justify-start">
                  <span className="mr-1">Or Send Us a Message Now</span>
                  {!isLoading ? (
                    <span>&rarr;</span>
                  ) : (
                    <div className="inline-block w-3 h-3 relative">
                      <Image src={loader} layout="fill" alt="Submit button loading GIF" />
                    </div>
                  )}
                </span>
                {!!customer.firstName ? (
                  <Button
                    disabled={isLoading}
                    onClick={send}
                    size="sm"
                    color={!!customer.message ? 'sand' : 'gray'}
                    variant={!!customer.message ? 'fill' : 'stroke'}
                  >
                    <span>Send</span>
                    <span className="hidden md:inline ml-1">Message</span>
                  </Button>
                ) : (
                  <Button
                    href="/contact-us"
                    size="sm"
                    color={!!customer.message ? 'sand' : 'gray'}
                    variant={!!customer.message ? 'fill' : 'stroke'}
                  >
                    <span>Send</span>
                    <span className="hidden md:inline ml-1">Message</span>
                  </Button>
                )}
              </div>
              <div className={cx('bg-stone-200 p-5', { hidden: !customer.firstName })}>
                <TextField
                  elemRef={inputRef}
                  value={customer.message}
                  onChange={value =>
                    (value as string).length <= 500
                      ? setCustomer({ ...customer, message: value as string })
                      : null
                  }
                  inputClassName="h-24"
                  type="textarea"
                  ariaLabel="Send a Message Now"
                  name="message"
                  placeholder="Write your messsage here..."
                />
                <div className="flex justify-between mt-1">
                  <Link href="/privacy-policy">
                    <a className="font-grotesk-news text-xs text-stone-400 underline">
                      Privacy Policy
                    </a>
                  </Link>
                  <span className="font-grotesk-news text-xs text-stone-400">
                    <span>{customer.message.length}</span>
                    <span className="ml-1">/ 500</span>
                  </span>
                </div>
              </div>
            </div>
          </AnimateHeight>
        </div>
      </div>
    </>
  );
};

export default ChatPopup;
