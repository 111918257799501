import React, { FC, ReactNode } from 'react';
import cx from 'classnames';
import { Popover } from '@headlessui/react';
import { MdInfoOutline } from 'react-icons/md';

const ToolTip: FC<{
  children: ReactNode;
  className?: string;
  iconClassName?: string;
  innerClassName?: string;
}> = ({ children, className, innerClassName, iconClassName }) => {
  return (
    <Popover>
      {() => (
        <div className={cx('relative', className)}>
          <Popover.Button>
            <MdInfoOutline className={iconClassName} />
          </Popover.Button>
          <Popover.Panel>
            <div
              className={cx(
                'absolute z-50 bg-sand p-4 w-64 lg:w-80 rounded-md -translate-x-1/2 mt-3 text-xs leading-loose shadow-md animate-fade-in',
                innerClassName
              )}
            >
              {children}
            </div>
          </Popover.Panel>
        </div>
      )}
    </Popover>
  );
};

export default ToolTip;
