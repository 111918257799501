import cx from 'classnames';
import { FC } from 'react';

import { Button, Image, FormWrapper, TextField } from 'components';
import logo from 'public/images/logo-escapod-emblem-big.png';

type Props = {
  email: string;
  isActive: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: () => void;
  onChange: (email: string) => void;
  error?: { form?: string; email?: string };
};

const NewsletterPopup: FC<Props> = ({
  isActive,
  isLoading,
  onClose,
  onSubmit,
  onChange,
  email,
  error
}) => {
  const imageSrc =
    'https://cdn.sanity.io/images/l3ps89do/production/87710765b3e1480ab37460ad478eda6808a3e1f9-2048x1365.jpg';

  return (
    <>
      <div
        className={cx(
          'NewsletterPopup__overlay bg-stone-900 bg-opacity-70 transition-all fixed bottom-0 left-0 right-0 top-0 z-50',
          { 'opacity-0 pointer-events-none': !isActive }
        )}
      />
      <div
        className={cx(
          'NewsletterPopup NewsletterPopup--DrawerB fixed bottom-0 left-0 right-0 top-0 w-full z-50 transition-all duration-[500ms] overflow-hidden p-3 flex items-center justify-center',
          {
            'opacity-0 pointer-events-none translate-y-4': !isActive
          }
        )}
      >
        <div className="NewsletterPopup__inner-wrapper relative w-full max-w-[620px] lg:max-w-[1200px] bg-sand z-10 flex flex-col lg:flex-row items-center text-center">
          <div className="NewsletterPopup__eyebrow absolute top-0 left-0 -translate-y-full bg-stone-900 p-2 md:p-4 lg:left-[500px]">
            <span className="uppercase font-grotesk-sub-headline tracking-wide md:tracking-widest text-[.4em] md:text-sm">
              <span className="text-stone-400 mr-2">No Spam Here.</span>
              <span className="text-white">Just rugged, outdoorsy goodness.</span>
            </span>
          </div>
          <div className="flex-shrink-0 relative h-[160px] md:h-[300px] w-full lg:h-[500px] lg:w-[500px]">
            <Image
              src={imageSrc}
              layout="fill"
              objectFit="cover"
              alt="A green TOPO2 fiberglass camping trailer is parked on a rocky outcropping with a backdrop of a red rock mesa in the distance."
              sizes="(max-width: 768px) 80vw, (max-width: 1200px) 50vw, 33vw"
            />
          </div>
          <div className="flex flex-wrap justify-start p-6 md:p-12 w-full relative">
            <div className="NewsletterPopup__text w-full pb-4 md:pb-0">
              <div className="NewsletterPopup__title-close-button-wrapper mb-4 lg:mb-8 flex items-center justify-between w-full lg:items-start">
                <div className="NewsletterPopup__title flex justify-center items-center w-full">
                  <div className="mr-4 relative flex-shrink-0 lg:hidden">
                    <Image src={logo} width="37" height="27" alt="escapod icon" />
                  </div>
                  <div className="mr-4 relative flex-shrink-0 hidden lg:block">
                    <Image src={logo} width="63" height="45" alt="escapod icon" />
                  </div>
                  <span className="md:whitespace-nowrap font-grotesk-headline-news text-lg md:text-3xl lg:text-[2.25em] tracking-wide text-charcoal uppercase">
                    Stay in the Know
                  </span>
                </div>
              </div>
              <div className="NewsletterPopup__message-form-wrapper flex w-full flex-wrap lg:flex-nowrap lg:flex-col items-center justify-center">
                <div className="NewsletterPopup__message mb-4 md:mb-12 xl:mb-16 lg:w-full lg:max-w-[800px]">
                  <span className="text-[.675rem] md:text-sm lg:text-lg !leading-relaxed">
                    We’re always dreaming up new ways to get you outdoors. Sign up and we’ll share
                    with you what we’re working on before anyone else.
                  </span>
                </div>
                <FormWrapper
                  className="NewsletterPopup__form-wrapper w-full flex-col lg:w-full lg:flex-row flex flex-nowrap justify-between lg:justify-start lg:max-w-[800px]"
                  id="NewsletterPopup-Subscribe"
                  name="NewsletterPopup-Subscribe"
                >
                  <div className="NewsletterPopup__email-input w-full lg:w-2/3 mr-4 mb-4 lg:mb-0 flex-grow">
                    <TextField
                      className="whitespace-nowrap w-full"
                      id="Newsletter-popup-form-email"
                      name="email"
                      placeholder="Email Address"
                      ariaLabel="Email Address"
                      value={email}
                      error={error?.email}
                      showError={!!error?.email}
                      onChange={value => onChange(value as string)}
                    />
                  </div>
                  <div className="NewsletterPopup__signup-button lg:w-48">
                    <Button
                      className="w-full"
                      type="button"
                      disabled={isLoading}
                      onClick={onSubmit}
                    >
                      {isLoading ? 'Sending' : 'Submit'}
                    </Button>
                  </div>
                </FormWrapper>
                <div className="NewsletterPopup__no-thanks mt-4">
                  <Button
                    variant="no-style"
                    onClick={onClose}
                    className="underline text-stone-700 font-grotesk text-xs"
                  >
                    No, thanks!
                  </Button>
                </div>
              </div>
              {error?.form && (
                <div className="NewsletterPopup__form-error absolute bottom-20 md:top-0 md:right-14">
                  <span className="text-center text-sm text-fire">{error.form}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsletterPopup;
