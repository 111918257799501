import Link from 'next/link';
import cx from 'classnames';
import React, { FC } from 'react';
import { Menu } from 'types';
import { Button } from 'components';
import linkIsRelative from 'utils/linkIsRelative';

const Footer: FC<{ menu: Menu[]; hide?: boolean }> = ({ menu, hide = false }) => (
  <footer className={cx('bg-stone-200 pt-16 pb-24 lg:py-24', { hidden: hide })}>
    <div className="container flex flex-wrap">
      <div className="mb-24 w-full lg:mb-0 lg:w-1/2 lg:pr-32">
        <h2 className="mb-8 font-grotesk-headline text-3xl tracking-wider">Questions?</h2>
        <p className="grotesk-news text-[14px] leading-[24px] tracking-wider">
          Our frequently asked questions (FAQ) page has answers to all of your questions. Please
          visit this searchable page to find the info you need to decide whether an Escapod is right
          for you.
        </p>
        <Button className="mt-8 w-fit" variant="fill" color="white" href="/faq">
          Visit FAQ Page
        </Button>
      </div>
      <div className="w-full lg:w-1/2">
        <h2 className="font-grotesk-headline text-2xl tracking-wider md:text-3xl">
          More to Explore
        </h2>
        <ul className="mt-8 flex flex-wrap">
          {menu.map(group => (
            <li
              key={group._key}
              className="mb-8 w-3/5 pr-4 even:w-2/5 md:pr-8 lg:w-[35%] lg:even:w-[35%] lg:thirds:w-[30%]"
            >
              <h3 className="font-grotesk-sub-headline-news text-sm uppercase tracking-widest text-fire">
                {group.title}
              </h3>
              {!!group.links.length && (
                <ul className="mt-6">
                  {group.links.map(link => (
                    <li key={link._key} className="mb-1 lg:mb-2">
                      {linkIsRelative(link.url) ? (
                        <Link href={link.url}>
                          <a className="grotesk-news text-xs leading-tight tracking-wider hover:underline md:text-sm">
                            {link.title}
                          </a>
                        </Link>
                      ) : (
                        <a
                          href={link.url}
                          aria-label={`Footer link to ${link.title}`}
                          target="_blank"
                          rel="noopner noreferrer"
                          className="grotesk-news text-xs leading-tight tracking-wider hover:underline md:text-sm"
                        >
                          {link.title}
                        </a>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-16 w-full">
        <p className="text-xs tracking-wide text-stone-400">
          &copy; {new Date().getFullYear()} Escapod Trailers LLC.{' '}
          <Link href="/privacy-policy">
            <a className="underline ml-2">Privacy Policy</a>
          </Link>
        </p>
      </div>
    </div>
  </footer>
);

export default Footer;
