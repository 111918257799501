import { FC } from 'react';
import { PortableText, Button } from 'components';

import { TFinancingApplication } from '.';
import { useFinancingMutate } from 'contexts/FinancingContext';
import { AccordionList } from 'components';
import { TAccordionList } from '../AccordionList';

const Consent: FC<TFinancingApplication> = ({ title, meta, agreement }) => {
  const mutate = useFinancingMutate();

  const accordionContent: TAccordionList = {
    _type: 'accordionList',
    _key: 'CONSENT_ACCORDION',
    meta: {
      isFirstBlockWithTitle: false,
      isLastOfConsecutiveType: true,
      nextBlockType: 'text'
    },
    layout: 'full',
    title: '',
    items: [
      {
        _key: '1A',
        title:
          'This is an application to be pre-approved for a loan. This is not a loan application.',
        body: 'The result of this application allows us to accurately predict if you are able to secure a loan through our financing program at the time of your trailer pickup. This is not a loan application and banks will not be notified of your intent to borrow at this time. If pre-approved, we will not proceed with a loan application until we have your written consent.'
      },
      {
        _key: '2A',
        title:
          'Pre-approval can only be granted to US citizens that live within the 48 contiguous state.',
        body: 'We cannot extend pre-approval to persons that live outside of the 48 contiguous states and are not currently US citizens. You may still be eligible for a loan, so please contact us at sales@escapod.us so we can help you find funding.'
      },
      {
        _key: '3A',
        title:
          'This is a "Soft Check" on your credit profile. This will not impact your credit score.',
        body: 'This a Loan Prequalification Check, otherwise known as a "soft check". Soft checks do not appear as inquiries on your credit report and are not logged as credit profile requests. This means your score cannot be impacted by this pre-approval application, even if you apply for pre-approval multiple times. Your score will only be impacted if you approve a loan application for the purchase of your trailer.'
      },
      {
        _key: '4A',
        title: 'You will not receive any information related to your credit profile.',
        body: 'The result of this application will only provide you information on whether or not you are eligible to receive a loan for an Escapod Trailers vehicle. We are not authorized to provide or discuss with you information on your credit profile, including any scores, ratings, history, public records, inquiries, or accounts. This is a security measure that protects you, the consumer.'
      },
      {
        _key: '5A',
        title:
          'Your pre-approval status may change if your credit profile changes significantly after you have been pre-approved.',
        body: 'Credit bureaus update your credit profile frequently with new information about your credit history and current account statuses. If any significant changes are detected after you are pre-approved your eligibility for loan approval may be impacted.'
      },
      {
        _key: '6A',
        title: 'You are prohibited from submitting information for someone else.',
        body: 'You may only submit information that is your own. Consent to submit personal information will be required. Information provided during this application process can only be provided by the persons who own this information.'
      },
      {
        _key: '8A',
        title: 'Your information will only be used to grant pre-approval for loan application.',
        body: 'We will not use this information to solicit unrelated products or services. This is purely a convenience service for the customer and helps the Escapod team prepare the customer for purchase.'
      },
      {
        _key: '7A',
        title: 'Your information will never be stored or shared by our service.',
        body: 'We do not store and will never store information submitted by customers. We do not share and will never share the information you submit or results from credit checks with any third-parties.'
      }
    ]
  };

  return (
    <div className="FinancingApplication__Consent flex flex-col justify-center w-full m-auto pb-12 animate-fade-in max-w-prose">
      {!!title && meta.isFirstBlockWithTitle && (
        <h1 className="FinancingApplication__title mb-8 w-full font-grotesk-headline-news text-2xl md:text-3xl">
          Understanding This Application
        </h1>
      )}
      {!!title && !meta.isFirstBlockWithTitle && (
        <h2 className="FinancingApplication__title mb-8 w-full font-grotesk-headline-news text-2xl md:text-3xl">
          Understanding This Application
        </h2>
      )}
      <AccordionList {...accordionContent} />
      <h2 className="FinancingApplication__title mb-8 w-full font-grotesk-headline-news text-2xl md:text-3xl">
        Lending Pre-Approval Agreement
      </h2>
      <div className="leading-relaxed text-sm mb-12">
        <PortableText content={agreement.agreement} />
      </div>
      <div className="w-full">
        <Button variant="fill" color="sand" size="lg" onClick={() => mutate?.setStage('BASIC')}>
          I Agree <span className="ml-2">&rarr;</span>
        </Button>
      </div>
    </div>
  );
};

export default Consent;
